import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Flex, Modal, Text, rem } from '@mantine/core';
import { useState } from 'react';
import { Button } from '@/components';
import classes from './RenewSubscription.module.css';
import { MachineBox } from '@/interfaces';
import { renewSubscription } from '@/lib/renewSubscription';

dayjs.extend(LocalizedFormat);

interface Props {
  open: boolean;
  subscription?: MachineBox;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const RenewSubscription = ({
  open,
  subscription,
  onClose,
  onDone,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const subType = subscription?.sub_type.split(',')[1] ?? '';

  const handleRenewSubscription = async () => {
    if (subscription) {
      setLoading(true);
      await renewSubscription(subscription.id.toString(), subType);
      await onDone();
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      classNames={{ header: classes.header }}
      opened={open}
      onClose={onClose}
      centered
    >
      <Flex direction="column" gap={rem(15)} mt={rem(32)}>
        <Text fz={rem(26)} fw={600} ta="center">
          Skapīša abonēšanas atsākšana
        </Text>
        <Text ta="center">
          {`Skapīša abonēšana tiks atsākta perioda: ${subType}
`}
        </Text>

        <Flex direction="column">
          <Button loading={loading} onClick={handleRenewSubscription}>
            Atsākt
          </Button>
          <Button variant="transparent" onClick={onClose}>
            Atcelt
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
