import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Loader, TextInput } from '@mantine/core';
import { Check } from '@phosphor-icons/react/dist/ssr';
import classes from './AddCategory.module.css';
import { addCategory } from '@/lib/addCategory';
import { useTranslation } from '@/hooks';

interface Props {
  visible: boolean;
  onSuccess: () => Promise<void>;
  onClose: () => void;
}

export const AddCategory = ({ visible, onSuccess, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>();
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!visible) {
      setError(false);
      setValue('');
      setLoading(false);
    }
  }, [visible]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setError(false);
  };

  const handleSubmit = async () => {
    if (!value) {
      setError(true);
    } else {
      setLoading(true);
      await addCategory(value);
      await onSuccess();
      onClose();
      setLoading(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }

    if (event.key === 'Esc' || event.key === 'Escape') {
      onClose();
    }
  };

  if (!visible) return <></>;

  return (
    <div className={classes.wrapper}>
      <TextInput
        rightSection={loading ? <Loader size={15} /> : <Check />}
        variant="default"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        error={error}
        rightSectionProps={{ className: classes.check, onClick: handleSubmit }}
        placeholder={t('categoryname')}
      />
    </div>
  );
};
