import { MachineBox } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getCompanyMachineBoxes = async (companyId?: string) => {
  if (!companyId) return [];

  try {
    const resp = await httpClient().get<MachineBox[]>(
      `/machines/machinebox/company/getall/${companyId}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get company machine boxes');
  }

  return [];
};
