import { useState, useMemo } from 'react';
import useSWR from 'swr';
import { Box, Flex, Modal, MultiSelect, Select, Text, TextInput, Textarea, rem } from '@mantine/core';
import classes from './Maintenance.module.css';
import { Button } from '@/components';
import { deleteCategory } from '@/lib/deleteCategory';
import { setMachineStatus } from '@/lib/setMachineStatus';
import { setRentalPointStatus } from '@/lib/setRentalPointStatus';
import { getMachineBoxesById } from '@/lib/getMachineBoxesById';
import { setMachineMaintenance } from '@/lib/setMachineMaintenance';
import { setLockerMaintenance } from '@/lib/setLockerMaintenance';
import { cancelMaintenance } from '@/lib/cancelMainenance';

interface Props {
  open: boolean;
  machine: any;
  onClose: () => void;
  onSuccess: () => void;
}

const MACHINE_STATUS = [
  {
    label: 'Apkopē (nav pieejams)',
    value: 'MAINTANENCE'
  },
  {
    label: 'Apkopē (pieejams)',
    value: 'PARTMAINTANENCE'
  }
]

export const Maintenance = ({ open, onClose, onSuccess, machine }: Props) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [machineStatus, setMaintMachineStatus] = useState("MAINTANENCE");
  const [maintenanceBoxes, setMaintenanceBoxes] = useState<any>([]);
  const [description, setDescription] = useState("");

  const machineData = useMemo(() => {
    if (!machine) return {};
    else {
      return machine;
    }
  }, [machine]);

  const { data, isLoading } = useSWR(
    `/machines/machineboxes/getall/${machine?.id}`,
    () => getMachineBoxesById(machine?.id),
  );

  const boxes = useMemo(() => {
    if (!data) return [];
  
    return data
      .filter(row => row.status === 'AVAILABLE')
      .map(row => ({
        value: String(row.id),
        label: row.locker_number
      }));
  }, [data]);  

  const handleMaintenance = async () => {
    setLoading(true);

    try {
      if (machineData.machine_type === 'automatic') {
        if (machineData.status === 'AVAILABLE') {
          const today = new Date();
          const formattedDate = today.toISOString().split('T')[0];
          const allBoxes = boxes.map((box) => box.label).join(',');
          const selectedBoxes = boxes.filter((box) => maintenanceBoxes.includes(box.value));
          const boxLabels = selectedBoxes.map((box) => box.label).join(',');
  
          const updater = {
            machine_id: machineData.id,
            description: description,
            startTime: formattedDate,
            boxes: machineStatus === 'PARTMAINTANENCE' ? boxLabels : allBoxes
          }
          await setMachineMaintenance(machineData?.id, machineStatus, updater);

          // for (const box of boxes) {
          //   await setLockerMaintenance(box.value, 'DISABLED')
          // }
        } else {
          await cancelMaintenance(machineData?.id)
        }
        
        onSuccess();
        onClose()
      } else if (machineData.machine_type === 'physical') {
        const updater =
          machineData.status != 'AVAILABLE' ? 'AVAILABLE' : 'MAINTANENCE';
        await setRentalPointStatus(machineData?.id, updater);
        onSuccess();
        onClose()
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setCurrentStep(1);
  };

  return (
    <Modal 
      opened={open} 
      onClose={handleClose} 
      centered 
      size="40dvw"
      title={machineData.status === 'AVAILABLE' ? 'Apkopes sākšana' : ''}
    >
      <Box>
        {machineData.status === 'MAINTANENCE' || machineData.status === 'PARTMAINTANENCE' || machineData.status === 'MAINTENANCE' ? (
          <Box h={rem(200)}>
            <Text mb={rem(10)} className={classes.title}>
              Apkopes izbeigšana
            </Text>
            <Text  m={rem(10)} className={classes.subtitle}>
              Nomas punkta apkope tiks izbeigta, un skapīši kļūs pieejami preču ievietošanai.
            </Text>
            <Flex  m={rem(10)} direction='column'>
              <Button loading={loading} onClick={handleMaintenance}>
                Beigt apkopi
              </Button>
              <Button variant='transparent' type='reset' color='grey' onClick={handleClose}>
                Atcelt
              </Button>
            </Flex>
          </Box>
        ) : (
          <>
          {machineData.machine_type === 'automatic' ? (
            <>
            <Box className={classes.grid}>
            <Select
              p={rem(5)}
              label="Nomas punkta statuss"
              placeholder="Izvēlies vērtību"
              data={MACHINE_STATUS}
              value={machineStatus}
              onChange={(value: any) => setMaintMachineStatus(value)}
              allowDeselect={false}
            />
              {machineStatus === 'PARTMAINTANENCE' ? (
                <MultiSelect
                  p={rem(5)}
                  label="Skapīša ID"
                  placeholder="Izvēlēties vienu vai vairākus"
                  data={boxes}
                  onChange={(value: any) => {
                    setMaintenanceBoxes(value);
                    console.log("Selected value:", value);
                  }}
                  clearable
                />
              ) : <div/>}
            </Box>
        
            <Textarea
              p={rem(20)}
              label="Apraksts"
              placeholder='Ievadiet tekstu brīvā formā'
              value={description}
              onChange={(event) => setDescription(event.currentTarget.value)}
              w={'100%'}
              rows={6}
            />
            <Flex justify="flex-end" gap={rem(5)} p={rem(20)}>
              <Button
                variant="transparent"
                color="gray"
                onClick={handleClose}
                disabled={loading}
              >
                Atcelt
              </Button>
              <Button type="submit" onClick={handleMaintenance} loading={loading}>
                Saglabāt
              </Button>
            </Flex>
            </>
          ) : (
            <Box h={rem(200)}>
              <Text mb={rem(10)} className={classes.title}>
                Apkopes sākšana
              </Text>
              <Text  m={rem(10)} className={classes.subtitle}>
                Nomas punkta apkope tiks uzsākta, un nomas punkts nebūs pieejams.
              </Text>
              <Flex  m={rem(10)} direction='column'>
                <Button loading={loading} onClick={handleMaintenance}>
                    Sākt apkopi
                </Button>
                <Button variant='transparent' type='reset' color='grey' onClick={handleClose}>
                    Atcelt
                </Button>
              </Flex>
            </Box>
          )}
        </>
        )}
      </Box>
    </Modal>
  );
  
};
