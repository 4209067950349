import { useMemo, useState } from 'react';
import {
  Box,
  Flex,
  InputLabel,
  Modal,
  NumberInput,
  Select,
  Text,
  rem,
  Loader
} from '@mantine/core';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useForm } from '@mantine/form';
import { DateValue, YearPickerInput } from '@mantine/dates';
import classes from './NewItem.module.css';
import {
  Button,
  MultiLevelSelect,
  MultiLevelSelectGroup,
  TagInput,
  TextInput,
} from '@/components';
import { Item } from '@/interfaces';
import { getItemCategories } from '@/lib/getItemCategories';
import { LOCKER_SIZES } from '@/constants/sizes';
import { addItemImages } from '@/lib/addItemImage';
import { ImageInputs } from './ImageInputs';
import { addItem } from '@/lib/addItem';
import { useUser } from '@/hooks/useUser';
import { getCategories } from '@/lib/getCategories';
import { getCategoryAttributes } from '@/lib/getCategoryAttributes';
import { ItemAttribute } from './ItemAttribute';
import { addItemAttribute } from '@/lib/addItemAttribute';
import { checkInventoryNumber } from '@/lib/checkInventoryNumber';

interface FormValues {
  inventory_number?: string;
  sub_category_id?: string;
  category_name?: string;
  name?: string;
  model?: string;
  producer?: string;
  year_made?: DateValue;
  price?: number;
  cost_per_day?: number;
  cost_per_h?: number;
  locker_size?: string;
  images_url?: string;
  additional_equipment?: string;
}

interface Props {
  open: boolean;
  item?: Item;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const NewItem = ({ open, onClose, onDone }: Props) => {
  const user = useUser((state) => state.user);
  const [images, setImages] = useState<File[]>([]);
  const [isValidInventory, setIsValidInventory] = useState(true);
  const [apiLoading, setIsApiLoading] = useState(false);
  const [catAttributes, setCatAttributes] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<any[]>([]);

  const { data: categories } = useSWR('/inventory_mgmt/categories/allnodes/list', () =>
    getCategories(),
  );

  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>({
    validate: {
      inventory_number: (value) => (!value ? 'Value required' : null),
      category_name: (value) => (!value ? 'Value required' : null),
      name: (value) => (!value ? 'Value required' : null),
      model: (value) => (!value ? 'Value required' : null),
      producer: (value) => (!value ? 'Value required' : null),
      year_made: (value) => (!value ? 'Value required' : null),
      price: (value) => (!value ? 'Value required' : null),
      cost_per_day: (value) => (!value ? 'Value required' : null),
      cost_per_h: (value) => (!value ? 'Value required' : null),
      locker_size: (value) => (!value ? 'Value required' : null),
    },
  });

  const getAttributes = async (id: any) => {
    const response = await getCategoryAttributes(id);
    let attributes = []
    for (const item of response) {
      const itemData = {
        id: item.id,
        name: item.name,
        attribute_type: item.attribute_type,
        measure_unit: item.measure_unit,
        item_id: null,
        value: ''
      }
      attributes.push(itemData); 
    }
    setCatAttributes(attributes)
  }

  const handleFileChange = (files: string[]) => {
    setSelectedFiles(files);
  };

  const handleInputChange = (value: string, index: number) => {
    setCatAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        value: value,
      };
      return updatedAttributes;
    });
  };

  const categoryOptions = useMemo(
    () =>
      categories
        ? categories.map(
            (cat) =>
              ({
                id: cat.id.toString(),
                group: cat.name,
                options: cat.subcategories.map((sub: any) => ({
                  value: sub.id.toString(),
                  label: sub.name,
                })),
              }) as MultiLevelSelectGroup,
          )
        : [],
    [categories],
  );

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const imageNames = images.map((img) => img.name);

    await addItemImages(images);
    const data = await addItem({
      ...values,
      year_made: dayjs(values.year_made).format('YYYY'),
      images_url: JSON.stringify(imageNames),
      company_id: user?.companyId,
      status: 'FREE',
      rating: 0.0,
    });
    for (const attr of catAttributes) {
      const payload = {
        item_id: data.id,
        cat_attribute_id: attr.id,
        value: attr.value
      };
      await addItemAttribute(payload);
    };
    await onDone();
    form.reset();
    onClose();

    setLoading(false);
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const checkInventoryNumberCall = async (value: any) => {
    setIsApiLoading(true)
    form.setValues({ inventory_number: value })

    try {
      const response = await checkInventoryNumber(value, user?.companyId);
      setIsValidInventory(response);
    } catch (error) {
      console.error('Error checking inventory number:', error);
    } finally {
      setIsApiLoading(false)
    }
  };

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
        body: classes.body,
      }}
      opened={open}
      onClose={onClose}
      title="Jauna prece"
      centered
      size="70dvw"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.grid}>
          <Flex direction="column" gap={rem(30)}>
            <TextInput
              label="Inventāra numurs"
              {...form.getInputProps('inventory_number')}
              onChange={(event) => checkInventoryNumberCall(event.target.value)}
              error={!isValidInventory && <Text fz={rem(10)}>Numurs ir aizņemts</Text>}
              rightSection={ apiLoading && <Loader size={'sm'} />}
            />
            <MultiLevelSelect
              label="Kategorija"
              options={categoryOptions}
              value={{ option: form.values.category_name || '' }}
              onChange={(_, option) => {
                form.setFieldValue('sub_category_id', option.value);
                form.setFieldValue('category_name', option.label);
                getAttributes(option.value)
              }}
            />
            <TextInput label="Nosaukums" {...form.getInputProps('name')} />
            <TextInput label="Ražotājs" {...form.getInputProps('producer')} />
            <TextInput label="Modelis" {...form.getInputProps('model')} />
            <YearPickerInput
              label="Ražošanas gads"
              dropdownType="popover"
              maxDate={new Date()}
              {...form.getInputProps('year_made')}
            />
          </Flex>
          <Flex direction="column" gap={rem(30)}>
            <TextInput
              label="Preces vērtība"
              rightSection={<Text fz={rem(14)}>€</Text>}
              {...form.getInputProps('price')}
            />
            <div>
              <Flex gap={rem(10)}>
                <NumberInput
                  label="Nomas maksa"
                  decimalScale={2}
                  fixedDecimalScale
                  rightSection={
                    <Text fz={rem(14)} mr={rem(10)}>
                      €/h
                    </Text>
                  }
                  {...form.getInputProps('cost_per_h')}
                />
                <NumberInput
                  label=" "
                  decimalScale={2}
                  fixedDecimalScale
                  rightSection={
                    <Text fz={rem(14)} mr={rem(10)}>
                      €/24h
                    </Text>
                  }
                  {...form.getInputProps('cost_per_day')}
                />
              </Flex>
            </div>
            <Select
              label="Skapīša izmērs"
              data={LOCKER_SIZES}
              {...form.getInputProps('locker_size')}
            />
            <div>
              <InputLabel>Attēls <span style={{fontSize: 12, color: 'red'}}>*obligāts</span></InputLabel>
              <ImageInputs onChange={setImages} />
            </div>
            <TagInput
              label="Papildaprīkojums"
              placeholder='Ievadīt un nospiest "Enter"'
              initialTags={JSON.parse(form.values.additional_equipment ?? '[]')}
              onCreate={(items) =>
                form.setFieldValue(
                  'additional_equipment',
                  JSON.stringify(items),
                )
              }
            />
          </Flex>
          <Flex direction="column" gap={rem(30)}>
            {catAttributes.map((item: any, index: number) => (
              <ItemAttribute 
                key={index} 
                item={item}
                onFileChange={handleFileChange}
                onInputChange={(value) => handleInputChange(value, index)}
              />
            ))}
          </Flex>
        </Box>
        <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
          <Button
            variant="transparent"
            color="gray"
            onClick={handleClose}
            disabled={loading}
          >
            Atcelt
          </Button>
          <Button onClick={() => handleSubmit(form.values)} loading={loading}>
            Saglabāt
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
