'use client';

import { useState } from 'react';
import Image from 'next/image';
import {
  ActionIcon,
  FileButton,
  Flex,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import addImage from '@/public/images/add_image.svg';
import classes from './ImageInputs.module.css';

interface Props {
  onChange: (images: File[]) => void;
}

export const ImageInputs = ({ onChange }: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  const getImageDetails = (file: File) => {
    let url = '';

    url = URL.createObjectURL(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      url = e.target?.result as string;
    };
    reader.readAsDataURL(file);

    return url;
  };

  const handleImageAdd = (newFiles: File[]) => {
    const newListOfFiles = [...files, ...newFiles];
    onChange(newListOfFiles);
    setFiles(newListOfFiles);
  };

  const deleteImage = (file: File | string) => {
    const filtered = files.filter((f) => {
      const valueToCompare = typeof file === 'string' ? file : file.name;

      if (typeof f === 'string') {
        return f !== valueToCompare;
      }

      return f.name !== valueToCompare;
    });

    onChange(filtered);
    setFiles(filtered);
  };

  return (
    <Flex gap={rem(10)}>
      {files.map((file, index) => (
        <UnstyledButton
          key={index}
          className={classes.imageWrapper}
          onClick={() => deleteImage(file)}
        >
          <Image
            src={getImageDetails(file)}
            height={40}
            width={40}
            alt={file?.name}
          />
          <ActionIcon
            className={classes.delete}
            variant="transparent"
            color="red"
          >
            <Trash />
          </ActionIcon>
        </UnstyledButton>
      ))}
      {files.length < 5 && (
        <FileButton
          onChange={handleImageAdd}
          accept="image/png,image/jpeg,image/jpg"
          multiple
        >
          {(props) => (
            <ActionIcon
              h={40}
              w={40}
              variant="filled"
              color="gray.2"
              {...props}
            >
              <Image src={addImage} height={15} width={15} alt="Add image" />
            </ActionIcon>
          )}
        </FileButton>
      )}
    </Flex>
  );
};
