import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const addItemImages = async (images: File[]) => {
  try {
    if (images.length === 0) {
      console.error("No images to upload");
      return undefined;
    }

    const formData = new FormData();
    
    images.forEach((image, index) => {
      formData.append('files', image);
    });

    const resp = await httpClient().post(
      '/inventory_mgmt/tools/upload/images',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Images created');
      return resp.data;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to add images');
  }

  return undefined;
};
