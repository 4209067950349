import { useState } from 'react';
import { Box, Flex, NumberInput, Stack, Text, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button, PasswordField, TextInput } from '@/components';
import { useTranslation } from '@/hooks';
import { VIEWS } from '../interfaces';
import { initPasswordReset } from '@/lib/initPasswordReset';
import { confirmPasswordReset } from '@/lib/confirmPasswordReset';

interface FormValues {
  email: string;
  code: string;
  password: string;
  passwordRepeat: string;
}

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const PasswordReset = ({ onViewChange }: Props) => {
  const [view, setView] = useState<'email' | 'password'>('email');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const form = useForm<FormValues>({
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : 'Please enter valid email',
      code: (value) =>
        view === 'password'
          ? value.toString().length === 6
            ? null
            : 'Please enter valid code'
          : null,
      password: (value, values) =>
        view === 'password'
          ? value === values.password
            ? null
            : 'Passwords should match'
          : null,
    },
  });

  const handleNext = async (values: FormValues) => {
    const { email, code, password } = values;
    setLoading(true);

    if (view === 'email') {
      const resp = await initPasswordReset(email);

      if (resp?.status === 200) {
        setView('password');
      }
    } else {
      const resp = await confirmPasswordReset(code, email, password);

      if (resp?.status === 200) {
        onViewChange(VIEWS.LOGIN);
      }
    }

    setLoading(false);
  };

  return (
    <Box py={rem(60)} ml={rem(34)} h="100%">
      <form onSubmit={form.onSubmit(handleNext)}>
        <Stack gap={rem(70)} justify="space-between" h="100%">
          {view === 'email' && (
            <Stack>
              <Text fz={rem(35)} fw={600}>
                {t('forgotPass')}
              </Text>
              <TextInput label="E-pasts" {...form.getInputProps('email')} />
            </Stack>
          )}
          {view === 'password' && (
            <Stack>
              <Text fz={rem(35)} fw={600}>
                Izveidot jaunu paroli
              </Text>
              <NumberInput
                label="Verifikācijas kods"
                hideControls
                {...form.getInputProps('code')}
              />
              <PasswordField
                label="Jaunā parole"
                {...form.getInputProps('password')}
              />
              <PasswordField
                label="Atkārtota parole"
                {...form.getInputProps('passwordRepeat')}
              />
            </Stack>
          )}

          <Flex justify="flex-start" gap={rem(30)}>
            <Button type="submit" loading={loading}>
              {t('continue')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};
