'use client';

import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { Badge } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useParams, useRouter } from 'next/navigation';
import {
  Button,
  PageHeader,
  Table,
  TableDataRow,
  TableHeader,
} from '@/components';
import { STATUS_COLORS } from '@/constants/status';
import { RowAction } from '@/components/Table/interfaces';
import { getSubCompaniesLegal } from '@/lib/getSubCompaniesLegal';
import { Accept } from './Accept';
import { Reject } from './Reject';

const HEADERS: TableHeader[] = [
  {
    value: 'name',
    label: 'Nosaukums',
  },
  {
    value: 'address',
    label: 'Adrese',
  },
  {
    value: 'userCount',
    label: 'Lietotāju skaits',
  },
  { value: 'registrationNumber', label: 'Reģ nr.' },
  { value: 'type', label: 'Tips' },
  { value: 'status', label: 'Status' },
];

export const SubCompaniesLegalList = () => {
  const [selected, setSelected] = useState<string>();
  const { data, isLoading, mutate } = useSWR('/subcompany/legal', () =>
    getSubCompaniesLegal(),
  );
  const router = useRouter();
  const params = useParams();
  const [acceptOpen, acceptActions] = useDisclosure(false);
  const [rejectOpen, rejectActions] = useDisclosure(false);

  const companies = useMemo(() => {
    if (!data) return [];

    return data.map(
      (row) =>
        ({
          _id: { value: row.id.toString() },
          name: { value: row.name, link: `/subCompanies/legal/${row.id}` },
          address: { value: row.legal_address ?? '' },
          userCount: { value: row.users ?? '' },
          registrationNumber: {
            value: row.registration_number ?? '',
          },
          type: { value: row.company_type ?? '' },
          status: {
            value: row.status ?? '',
            display: (
              <Badge color={STATUS_COLORS[row.status?.toUpperCase() || 'GRAY']}>
                {row.status || 'unknown'}
              </Badge>
            ),
          },
        }) as TableDataRow,
    );
  }, [data]);

  const handleEdit = (id: string) => {
    router.push(`/${params.lang}/users/${id}?action=edit`);
  };

  const rowActions: RowAction[] = useMemo(
    () => [
      {
        label: 'Rediģēt',
        callback: handleEdit,
      },
      {
        label: 'Pieņemt',
        when: { status: ['pending', 'declined'] },
        callback: (id) => {
          setSelected(id);
          acceptActions.open();
        },
      },
      {
        label: 'Noraidīt',
        when: { status: ['active', 'pending', null] },
        callback: (id) => {
          setSelected(id);
          rejectActions.open();
        },
      },
    ],
    [],
  );

  const handleDone = async () => {
    await mutate();
  };

  return (
    <>
      <PageHeader
        title="Juridisko apakšuzņēmumu pārvaldība"
        actions={<Button type="submit">Pievienot uzņēmumu</Button>}
      />
      <Table
        headers={HEADERS}
        data={companies}
        searchable
        filters={['status']}
        loading={isLoading}
        rowActions={rowActions}
      />

      <Accept
        id={selected}
        open={acceptOpen}
        onClose={acceptActions.close}
        onDone={handleDone}
      />
      <Reject
        id={selected}
        open={rejectOpen}
        onClose={rejectActions.close}
        onDone={handleDone}
      />
    </>
  );
};
