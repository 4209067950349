import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const unblockClient = async (user: Client) => {
  try {
    const resp = await httpClient().post<Client>(
      `/clients/client/unblock/${user?.id}/`,
      user,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User unblocked');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to unblock user');
    console.error(error);
  }
};
