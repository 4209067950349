import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface Machine {
  lattitude: number;
  longitude: number;
  address: string;
  name: string;
  machine_type: string;
  status: string;
  phone: string;
  workflow_type: string;
  exception_dates: string;
  company_id: number;
  city: string;
  post_code: string;
}

export const addMachine = async (item: Machine) => {
  try {
    const resp = await httpClient().post('/machines/create', item);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Item created');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create item');
  }
};
