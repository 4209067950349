import { Admin } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const deleteAdmin = async (admin: Admin) => {
  try {
    const resp = await httpClient().post<Admin>('/admin/admindeleteusr', admin);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User deleted');
      return resp.data;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to delete user');
    console.error(error);
  }

  return undefined;
};
