import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getRentalPoints = async () => {
  try {
    const resp = await httpClient().get('/rentalpoints/getall');

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get rentalpoints');
  }

  return undefined;
};
