import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const addSubAdminImage = async (file: File) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const resp = await httpClient().post('/subadmin/upload/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Added user image');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to add user image');
  }
};
