import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const initPasswordReset = async (email: string) => {
  try {
    const resp = await httpClient().post('/admin/forgotpassword', {
      email,
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Password reset code sent to email');
      return resp;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to initialize password reset');
    console.error(error);
  }

  return undefined;
};
