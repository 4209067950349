import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';
import { SubAdminRegisterResponse } from '@/interfaces';

interface Body {
  email: string;
  code: string;
}

export const verifyRegistration = async (body: Body) => {
  try {
    const resp = await httpClient().post<SubAdminRegisterResponse>(
      '/subadmin/verifycode',
      body,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Code verified');
      return resp.status;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to verify code');
  }

  return undefined;
};
