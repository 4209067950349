import React, { useState } from 'react';
import {
  FileInput,
  Flex,
  TextInput,
  Text,
  rem,
  Button,
  ActionIcon,
  FileButton,
  InputLabel
} from '@mantine/core';
import { FilePlus, X } from '@phosphor-icons/react/dist/ssr';
import classes from '../ItemAttribute/ItemAttribute.module.css';

interface Props {
  onFileChange: (files: string[]) => void;
  onInputChange: (value: string) => void;
  item: any;
}

export const ItemAttribute = ({ item, onFileChange, onInputChange }: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const getTrimmedFileName = (fileName: string) => {
    if (fileName.length > 30) {
      return fileName.slice(0, 30) + '...';
    }
    return fileName;
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    onFileChange(updatedFiles.map(file => file.name));
  };

  const handleFileChange = (newFiles: File[]) => {
    setFiles([...files, ...newFiles]);
    onFileChange([...files, ...newFiles].map(file => file.name));
  };
  
  return (
    <Flex gap={rem(10)} direction="column">
      {item.attribute_type === 'Fails' ? (
        <Flex direction="column" w={'100%'} mt={rem(8)}>
          <Text fz={rem(12)}>Fails</Text>
          <FileButton
            multiple
            onChange={handleFileChange}
          >
            {(props) => <Button 
              leftSection={<FilePlus />} 
              variant="default" {...props}
              className={classes.fileupload}
            >
              Add Files
            </Button>
            }
          </FileButton>
          <Flex direction="column">
            {files.map((file, index) => (
              <Flex align="center" key={index} justify='space-between'>
                <div className={classes.filename}>{getTrimmedFileName(file.name)}</div>
                <ActionIcon
                  mt={rem(5)}
                  ml={rem(5)}
                  variant="transparent"
                  onClick={() => handleDeleteFile(index)}
                >
                  <X size={20} color="grey" />
                </ActionIcon>
              </Flex>
            ))}
          </Flex>
        </Flex>
        
      ) : (
        <TextInput 
          rightSection={item.measure_unit}
          w={'100%'}
          label={item.name}
          value={item.value}
          onChange={(event) => onInputChange(event.target.value)}
        />
      )}
    </Flex>
  );
};
