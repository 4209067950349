import { useState } from 'react';
import {
  Box,
  Flex,
  ActionIcon,
  Modal,
  Text,
  rem,
  Radio,
  ScrollArea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import classes from './AddRentalPoint.module.css';
import { Button, CountryCode, TextInput } from '@/components';
import { LockerBox } from './LockerBox';
import { Item } from '@/interfaces';
import { SectionHeader } from '@/components/SectionHeader';
import { Autocomplete } from '@/components/AutoComplete';
import { addMachine } from '@/lib/addMachine';
import { addRentalPoint } from '@/lib/addRentalPoint';
import { addLockerBox } from '@/lib/addLocker';
import { ExceptionDate } from './ExceptionDate';
import { WorkDay } from './WorkDay';
import { useTranslation } from '@/hooks';

interface Locker {
  size: string;
  locker_number: string;
}

interface ExceptionDate {
  date: string;
  timeFrom: string;
  timeTo: string;
  closed: boolean;
}

interface FormValues {
  lattitude?: number;
  longitude?: number;
  coordinates: string;
  address?: string;
  name?: string;
  machine_type?: string;
  status?: string;
  phone?: string;
  workflow_type?: string;
  company_id?: number;
  city?: string;
  post_code?: string;
}

interface Props {
  open: boolean;
  item?: Item;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const AddRentalPoint = ({ open, onClose, onDone }: Props) => {
  const [type, setType] = useState('automatic');
  const [workTime, setWorkTime] = useState('everytime');
  const [lockers, setLockers] = useState<Locker[]>([]);
  const [workDays, setWorkDays] = useState<any[]>([]);
  const [exceptionDates, setExceptionDates] = useState<any[]>([]);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>({
    initialValues: {
      lattitude: 0,
      longitude: 0,
      coordinates: '',
      address: '',
      name: '',
      machine_type: type,
      status: '',
      phone: '',
      workflow_type: 'everytime',
      company_id: 0,
      city: '',
      post_code: '',
    },
    validate: {
      lattitude: (value) => (!value ? 'Value required' : null),
      longitude: (value) => (!value ? 'Value required' : null),
      address: (value) => (!value ? 'Value required' : null),
      name: (value) => (!value ? 'Value required' : null),
      city: (value) => (!value ? 'Value required' : null),
    },
  });

  const handlePlaceSelected = (place: {
    object: { address_components: any };
    address: string | undefined;
    lattitude: number | undefined;
    longitude: number | undefined;
  }) => {
    const getObjectByType = (data: any[], targetType: string) => {
      const foundObject = data.find((component) =>
        component.types.includes(targetType),
      );

      return foundObject || null;
    };

    const postal_code = getObjectByType(
      place.object.address_components,
      'postal_code',
    );
    const city = getObjectByType(place.object.address_components, 'locality');

    form.setFieldValue('address', place.address);
    console.log(place);
    form.setFieldValue('lattitude', place.lattitude);
    form.setFieldValue('longitude', place.longitude);
    form.setFieldValue(
      'coordinates',
      `${place.longitude} - ${place.lattitude}`,
    );
    form.setFieldValue('city', city.short_name);
    form.setFieldValue('post_code', postal_code.short_name);
  };

  const setMachineType = async (value: string) => {
    setType(value);
    form.setFieldValue('machine_type', value);
  };

  const setWorkFlowType = (type: string) => {
    form.setFieldValue('workflow_type', type);
    setWorkTime(type)
  }

  const handleDatesChange = (updatedDates: any) => {
    const timeField = {
      type: workTime,
      data: updatedDates
    }
    form.setFieldValue('workflow_type', JSON.stringify(timeField))
    setWorkDays(updatedDates);
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    const data = {
      ...values,
      status: 'MAINTENANCE',
      exception_dates: JSON.stringify(exceptionDates)
    };

    if (type === 'automatic') {
      // @ts-ignore
      const machine = await addMachine(data);
      for (const locker of lockers) {
        const lockerData = {
          locker_number: locker.locker_number,
          size: locker.size,
          status: 'AVAILABLE',
          machine_id: machine.id
        };
        console.log(locker)
        await addLockerBox(lockerData)
      }
      await onDone();
      form.reset();
    } else if (type === 'physical') {
      // @ts-ignore
      await addRentalPoint(data);

      await onDone();
      form.reset();
    }
    onClose();

    setLoading(false);
};


  const addLocker = () => {
    const newLocker: Locker = {
      size: '',
      locker_number: '',
    };
    setLockers((prevLockers) => [...prevLockers, newLocker]);
  };

  const removeLockerByIndex = (indexToRemove: number) => {
    setLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers.splice(indexToRemove, 1);
      return updatedLockers;
    });
  };

  const handleSizeChange = (selectedSize: string, index: number) => {
    setLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers[index] = {
        ...updatedLockers[index],
        size: selectedSize,
      };
      return updatedLockers;
    });
  };

  const handleIdChange = (inputId: string, index: number) => {
    setLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers[index] = {
        ...updatedLockers[index],
        locker_number: inputId,
      };
      return updatedLockers;
    });
  };

  const addExceptionDate = () => {
    const exceptionDate: ExceptionDate = {
      date: '',
      timeFrom: '',
      timeTo: '',
      closed: false
    };
    setExceptionDates((prevExceptions) => [...prevExceptions, exceptionDate]);
  };

  const handleStatusChange = (status: boolean, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        closed: status,
      };
      return updatedExceptions;
    });
  };

  const handleDate = (date: any, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        date: date,
      };
      return updatedExceptions;
    });
  };

  const handleFrom = (timeFrom: any, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        timeFrom: timeFrom,
      };
      return updatedExceptions;
    });
  };

  const handleTo = (timeTo: any, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        timeTo: timeTo,
      };
      return updatedExceptions;
    });
  };

  const removeExceptionByIndex = (indexToRemove: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions.splice(indexToRemove, 1);
      return updatedExceptions;
    });
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
        body: classes.body,
      }}
      opened={open}
      onClose={onClose}
      title={t('addrentalpoint')}
      centered
      size="70dvw"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.uppergrid} mb={rem(20)}>
          <TextInput label="Nosaukums" {...form.getInputProps('name')} />
          <Autocomplete
            label={t('legal_address')}
            onPlaceSelected={handlePlaceSelected}
            {...form.getInputProps('address')}
          />
          <Flex justify="space-between">
            <TextInput
              w="48%"
              label={t('city')}
              {...form.getInputProps('city')}
            />
            <TextInput
              w="48%"
              label={t('zipCode')}
              {...form.getInputProps('post_code')}
            />
          </Flex>
          <TextInput
            label={t('coordinates')}
            {...form.getInputProps('coordinates')}
            readOnly
          />
          <Radio.Group value={type}>
            <Text className={classes.label}>{t('rentalpointtype')}</Text>
            <Flex justify="space-between">
              <Flex
                w="48%"
                className={classes.selection}
                onClick={() => setMachineType('automatic')}
              >
                <Radio pl={rem(10)} label={t('automatic')} value="automatic" />
              </Flex>
              <Flex
                w="48%"
                className={classes.selection}
                onClick={() => setMachineType('physical')}
              >
                <Radio pl={rem(10)} label={t('physical')} value="physical" />
              </Flex>
            </Flex>
          </Radio.Group>
          <div />
          {type === 'automatic' ? (
            <Flex direction="column">
              <Button justify="start" variant="transparent" onClick={addLocker}>
                {t('addbox')}
              </Button>
              <ScrollArea mah={130}>
                {lockers.map((locker, index) => (
                  <Flex align="center">
                    <LockerBox
                      key={index}
                      locker_number={locker.locker_number}
                      locker_size={locker.size}
                      onIdChange={(locker_number) =>
                        handleIdChange(locker_number, index)
                      }
                      onSizeChange={(selectedSize) =>
                        handleSizeChange(selectedSize, index)
                      }
                    />
                    <ActionIcon
                      mt={rem(20)}
                      ml={rem(10)}
                      variant="transparent"
                      onClick={() => removeLockerByIndex(index)}
                    >
                      <Trash size={22} color="grey" />
                    </ActionIcon>
                  </Flex>
                ))}
              </ScrollArea>
            </Flex>
          ) : (
            <Flex justify="end">
              <Flex w="48%" justify="space-between">
                <CountryCode
                  label={t('phone')}
                  value="371"
                  defaultValue="371"
                  onChange={() => console.log('')}
                />
                <TextInput w="60%" label=" " />
              </Flex>
            </Flex>
          )}
          <div />
        </Box>
        <SectionHeader title={t('worktime')} />
        <Box className={classes.uppergrid}>
          <Radio.Group value={workTime}>
            <Text className={classes.label}>{t('availability')}</Text>
            <Flex justify="space-between">
              <Flex
                w="48%"
                className={classes.selection}
                onClick={() => setWorkFlowType('everytime')}
              >
                <Radio pl={rem(10)} label="24/7" value="everytime" />
              </Flex>
              <Flex
                w="48%"
                className={classes.selection}
                onClick={() => setWorkFlowType('selected')}
              >
                <Radio pl={rem(10)} label={t('limited')}value="selected" />
              </Flex>
            </Flex>
          </Radio.Group>
        </Box>
        {workTime === 'selected' ? (
          <Box className={classes.grid}>
            <div>
            </div>
            <Flex>
              <WorkDay 
                onDatesChange={handleDatesChange}
              />
            </Flex>
          </Box>
          ) : null}
        <Box mt={rem(20)}>
          <Button justify="start" variant="transparent" onClick={addExceptionDate}>
            {t('exception_date')}
          </Button>
          <div />
          <Flex w={'100%'} direction="column">
            {exceptionDates.map((item, index) => (
              <ExceptionDate
                onStatusChange={(status) => handleStatusChange(status, index)}
                onDateChange={(value) => handleDate(value, index)}
                onTimeFromChange={(date) => handleFrom(date, index)}
                onTimeToChange={(date) => handleTo(date, index)}
                removeItemByIndex={() => removeExceptionByIndex(index)}
                key={index} 
                item={item}  
              />
            ))}
          </Flex>
        </Box>
        <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
          <Button
            variant="transparent"
            color="gray"
            onClick={handleClose}
            disabled={loading}
          >
            Atcelt
          </Button>
          <Button type="submit" loading={loading}>
            Saglabāt
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
