import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const removeRentalPoint = async (point_id: number) => {
  try {
    const resp = await httpClient().delete(`/rentalpoints/delete/${point_id}`);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Nomas punkts dzēsts');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Punkta dzēšana neveiksmīga');
    console.error(error);
  }
};
