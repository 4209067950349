import { Checkbox, Flex, Text, rem } from '@mantine/core';
import cx from 'classnames';
import { MachineBox } from '@/interfaces';
import classes from './Locker.module.css';

interface Props {
  box: MachineBox;
  selected: boolean;
  onSelect: (id: number) => void;
}

export const Locker = ({ box, selected, onSelect }: Props) => (
  <Flex
    className={cx(classes.box, { [classes.selected]: selected })}
    justify="space-between"
    onClick={() => onSelect(box.id)}
    mb={rem(10)}
  >
    <Flex direction="column">
      <Text className={classes.label}>Izmērs</Text>
      <Text fz={rem(16)} fw={600}>
        {box.size}
      </Text>
    </Flex>
    <Flex direction="column">
      <Text className={classes.label}>Skapīša Nr.</Text>
      <Text>{box.locker_number}</Text>
    </Flex>
    <Checkbox radius={4} checked={selected} />
  </Flex>
);
