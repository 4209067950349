import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const confirmPasswordReset = async (
  code: string,
  email: string,
  password: string,
) => {
  try {
    const resp = await httpClient().post('/admin/confirmforgot', {
      code,
      email,
      password,
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Password changed succesfully');
      return resp;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to change password');
    console.error(error);
  }

  return undefined;
};
