import { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Group,
  InputLabel,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { useTranslation } from '@/hooks';
import { VIEWS } from '../interfaces';
import { ProfileImage, Radio } from '@/components';
import { updateSubAdmin } from '@/lib/updateSubAdmin';
import { useRegistration } from '@/hooks/useRegistration';
import { errorNotification } from '@/util';
import { updateSubCompany } from '@/lib/updateSubCompany';
import { addSubAdminImage } from '@/lib/addSubAdminImage';
import { BUSINESS_ACTIVITIES } from '@/constants/businessActivity';

interface FormValues {
  type: string;
  name: string;
  personalCode1?: number;
  personalCode2?: number;
  username?: string;
  registrationNumber?: number;
  vatPayer?: boolean;
  businessActivity?: string;
  vatPayerNumber?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  image?: File | undefined;
}

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const ProfileDetails = ({ onViewChange }: Props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const registrationData = useRegistration((state) => state.user);
  const form = useForm<FormValues>({
    initialValues: {
      type: 'individual',
      name: '',
    },
    validate: {
      name: (value) => (!value ? 'Please enter name' : null),
      personalCode1: (value, values) =>
        values.type === 'individual'
          ? !value
            ? 'Please enter valid personal code'
            : null
          : null,
      personalCode2: (value, values) =>
        values.type === 'individual'
          ? !value
            ? 'Please enter valid personal code'
            : null
          : null,
      username: (value, values) =>
        values.type === 'legal'
          ? !value
            ? 'Please enter valid username'
            : null
          : null,
      registrationNumber: (value, values) =>
        values.type === 'legal'
          ? !value
            ? 'Please enter valid registration number'
            : null
          : null,
      address: (value, values) =>
        values.type === 'legal'
          ? !value
            ? 'Please enter valid address'
            : null
          : null,
      city: (value, values) =>
        values.type === 'legal'
          ? !value
            ? 'Please enter valid city'
            : null
          : null,
      zipCode: (value, values) =>
        values.type === 'legal'
          ? !value
            ? 'Please enter valid zip code'
            : null
          : null,
    },
  });

  const handleTypeSwitch = (value: string) => {
    form.reset();
    form.setFieldValue('type', value);
  };

  const handleSave = async (values: FormValues) => {
    const {
      name,
      type,
      address,
      personalCode1,
      personalCode2,
      registrationNumber,
      username,
      vatPayerNumber,
      vatPayer,
      businessActivity,
      image,
    } = values;
    setLoading(true);

    try {
      if (image) {
        await addSubAdminImage(image);
      }

      // TODO: submit new field Business activity / Saimnieciskā darbība.
      await updateSubAdmin(registrationData.adminId as string, {
        image_url: image ? `${dayjs().format('YYYY-MM-DD')}${image.name}` : '',
        user_type: type,
        username,
        is_vat_payer: vatPayer,
        business_activity: businessActivity,
        personal_code:
          type === 'individual'
            ? `${personalCode1}${personalCode2}`
            : undefined,
      });

      await updateSubCompany(registrationData.companyId as string, {
        company_type: type,
        name,
        registration_number:
          type === 'individual'
            ? `${personalCode1}${personalCode2}`
            : registrationNumber?.toString() || '',
        legal_address: address,
        vat_number: vatPayerNumber,
      });

      onViewChange(VIEWS.VERIFF);
    } catch (error) {
      console.error(error);
      errorNotification('Failed to update user data');
    }

    setLoading(false);
  };

  return (
    <Box py={rem(60)} ml={rem(34)}>
      <form onSubmit={form.onSubmit(handleSave)}>
        <Text fz={rem(35)} fw={600}>
          {t('profile_title')}
        </Text>
        <Text fz={rem(18)} mb={rem(50)}>
          {t('profile_text')}
        </Text>
        <Group grow gap="sm">
          <Radio
            label={t('individual')}
            checked={form.values.type === 'individual'}
            onChange={() => handleTypeSwitch('individual')}
          />
          <Radio
            label={t('legal')}
            checked={form.values.type === 'legal'}
            onChange={() => handleTypeSwitch('legal')}
          />
        </Group>
        <Stack mt={rem(70)} justify="space-between">
          {form.values.type === 'individual' && (
            <Group grow gap={rem(30)} align="flex-start">
              <Stack gap={rem(30)}>
                <TextInput
                  label={t('displayName')}
                  required
                  {...form.getInputProps('name')}
                />

                <Stack gap={0}>
                  <InputLabel required>{t('personal_code')}</InputLabel>
                  <Flex gap="2px" align="center">
                    <NumberInput
                      placeholder="010199"
                      maxLength={6}
                      hideControls
                      {...form.getInputProps('personalCode1')}
                    />
                    <span>-</span>
                    <NumberInput
                      placeholder="12345"
                      maxLength={5}
                      hideControls
                      {...form.getInputProps('personalCode2')}
                    />
                  </Flex>
                </Stack>
                <Group grow gap={rem(30)}>
                  <Checkbox
                    label={t('vatPayer')}
                    radius={3}
                    width="100%"
                    {...form.getInputProps('vatPayer')}
                  />
                  {form.values.vatPayer && (
                    <Select
                      label={t('businessActivity')}
                      data={BUSINESS_ACTIVITIES.map((act) => ({
                        value: act,
                        label: t(act),
                      }))}
                      {...form.getInputProps('businessActivity')}
                    />
                  )}
                </Group>
              </Stack>
              <Stack gap={0}>
                <InputLabel>{t('profile_image')}</InputLabel>
                <ProfileImage
                  onChange={(file) => form.setFieldValue('image', file)}
                />
              </Stack>
            </Group>
          )}
          {form.values.type === 'legal' && (
            <Stack gap={rem(30)}>
              <Group grow gap={rem(30)}>
                <Stack gap={rem(30)}>
                  <TextInput
                    label={t('company_name')}
                    required
                    placeholder="SIA Kompānija"
                    {...form.getInputProps('name')}
                  />

                  <TextInput
                    label={t('company_username')}
                    required
                    placeholder="SIA Kompānija"
                    {...form.getInputProps('username')}
                  />
                </Stack>
                <Stack gap={0}>
                  <InputLabel>{t('profile_image')}</InputLabel>
                  <ProfileImage
                    onChange={(file) => form.setFieldValue('image', file)}
                  />
                </Stack>
              </Group>
              <Group grow justify="center">
                <Checkbox
                  label={t('vatPayer')}
                  radius={3}
                  {...form.getInputProps('vatPayer')}
                />
                {form.values.vatPayer && (
                  <TextInput
                    label={t('vat_number')}
                    placeholder="LV40000000000"
                    {...form.getInputProps('vatPayerNumber')}
                  />
                )}
              </Group>
              <Group grow justify="center">
                <TextInput
                  label={t('reg_number')}
                  required
                  placeholder="40000000000"
                  {...form.getInputProps('registrationNumber')}
                />
              </Group>
              <TextInput
                label={t('legal_address')}
                required
                placeholder="Laivu iela 20-17"
                {...form.getInputProps('address')}
              />
              <Group grow>
                <TextInput
                  label={t('city')}
                  required
                  placeholder="Rīga"
                  {...form.getInputProps('city')}
                />
                <TextInput
                  label={t('zipCode')}
                  required
                  placeholder="LV-1010"
                  {...form.getInputProps('zipCode')}
                />
              </Group>
            </Stack>
          )}

          <Group justify="space-between" gap={rem(30)} mt={rem(32)}>
            <Button type="submit" loading={loading}>
              {t('continue')}
            </Button>
            <Flex align="center">
              <Text c="red">*</Text>
              <Text fz={11} fw={400} c="#8F9098">
                - obligāti aizpildāmie lauki
              </Text>
            </Flex>
          </Group>
        </Stack>
      </form>
    </Box>
  );
};
