import { useState } from 'react';
import { Flex, Modal, rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { Button, CountryCode, TextInput } from '@/components';
import classes from './EditSelf.module.css';
import { Client } from '@/interfaces/client';
import { editSubAdmin } from '@/lib/editSubAdmin';

interface FormType {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  previous_email: string;
  role: string;
  aws_id: string;
  status: string;
  phone_area_code: string;
  // countryCode: string;
}

interface Props {
  user: any;
  open: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
}

export const EditSelf = ({ user, open, onClose, onSave }: Props) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<FormType>({
    initialValues: {
      first_name: user?.name,
      last_name: user?.surname,
      phone: user?.phone,
      email: user?.email,
      previous_email: user?.email,
      role: user?.role,
      aws_id: user?.awsId,
      status: user?.status,
      phone_area_code: '+371'
      // countryCode: user?.phone_area_code,
    },
    validate: {
      first_name: (value: string) => (!value ? 'Please enter name' : null),
      last_name: (value: string) => (!value ? 'Please enter surname' : null),
      phone: (value: string) => (!value ? 'Please enter phone number' : null),
      email: (value: string) =>
        !value ? 'Please enter valid email number' : null,
      // countryCode: (value: string) => (!value ? 'Please select' : null),
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleSubmit = async (values: FormType) => {
    setLoading(true);
    try {
      await editSubAdmin(values)
      await onSave();
      form.reset();
    } catch (error) {
      notifications.show({ message: 'Failed to add administrator' });
    }
    setLoading(false);
  };

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
      }}
      opened={open}
      onClose={onClose}
      title="Profila rediģēšana"
      centered
      size="xl"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className={classes.grid}>
          <TextInput label="Vārds" {...form.getInputProps('first_name')} />
          <TextInput label="Uzvārds" {...form.getInputProps('last_name')} />
          <TextInput label=" " {...form.getInputProps('phone')} />
          <TextInput label="E-pasts" {...form.getInputProps('email')} />
        </div>
        <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
          <Button
            variant="transparent"
            type="reset"
            onClick={handleClose}
            disabled={loading}
          >
            Atcelt
          </Button>
          <Button type="submit" loading={loading}>
            Saglabāt
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
