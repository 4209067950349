import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

interface Update {
  item_id: number;
  category_id: number
}

export const updateItemCat = async (item: Update) => {
  try {
    const resp = await httpClient().put(
      `/inventory_mgmt/tools/update/${item?.item_id}/`,
      {
        sub_category_id: item.category_id
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Category change success');
    }
    console.error(resp);
  } catch (error) {
    errorNotification(
      'Category change fail',
    );
    console.error(error);
  }
};
