import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker } from './Marker';
import { defaultProps, styles } from './constants';
import { MarkerType } from './interface';

const ZOOM_THRESHOLD = 11;

interface Props {
  markers: MarkerType[];
  onSelect: (id: number) => void;
}

export const Map = ({ markers, onSelect }: Props) => {
  const [currentZoom, setCurrentZoom] = useState(defaultProps.zoom);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key:
          process.env.NEXT_PUBLIC_GOOGLE_API_KEY ??
          'AIzaSyDaFxy3SXbjtAA5M42Im2Mz6TYc6uVqakU',
      }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      options={{ styles }}
      onZoomAnimationEnd={setCurrentZoom}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          {...marker}
          textHidden={currentZoom <= ZOOM_THRESHOLD}
          onSelect={() => onSelect(marker.id)}
        />
      ))}
    </GoogleMapReact>
  );
};
