import { useEffect, useMemo, useState } from 'react';
import { Box, Flex, Modal } from '@mantine/core';
import dayjs from 'dayjs';
import { Map } from './Map';
import { Machine, MachineBox } from '@/interfaces';
import classes from './AddSubscription.module.css';
import { LockerSelect } from './LockerSelect';
import { Button } from '@/components';
import { Subscribe } from './Subscribe';
import { subscribeToLocker } from '@/lib/subscribeToLocker';
import { useUser } from '@/hooks/useUser';
import { MONTHLY_PRICES, OFFLINE_STATUSES, YEARLY_PRICES } from './constants';

interface Props {
  open: boolean;
  machines?: Machine[];
  machineBoxes?: MachineBox[];
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const AddSubscriptions = ({
  open,
  machines,
  machineBoxes,
  onClose,
  onDone,
}: Props) => {
  const user = useUser((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState<Machine>();
  const [selectedMachineBoxes, setSelectedMachineBoxes] = useState<
    MachineBox[]
  >([]);
  const [selectedLockers, setSelectedLockers] = useState<number[]>([]);
  const [view, setView] = useState<number>(0);
  const [subType, setSubType] = useState<'monthly' | 'annual'>('monthly');

useEffect(() => {
  console.log(machineBoxes)
}, [machineBoxes])

  const getMachineBoxes = (id: number) => {
    console.log(JSON.stringify(machineBoxes))
    if (!machineBoxes) return [];

    const boxes = machineBoxes.filter(
      (mb) =>
        mb.machine_id === id &&
        (mb.company_id === 0 || !mb.company_id) &&
        !OFFLINE_STATUSES.includes(mb.status) &&
        (mb.current_item_id === 0 || !mb.current_item_id), 
    );

    return boxes;
  };

  const markers = useMemo(() => {
    const availableMachines = machines
      ? machines.filter(
          (machine) => machine.status !== 'MAINTENANCE' && !machine.company_id,
        )
      : [];

    return availableMachines.map((machine) => ({
      id: machine.id,
      value: getMachineBoxes(machine.id).length,
      lat: machine.lattitude,
      lng: machine.longitude,
    }));
  }, [machines]);

  const handleMachineSelect = (id: number) => {
    const machine = machines?.find((ma) => ma.id === id);

    if (machine) {
      setSelectedMachine(machine);
      const mbs = getMachineBoxes(id);
      console.log(mbs)
      setSelectedMachineBoxes(mbs);
    }
  };

  const handleSelectLockers = (id: number) => {
    if (selectedLockers.includes(id)) {
      setSelectedLockers(selectedLockers.filter((l) => l !== id));
    } else {
      setSelectedLockers([...selectedLockers, id]);
    }
  };

  const handleClose = () => {
    setView(0);
    setSelectedMachine(undefined);
    setSelectedLockers([]);
    setSelectedMachineBoxes([]);
    onClose();
  };

  const handleSubscribe = async () => {
    setLoading(true);

    const subs = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < selectedLockers.length; index++) {
      const id = selectedLockers[index];
      const box = selectedMachineBoxes.find((b) => b.id === id);

      if (box) {
        const companyID = user?.companyId || '';
        const subEnd =
          subType === 'monthly'
            ? dayjs().add(1, 'months')
            : dayjs().add(1, 'year');

        const sub = subscribeToLocker({
          id: box.id,
          aws_id: user?.awsId ?? '',
          amount:
            subType === 'monthly'
              ? MONTHLY_PRICES[box.size]
              : YEARLY_PRICES[box.size],
          company_id: parseInt(companyID),
          sub_end: subEnd.format('YYYY-MM-DD'),
          sub_start: dayjs().format('YYYY-MM-DD'),
          sub_type: subType,
        });

        subs.push(sub);
      }
    }

    await Promise.all(subs);

    await onDone();
    handleClose();
    setLoading(false);
  };

  return (
    <Modal
      title="Jauns abonements"
      opened={open}
      onClose={handleClose}
      centered
      size="80dvw"
      classNames={{ title: classes.title }}
    >
      <Box h="80vh">
        {view === 0 && (
          <Box className={classes.subContainer} h="95%">
            <Map markers={markers} onSelect={handleMachineSelect} />
            <Flex direction="column" justify="space-between">
              <LockerSelect
                machine={selectedMachine}
                boxes={selectedMachineBoxes}
                lockers={selectedLockers}
                onSelect={handleSelectLockers}
              />
            </Flex>
          </Box>
        )}
        {view === 1 && (
          <Subscribe
            machine={selectedMachine}
            boxes={selectedMachineBoxes}
            lockers={selectedLockers}
            onSubTypeChange={setSubType}
          />
        )}
        {selectedMachine && selectedMachineBoxes?.length > 0 && (
        <Flex justify="space-between" >
          <div>
            {view === 1 && (
              <Button variant="transparent" onClick={() => setView(0)}>
                Atpakaļ
              </Button>
            )}
          </div>
          <Flex justify="flex-end" gap="sm">
            <Button variant="transparent" onClick={handleClose}>
              Atcelt
            </Button>
            {view === 0 && (
              <Button
                onClick={() => setView(1)}
                disabled={selectedLockers.length === 0}
              >
                Turpināt
              </Button>
            )}
            {view === 1 && (
              <Button onClick={handleSubscribe} loading={loading}>
                Abonēt
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      </Box>
    </Modal>
  );
};
