export const STATUS_COLORS: { [key: string]: string } = {
  ACTIVE: 'lime',
  BLOCKED: 'gray',
  DELETED: 'red',
  GRAY: 'gray',
  EMPTY: 'gray',
  RENTED: 'red',
  AVAILABLE: 'lime',
  FREE: 'lime.4',
};
