import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

interface Props {
  stripe_id?: any
}

export const initiateCardSession = async ({ stripe_id }: Props) => {
  try {
    const resp = await httpClient().post(
      `/payments_mgmt/create-checkout-session?stripe_id=${stripe_id}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Session created');
      return resp.data;
    }
    console.error(resp.data.message);
  } catch (error) {
    errorNotification('Failed to get session');
    console.error(error);
  }

  return undefined;
};
