import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';
import { SubAdminRegisterResponse } from '@/interfaces';


export const createConnectedAccountSession = async (account_number: any) => {
  try {
    // const resp = await httpClient().post<SubAdminRegisterResponse>(
    const resp = await httpClient().post(
      `/payments_mgmt/create/connectedacc/session?subadmin_id=${account_number}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to register connected account for subadmin');
  }

  return undefined;
};
