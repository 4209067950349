import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface CategoryEdit {
  category_id: number;
  data: any;
}

export const editSubCategory = async ({ category_id, data }: CategoryEdit ) => {
  try {
    const resp = await httpClient().put(
      `/inventory_mgmt/category/edit/${category_id}`, 
      data
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Category Edited');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to edit category');
  }
};
