import { Flex, Modal, Text, rem } from '@mantine/core';
import { useState } from 'react';
import { Button } from '@/components';
import classes from './Reject.module.css';
import { rejectSubCompany } from '@/lib/rejectSubCompany';

interface Props {
  id?: string;
  open: boolean;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const Reject = ({ id, open, onClose, onDone }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleReject = async () => {
    setLoading(true);
    await rejectSubCompany(+id!);
    await onDone();
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      classNames={{ header: classes.header }}
      opened={open}
      onClose={onClose}
      centered
    >
      <Flex direction="column" gap={rem(15)} mt={rem(32)}>
        <Text fz={rem(26)} fw={600} ta="center">
          Noraidīt uzņēmumu
        </Text>
        <Text ta="center">Uzņēmums kļūs neaktīvs</Text>

        <Flex direction="column">
          <Button loading={loading} onClick={handleReject}>
            Noraidīt
          </Button>
          <Button variant="transparent" onClick={onClose}>
            Atcelt
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
