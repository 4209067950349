import { useMemo, useState } from 'react';
import { Box, Flex, Radio, Stack, Text, rem } from '@mantine/core';
import cx from 'classnames';
import { Link } from '@/components/Link';
import { Machine, MachineBox } from '@/interfaces';
import classes from './Subscribe.module.css';
import { MONTHLY_PRICES, YEARLY_PRICES } from '../constants';

interface ListType {
  size: string;
  count: number;
  price: number;
}

interface Props {
  machine?: Machine;
  boxes: MachineBox[];
  lockers: number[];
  onSubTypeChange: (value: 'monthly' | 'annual') => void;
}

export const Subscribe = ({
  machine,
  boxes,
  lockers,
  onSubTypeChange,
}: Props) => {
  const [subType, setSubType] = useState<'monthly' | 'annual'>('monthly');

  const list = useMemo(() => {
    const listItems: { [key: string]: ListType } = {};

    lockers.forEach((locker) => {
      const PRICES = subType === 'monthly' ? MONTHLY_PRICES : YEARLY_PRICES;
      const box = boxes.find((b) => b.id === locker);

      if (box && box?.size) {
        if (listItems[box.size]) {
          listItems[box.size].count += 1;
          listItems[box.size].price += PRICES[box?.size || '-'];
        } else {
          listItems[box.size] = {
            size: box.size,
            count: 1,
            price: PRICES[box?.size || '-'],
          };
        }
      }
    });

    return listItems;
  }, [lockers, boxes, subType]);

  const getTotal = () => (
    <Text fw={500}>
      {`${Object.values(list).reduce((prev, cur) => prev + cur.price, 0)}€`}
    </Text>
  );

  const handleSubChange = (value: 'monthly' | 'annual') => {
    setSubType(value);
    onSubTypeChange(value);
  };

  return (
    <Box className={classes.container}>
      <Flex direction="column" gap={rem(20)}>
        <Text>{machine?.address}</Text>

        {Object.values(list).map((item) => (
          <Box className={classes.locker}>
            <Flex direction="column">
              <Text className={classes.label}>Izmērs</Text>
              <Text>{item.size}</Text>
            </Flex>
            <Flex direction="column">
              <Text className={classes.label}>Skaits</Text>
              <Text>{item.count}</Text>
            </Flex>
            <Flex direction="column">
              <Text className={classes.label}>Maksa</Text>
              <Text>{`${item.price}€`}</Text>
            </Flex>
          </Box>
        ))}

        <Stack gap={rem(30)}>
          <Radio
            className={cx(classes.subType, {
              [classes.selected]: subType === 'monthly',
            })}
            name="subType"
            checked={subType === 'monthly'}
            onChange={() => handleSubChange('monthly')}
            label={
              <Flex gap={rem(25)} align="center">
                <Text>Mēneša abonents</Text>
                {subType === 'monthly' && getTotal()}
              </Flex>
            }
          />
          <Radio
            className={cx(classes.subType, {
              [classes.selected]: subType === 'annual',
            })}
            name="subType"
            checked={subType === 'annual'}
            onChange={() => handleSubChange('annual')}
            label={
              <Flex gap={rem(25)}>
                <Text>Gada abonents</Text>
                {subType === 'annual' && getTotal()}
              </Flex>
            }
          />
        </Stack>

        <Flex gap={rem(10)} align="center">
          <Text>Nospiežot “Abonēt”, Jūs piekrītat</Text>
          <Link href="#">Lietošanas noteikumiem</Link>
        </Flex>
      </Flex>
    </Box>
  );
};
