import { MachineCleanup } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getMachineCleanups = async (machineId: number) => {
  try {
    const resp = await httpClient().get<MachineCleanup[]>(
      `/machines/cleanup/history/getall/${machineId}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get machine cleanup history');
  }

  return undefined;
};
