import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const deleteClient = async (user: Client) => {
  try {
    const resp = await httpClient().delete(
      `/clients/client/remove/${user?.id}/`);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User deleted');
      return resp.data;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to delete user');
    console.error(error);
  }

  return undefined;
};
