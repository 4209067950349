import { Admin } from '@/interfaces';
import { errorNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const rejectSubCompany = async (companyId: number) => {
  try {
    const resp = await httpClient().post<Admin>(
      `/subcompany/decline/${companyId}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to reject sub company');
    console.error(error);
  }

  return undefined;
};
