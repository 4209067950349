/* eslint-disable no-restricted-syntax */
import { Item } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getItems = async (companyId: any) => {
  try {
    const resp = await httpClient().get<Item[]>(
      `/inventory_mgmt/get/company/${companyId}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      const items = resp.data;

      return items;
    }
  } catch (error) {
    errorNotification('Failed to get company items');
  }

  return undefined;
};
