import {
  BadgeProps,
  Flex,
  Badge as MantineBadge,
  Text,
  rem,
} from '@mantine/core';
import classes from './BoxBadge.module.css';

interface Props extends BadgeProps {
  type?: 'MAINTANENCE' | 'AVAILABLE' | 'PARTMAINTENANCE' | 'DISABLED' | 'MAINTANENCE' | 'PARTMAINTANENCE';
}

const badgeColor: Record<string, string> = {
  AVAILABLE: '#E9F8BF',
  MAINTANENCE: '#FEE1C7'
};

const badgeTexColor: Record<string, string> = {
  AVAILABLE: '#8CB80A',
  MAINTANENCE: '#EC7004'
};

const statusText: Record<string, string> = {
  AVAILABLE: 'Pieejams',
  MAINTANENCE: 'Abonēts',
};

export const BoxBadge = ({ type = 'MAINTANENCE' }: Props) => {
  const displayText = statusText[type] || statusText.MAINTENANCE;
  return (
    <Flex maw={rem(200)} direction="column">
      <MantineBadge
        color={badgeColor[type]}
        w="auto"
        style={{ textTransform: 'none' }}
      >
        <span style={{ color: badgeTexColor[type] }}>{displayText}</span>
      </MantineBadge>
    </Flex>
  );
};
