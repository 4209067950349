import { ScrollArea, Flex, Text, rem } from '@mantine/core';
import { Info } from '@phosphor-icons/react/dist/ssr';
import { Locker } from './Locker';
import { Machine, MachineBox } from '@/interfaces';

interface Props {
  machine?: Machine;
  boxes: MachineBox[];
  lockers: number[];
  onSelect: (id: number) => void;
}

export const LockerSelect = ({ machine, boxes, lockers, onSelect }: Props) => (
  <Flex direction="column" justify="space-between">
    <Flex direction="column" gap={rem(25)}>
      {!machine && (
        <Text>
          <Info /> Lai veiktu abonēšanu, izvēlaties nomas punktu
        </Text>
      )}
      {machine && <Text fw={700}>{machine.address}</Text>}
      <ScrollArea h={'60vh'}>
        {boxes
          .filter((box) => box.status === "AVAILABLE")
          .map((box) => (
            <Locker
              key={box.id}
              box={box}
              selected={lockers.includes(box.id)}
              onSelect={onSelect}
            />
          ))}
        {machine && boxes.length === 0 && (
          <Text>Nav pieejami skapīši, izvēlieties citu nomas punktu</Text>
        )}
      </ScrollArea>
    </Flex>
  </Flex>
);
