import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export interface Body {
  id: number;
  aws_id: string;
  sub_type: string;
  amount: number;
  sub_start: string;
  sub_end: string;
  company_id: any;
}

export const subscribeToLocker = async (body: Body) => {
  try {
    const resp = await httpClient().put('/machines/machinebox/subscribe', body);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Subscription added');
      return resp.data;
    }
    console.error(resp.data.message);
  } catch (error) {
    errorNotification('Failed to add subscription');
    console.error(error);
  }

  return undefined;
};
