import { generate } from 'generate-password';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface Body {
  name: string;
  surname: string;
  phone: string;
  email: string;
  generatePassword: boolean;
  countryCode: string;
  password: string;
  roles: string[];
  company_id?: string;
}

export const addAdmin = async (body: Body) => {
  try {
    const resp = await httpClient().post('/admin/createadmin', {
      email: body.email,
      password: body.generatePassword
        ? generate({
            length: 12,
            numbers: true,
            symbols: true,
            excludeSimilarCharacters: true,
            strict: true,
          })
        : body.password,
      given_name: body.name,
      family_name: body.surname,
      phone: `+${body.countryCode}${body.phone}`,
      role: body.roles.join(','),
      company_id: body.company_id
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User created');
      return resp.data;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to add user');
  }

  return undefined;
};
