export const ADMIN_ROLES = [
  {
    value: 'Transactor',
    label: 'Transactor',
  },
  {
    value: 'Rentaler',
    label: 'Rentaler',
  },
  {
    value: 'CompanyManager',
    label: 'CompanyManager',
  },
  {
    value: 'ItemAnalytics',
    label: 'ItemAnalytics',
  },
  {
    value: 'UserManager',
    label: 'UserManager',
  },
];
