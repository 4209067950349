'use client';

import dayjs from 'dayjs';
import { Flex, rem } from '@mantine/core';
import useSWR from 'swr';
import { useTranslation } from '@/hooks';
import classes from './Home.module.scss';
import { SimpleStatBlock, UserTraffic, PageHeader } from '@/components';
import { getMetrics } from '@/lib/getMetrics';
import { formatCurrency } from '@/util';

export const Home = () => {
  const { t } = useTranslation();
  const { data } = useSWR('/event/metrics', () => getMetrics());

  return (
    <Flex direction="column" gap={rem(24)}>
      <div
        className={classes.Heading}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <PageHeader title={t('home')} />
        <div className={classes.date}>{dayjs().format('DD.MM.YYYY')}</div>
      </div>
      <Flex w="100%" gap={rem(24)}>
        <div className={classes.grid}>
          <SimpleStatBlock
            title="Darījumu statistika"
            rows={[
              {
                name: 'Kopā:',
                value: data?.results.deals.total_amount || 0,
              },
              {
                name: 'Apgrozījums periodā (€):',
                value: data?.results.deals.turnover || 0,
              },
            ]}
          />
          <SimpleStatBlock
            title="Vidējā peļņa (€)"
            rows={[
              {
                name: 'No viena darījuma:',
                value: data?.results.income.average_from_deal || 0,
              },
              {
                name: 'No viena iznomātāja:',
                value: data?.results.income.average_from_renter || 0,
              },
            ]}
            dark
          />
          <div className={classes.fullRow}>
            <UserTraffic data={data?.results.active_users_month || []} />
          </div>
        </div>
        <Flex direction="column" gap={rem(24)} miw={rem(380)}>
          <SimpleStatBlock
            title="Apgrozījums pa skapīšiem (€)"
            rows={[
              {
                name: 'S (64 x 38 x 9 cm):',
                value: data?.results.locker_turnover.sm || 0,
              },
              {
                name: 'M (64 x 38 x 19 cm):',
                value: data?.results.locker_turnover.md || 0,
              },
              {
                name: 'L (64 x 38 x 39 cm):',
                value: data?.results.locker_turnover.lg || 0,
              },
              {
                name: 'XL (100 x 200 x 150 cm):',
                value: data?.results.locker_turnover.xl || 0,
              },
            ]}
            small
          />
          <SimpleStatBlock
            title="Skapīšu aizpildījums"
            rows={[
              {
                name: 'S (64 x 38 x 9 cm):',
                value: data?.results.locker_usage_percent.sm || 0,
              },
              {
                name: 'M (64 x 38 x 19 cm):',
                value: data?.results.locker_usage_percent.md || 0,
              },
              {
                name: 'L (64 x 38 x 39 cm):',
                value: data?.results.locker_usage_percent.lg || 0,
              },
              {
                name: 'XL (100 x 200 x 150 cm):',
                value: data?.results.locker_usage_percent.xl || 0,
              },
            ]}
            small
            className="mt-4"
          />
        </Flex>
      </Flex>
      <Flex gap={rem(24)}>
        <SimpleStatBlock
          title="Ienesīgākie automātiskie nomas punkti"
          rows={
            data?.results.high_profit_locker
              ? data?.results.high_profit_locker.map((record) => ({
                  name: record.name,
                  value: formatCurrency(record.income || 0),
                }))
              : []
          }
          list
        />
        <SimpleStatBlock
          title="Mazāk ienesīgie automātiskie nomas punkti"
          rows={
            data?.results.low_profit_locker
              ? data?.results.low_profit_locker.map((record) => ({
                  name: record.name,
                  value: formatCurrency(record.income || 0),
                }))
              : []
          }
          list
        />
      </Flex>
    </Flex>
  );
};
