import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const updateCategoryAttribute = async (item: any) => {
  try {
    const resp = await httpClient().patch<any>(
      `/inventory_mgmt/category/attribute/edit/${item?.attribute_id}`,
      {
        name: item.name,
        attribute_type: item.attribute_type,
        measure_unit: item.measure_unit
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Edited attribute');
    }
    console.error(resp);
  } catch (error) {
    errorNotification(
      'Failed to update attribute'
    );
    console.error(error);
  }
};
