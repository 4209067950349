'use client';

import { useState } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { Text, rem } from '@mantine/core';
import { Login } from './Login';
import { Register } from './Register';
import { VIEWS } from './interfaces';
import classes from './Auth.module.css';
import { useTranslation } from '@/hooks';
import logo from '@/public/images/logo_full_green.svg';
import { Verify } from './Verify';
import { ChangePass } from './ChangePass';
import { PasswordReset } from './PasswordReset';
import { ProfileDetails } from './ProfileDetails';
import { PaymentDetails } from './PaymentDetails';

const VeriffWrapper = dynamic(() => import('./Veriff/Veriff'), { ssr: false });

const VIEW_COMPONENTS = {
  [VIEWS.LOGIN]: Login,
  [VIEWS.REGISTER]: Register,
  [VIEWS.VERIFY]: Verify,
  [VIEWS.VERIFF]: VeriffWrapper,
  [VIEWS.CHANGE_PASS]: ChangePass,
  [VIEWS.RESTORE_PASS]: PasswordReset,
  [VIEWS.PROFILE]: ProfileDetails,
  [VIEWS.PAYMENT_DETAILS]: PaymentDetails,
};

export const Auth = () => {
  const { t } = useTranslation();
  const [view, setView] = useState<VIEWS>(VIEWS.LOGIN);

  const Component = VIEW_COMPONENTS[view];

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <Image src={logo} height={60} width={150} alt="Neiboo" />
        <div>
          <Text fz={rem(45)} fw={700}>
            {t('login.welcome')}
          </Text>
          <Text fz={rem(35)} fw={400}>
            {t('login.moto')}
          </Text>
        </div>
        <Text>{t('login.explanation')}</Text>
      </div>
      <div className={classes.form}>
        <Component onViewChange={setView} />
      </div>
    </div>
  );
};
