import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const checkInventoryNumber = async (query: any, company_id: any) => {
  try {
    const resp = await httpClient().post(
      `/inventory_mgmt/tools/check/inventorynumber/${company_id}?query=${query}`, {});

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Inventory number check success');
    }
    return resp.data
  } catch (error) {
    errorNotification('Inventory number check fail');
    console.error(error);
  }
};
