import { BadgeProps, Badge as MantineBadge } from '@mantine/core';

export type BADGE_TYPE =
  | 'DEFAULT'
  | 'RENTED'
  | 'INITIATED'
  | 'COMPLETED'
  | 'ACTIVE'
  | 'AVAILABLE'
  | 'FREE'
  | 'REGISTERING';

interface Props extends BadgeProps {
  type?: string | BADGE_TYPE;
}

const badgeColor: Record<string, string> = {
  DEFAULT: '#E9F8BF',
  RENTED: '#E9F8BF',
  ACTIVE: '#E9F8BF',
  REGISTERING: '#FEE1C7',
  AVAILABLE: '#FEE1C7',
  FREE: '#E9F8BF',
  INITIATED: '#FEE1C7',
  COMPLETED: '#E8E9F1',
  DAMAGED: '#E8E9F1',
  BLOCKED: '#E8E9F1',
  INTERRUPTED: '#E8E9F1',
};

const badgeTexColor: Record<string, string> = {
  DEFAULT: '#8CB80A',
  RENTED: '#8CB80A',
  ACTIVE: '#8CB80A',
  REGISTERING: '#EC7004',
  AVAILABLE: '#EC7004',
  FREE: '#8CB80A',
  INITIATED: '#EC7004',
  COMPLETED: '#494A50',
  DAMAGED: '#494A50',
  BLOCKED: '#494A50',
  INTERRUPTED: '#494A50',
};

const statusText: Record<string, string> = {
  DEFAULT: 'Aktīvs',
  RENTED: 'Aktīvs',
  ACTIVE: 'Aktīvs',
  REGISTERING: 'Reģistrācija',
  AVAILABLE: 'Nomas punktā',
  FREE: 'Nav ievietota',
  INITIATED: 'Rezervēts',
  COMPLETED: 'Pabeigts',
  DAMAGED: 'Bojāts',
  BLOCKED: 'Bloķēts',
  INTERRUPTED: 'Pabeigts',
};

export const Badge = ({ type = 'ACTIVE' }: Props) => {
  const displayText = statusText[type] || statusText.DEFAULT;

  return (
    <MantineBadge
      color={badgeColor[type]}
      w="auto"
      style={{ textTransform: 'none' }}
    >
      <span style={{ color: badgeTexColor[type] }}>{displayText}</span>
    </MantineBadge>
  );
};
