import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface Attribute {
  item_id: number;
  cat_attribute_id: number;
  value: string;
}

export const addItemAttribute = async (item: Attribute) => {
  try {
    const resp = await httpClient().post('/inventory_mgmt/category/item/attribute/create', {
      item_id: item.item_id,
      cat_attribute_id: item.cat_attribute_id,
      value: item.value
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Attribute created');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create attribute');
  }
};
