import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';
import { EventMetrics } from '@/interfaces';

export const getMetrics = async (): Promise<EventMetrics | undefined> => {
  try {
    const resp = await httpClient().get<EventMetrics>('/event/metrics');

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get event metrics');
  }

  return Promise.resolve(undefined);
};
