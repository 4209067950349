import { SubCompanyLegal } from '@/interfaces';
import { errorNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const getSubCompaniesIndividual = async () => {
  try {
    const resp = await httpClient().get<SubCompanyLegal[]>('/subcompany/all');

    if (OK_STATUSES.includes(resp.status)) {
      const legalEntities = resp.data.filter(
        (item) => item.company_type === 'individual',
      );
      return legalEntities;
    }
  } catch (error) {
    errorNotification('Failed to get sub companies');
    console.error(error);
  }

  return [];
};
