import { BadgeProps, Badge as MantineBadge } from '@mantine/core';

interface Props extends BadgeProps {
  type?: 'EMPTY' | 'AVAILABLE' | 'RENTING' | 'DISABLED' | 'FREE';
}

const badgeColor: Record<string, string> = {
  FREE: '#FEE1C7',
  EMPTY: '#E9F8BF',
  AVAILABLE: '#FEE1C7',
  RENTING: '#E8E9F1',
  DISABLED: '#FEE1C7',
};

const badgeTexColor: Record<string, string> = {
  FREE: '#EC7004',
  EMPTY: '#8CB80A',
  AVAILABLE: '#EC7004',
  RENTING: '#494A50',
  DISABLED: '#FEE1C7',
};

const statusText: Record<string, string> = {
  FREE: 'Tukšs',
  EMPTY: 'Tukšs',
  AVAILABLE: 'Aizņemts',
  RENTING: 'Prece nomā',
  DISABLED: 'Atslēgts',
};

export const SubscriptionBadge = ({ type = 'EMPTY' }: Props) => {
  const displayText = statusText[type] || statusText.DEFAULT;

  return (
    <MantineBadge
      color={badgeColor[type]}
      w="auto"
      style={{ textTransform: 'none' }}
    >
      <span style={{ color: badgeTexColor[type] }}>{displayText}</span>
    </MantineBadge>
  );
};
