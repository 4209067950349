import { Admin } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const blockAdmin = async (admin: Admin) => {
  try {
    const resp = await httpClient().post<Admin>('/admin/adminblockusr', admin);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User blocked');
      return resp.data;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to block user');
    console.error(error);
  }

  return undefined;
};
