import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  InputLabel,
  Modal,
  NumberInput,
  Select,
  Text,
  rem,
  Loader
} from '@mantine/core';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useForm } from '@mantine/form';
import { YearPickerInput } from '@mantine/dates';
import classes from './EditItem.module.css';
import {
  Button,
  MultiLevelSelect,
  MultiLevelSelectGroup,
  TagInput,
  TextInput,
  ImageInputs,
} from '@/components';
import { Item } from '@/interfaces';
import { getItemCategories } from '@/lib/getItemCategories';
import { LOCKER_SIZES } from '@/constants/sizes';
import { updateItem } from '@/lib/updateItem';
import { addItemImages } from '@/lib/addItemImage';
import { getCategoryAttributes } from '@/lib/getCategoryAttributes';
import { getCategories } from '@/lib/getCategories';
import { ItemAttribute } from '../NewItem/ItemAttribute';
import { getItemAttributes } from '@/lib/getItemAttributes';
import { updateItemAttribute } from '@/lib/updateItemAttribute';
import { checkInventoryNumber } from '@/lib/checkInventoryNumber';


interface FormValues {
  inventory_number?: string;
  category_name?: string;
  sub_category_id?: any;
  name?: string;
  model?: string;
  producer?: string;
  year_made?: any;
  price?: number;
  cost_per_day?: number;
  cost_per_h?: number;
  locker_size?: string;
  additional_equipment?: string;
}

interface Props {
  open: boolean;
  item?: Item;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const EditItem = ({ open, item, onClose, onDone }: Props) => {
  const [images, setImages] = useState<File[]>([]);
  const [catAttributes, setCatAttributes] = useState<any[]>([]);
  const [isValidInventory, setIsValidInventory] = useState(true);
  const [apiLoading, setIsApiLoading] = useState(false);

  const { data: categories } = useSWR('/inventory_mgmt/categories/allnodes/list', () =>
    getCategories(),
  );

  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>({
    initialValues: {
      inventory_number: item?.inventory_number,
      category_name: item?.category_name,
      sub_category_id: item?.sub_category_id,
      name: item?.name,
      model: item?.model,
      producer: item?.producer,
      year_made: item?.year_made,
      price: item?.price,
      cost_per_day: item?.cost_per_day,
      cost_per_h: item?.cost_per_h,
      locker_size: item?.locker_size,
      additional_equipment: item?.additional_equipment || '[]',
    },
  });

  useEffect(() => {
    if (item) {
      let yearMadeDate = null;
  
      if (item.year_made) {
        yearMadeDate = new Date(item.year_made, 0, 1);
      }
  
      form.setValues({
        inventory_number: item.inventory_number || "",
        category_name: item.category_name || "",
        sub_category_id: item.sub_category_id || "",
        name: item.name || "",
        model: item.model || "",
        producer: item.producer || "",
        year_made: yearMadeDate,
        price: item.price || 0,
        cost_per_day: item.cost_per_day || 0,
        cost_per_h: item.cost_per_h || 0,
        locker_size: item.locker_size || "",
        additional_equipment: item.additional_equipment || '[]',
      });

      getAttributes(item?.sub_category_id)
    }
  }, [item]);

  const categoryOptions = useMemo(
    () =>
      categories
        ? categories.map(
            (cat) =>
              ({
                id: cat.id.toString(),
                group: cat.name,
                options: cat.subcategories.map((sub: any) => ({
                  value: sub.id.toString(),
                  label: sub.name,
                })),
              }) as MultiLevelSelectGroup,
          )
        : [],
    [categories],
  );

  const handleSubmit = async (values: FormValues) => {
    if (item) {
      setLoading(true);
  
      const imageNames = images.map((img) => img.name);
      const yearMadeUnixTimestamp = Math.floor(new Date(values.year_made).getTime() / 1000);
  
      const { images_url, ...rest } = item;

      const itemToUpdate: any = {
        ...rest,
        ...values,
        year_made: 2024,
      };
  
      console.log(itemToUpdate);

      for (const attribute of catAttributes) {
        const payload = {
          attribute_id: attribute.item_attribute_id,
          value: attribute.value
        }
        await updateItemAttribute(payload)
      }
  
      // await addItemImages(images);
      await updateItem(itemToUpdate);
      await onDone();
      onClose();
  
      setLoading(false);
    }
  };
  
  const getAttributes = async (id: any) => {
    const response = await getCategoryAttributes(id);
    const itemAttrs = await getItemAttributes(item?.id)
    let attributes = []
    for (const item of response) {
      const itemData = {
        id: item.id,
        name: item.name,
        attribute_type: item.attribute_type,
        measure_unit: item.measure_unit,
        item_id: null,
        value: '',
        item_attribute_id: ''
      }
      // @ts-ignore
      const matchingAttr = itemAttrs.find(attr => attr.cat_attribute_id === item.id);
      
      if (matchingAttr) {
        itemData.item_id = matchingAttr.item_id;
        itemData.value = matchingAttr.value;
        itemData.item_attribute_id = matchingAttr.id
    }
      attributes.push(itemData); 
    }
    setCatAttributes(attributes)
  }

  const handleInputChange = (value: string, index: number) => {
    setCatAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        value: value,
      };
      return updatedAttributes;
    });
  };

  const handleFileChange = (files: string[]) => {
    console.log('file upload')
  };

  const handleClose = () => {};

  const checkInventoryNumberCall = async (value: any) => {
    setIsApiLoading(true)
    form.setValues({ inventory_number: value })
    
    try {
      const response = await checkInventoryNumber(value, item?.company_id);

      if (response === false && item?.inventory_number != value) {
        setIsValidInventory(response);
      }
    } catch (error) {
      console.error('Error checking inventory number:', error);
    } finally {
      setIsApiLoading(false)
    }
  };

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
        body: classes.body,
      }}
      opened={open}
      onClose={onClose}
      title="Redigēt preci"
      centered
      size="70dvw"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.grid}>
          <Flex direction="column" gap={rem(30)}>
          <TextInput
              label="Inventāra numurs"
              {...form.getInputProps('inventory_number')}
              onChange={(event) => checkInventoryNumberCall(event.target.value)}
              error={!isValidInventory && <Text fz={rem(10)}>Numurs ir aizņemts</Text>}
              rightSection={ apiLoading && <Loader size={'sm'} />}
            />
            <MultiLevelSelect
                label="Kategorija"
                options={categoryOptions}
                value={{ option: form.values.category_name || '' }}
                onChange={(_, option) => {
                  form.setFieldValue('sub_category_id', option.value);
                  form.setFieldValue('category_name', option.label);
                  getAttributes(option.value)
                }}
              />
            <TextInput label="Nosaukums" {...form.getInputProps('name')} />
            <TextInput label="Ražotājs" {...form.getInputProps('producer')} />
            <TextInput label="Modelis" {...form.getInputProps('model')} />
            <YearPickerInput
              label="Ražošanas gads"
              maxDate={new Date()}
              {...form.getInputProps('year_made')}
              onChange={(value) =>
                form.setFieldValue('year_made', value)
              }
            />
          </Flex>
          <Flex direction="column" gap={rem(30)}>
            <TextInput
              label="Preces vērtība"
              rightSection={<Text fz={rem(14)}>€</Text>}
              {...form.getInputProps('price')}
            />
            <div>
              <InputLabel>Nomas maksa</InputLabel>
              <Flex gap={rem(10)}>
                <NumberInput
                  decimalScale={2}
                  fixedDecimalScale
                  rightSection={
                    <Text fz={rem(14)} mr={rem(10)}>
                      €/h
                    </Text>
                  }
                  {...form.getInputProps('cost_per_h')}
                />
                <NumberInput
                  decimalScale={2}
                  fixedDecimalScale
                  rightSection={
                    <Text fz={rem(14)} mr={rem(10)}>
                      €/24h
                    </Text>
                  }
                  {...form.getInputProps('cost_per_day')}
                />
              </Flex>
            </div>
            <Select
              label="Skapīša izmērs"
              data={LOCKER_SIZES}
              {...form.getInputProps('locker_size')}
            />
            <div>
              <InputLabel>Attēls</InputLabel>
              <ImageInputs
                itemId={item?.id}
                value={JSON.parse(item?.images_url || '[]')}
                onChange={setImages}
              />
            </div>
            <TagInput
              label="Papildaprīkojums"
              placeholder='Ievadīt un nospiest "Enter"'
              initialTags={JSON.parse(form.values.additional_equipment ?? '[]')}
              onCreate={(items) =>
                form.setFieldValue(
                  'additional_equipment',
                  JSON.stringify(items),
                )
              }
            />
          </Flex>
          <Flex direction="column" gap={rem(30)}>
            {catAttributes.map((item: any, index: number) => (
              <ItemAttribute
                key={index} 
                item={item}
                onFileChange={handleFileChange}
                onInputChange={(value) => handleInputChange(value, index)}
              />
            ))}
          </Flex>
        </Box>
        <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
          <Button
            variant="transparent"
            color="gray"
            onClick={handleClose}
            disabled={loading}
          >
            Atcelt
          </Button>
          <Button type="submit" loading={loading}>
            Saglabāt
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
