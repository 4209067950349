import { useState, useMemo } from 'react';
import { Box, Flex, Modal, Text, rem } from '@mantine/core';
import classes from './RemoveMachine.module.css';
import { Button } from '@/components';
import { deleteCategory } from '@/lib/deleteCategory';
import { setMachineStatus } from '@/lib/setMachineStatus';
import { removeMachine } from '@/lib/removeMachine';
import { removeRentalPoint } from '@/lib/removeRentalPoint';

interface Props {
  open: boolean;
  machine: any;
  onClose: () => void;
  onSuccess: () => void;
}

export const RemoveMachine = ({ open, onClose, onSuccess, machine }: Props) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const machineData = useMemo(() => {
    if (!machine) return {};
    else {
      return machine;
    }
  }, [machine]);

  const handleMaintenance = async () => {
    setLoading(true);
    try {
      if (machineData.machine_type === 'automatic') {
        await removeMachine(machineData?.id);
        setCurrentStep(2);
        onSuccess();
      } else if (machineData.machine_type === 'physical') {
        await removeRentalPoint(machineData?.id)
        setCurrentStep(2);
        onSuccess();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setCurrentStep(1);
  };

  return (
    <Modal opened={open} onClose={handleClose} centered size="30dvw">
      <Flex justify="center">
        {currentStep === 1 ? (
          <Box h={rem(200)}>
            <Text mb={rem(10)} className={classes.title}>
              Nomas punkta dzēšana
            </Text>
            <Text m={rem(10)} className={classes.subtitle}>
              Nomas punkts tiks dzēsts un nebūs pieejams darījumiem
            </Text>
            <Flex m={rem(10)} direction="column">
              <Button loading={loading} color="red" onClick={handleMaintenance}>
                Dzēst
              </Button>
              <Button
                variant="transparent"
                type="reset"
                color="grey"
                onClick={handleClose}
              >
                Atcelt
              </Button>
            </Flex>
          </Box>
        ) : null}
        {currentStep === 2 ? (
          machineData.status != 'MAINTENANCE' ? (
            <Box h={rem(200)}>
              <Text mb={rem(10)} className={classes.title}>
                Nomas punkts ir dzēsts
              </Text>
              <Text m={rem(10)} className={classes.subtitle}>
                Visas ar nomas punktu saistītās darbības nav pieejamas.
              </Text>
              <Flex m={rem(10)} direction="column">
                <Button
                  variant="transparent"
                  mt={rem(50)}
                  type="reset"
                  color="grey"
                  onClick={handleClose}
                >
                  Atcelt
                </Button>
              </Flex>
            </Box>
          ) : (
            <Box h={rem(200)}>
              <Text mb={rem(10)} className={classes.title}>
                Apkope sākta
              </Text>
              <Text m={rem(10)} className={classes.subtitle}>
                Nomas punkta apkope ir sākta, un skapīši nav pieejami preču
                ievietošanai.
              </Text>
              <Flex m={rem(10)} direction="column">
                <Button
                  variant="transparent"
                  mt={rem(50)}
                  type="reset"
                  color="grey"
                  onClick={handleClose}
                >
                  Atcelt
                </Button>
              </Flex>
            </Box>
          )
        ) : null}
      </Flex>
    </Modal>
  );
};
