import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface RentalPoint {
  lattitude: number;
  longitude: number;
  address: string;
  name: string;
  machine_type: string;
  status: string;
  phone: string;
  company_id: any;
}

export const addRentalPoint = async (item: RentalPoint) => {
  try {
    const resp = await httpClient().post('/rentalpoints/create', item);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Rental point created');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create Rental Point');
  }
};
