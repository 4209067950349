'use client';

import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useParams, useRouter } from 'next/navigation';
import useSWR from 'swr';
import { Menu, UnstyledButton, Flex, Text, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DotsThreeVertical } from '@phosphor-icons/react/dist/ssr';
import {
  Button,
  Info,
  PageHeader,
  SectionTitle,
  Table,
  TableDataRow,
  TableHeader,
} from '@/components';
import { LockerBadge } from '@/components/Badge/LockerBadge';
import { getMachine } from '@/lib/getMachine';
import { getRentalPoint } from '@/lib/getRentalPoint';
import classes from './RentailPoint.module.css';
import { HISTORY_HEADERS, MAINTENANCE_HEADERS } from './tableHeaders';
import { getMachineCleanups } from '@/lib/getMachineCleanups';
import { Maintenance } from '../RentalPoints/Mainenance';
import { useUser } from '@/hooks/useUser';
import { RemoveMachine } from '../RentalPoints/RemoveMachine';
import { EditRentalPoint } from '../RentalPoints/EditRentalPoint';
import { RentalItems } from '../RentalPoints/RentalItems';
import { getRentalPointItems } from '@/lib/getRentalPointItems';
import { Badge } from '@/components/Badge';
import { getMachineBoxesById } from '@/lib/getMachineBoxesById';
import { BoxBadge } from '@/components/Badge/BoxBadge';

const HEADERS: TableHeader[] = [
  {
    value: 'name',
    label: 'Nosaukums',
  },
  {
    value: 'producer',
    label: 'Razotajs.',
  },
  {
    value: 'model',
    label: 'Modelis',
  },
  { value: 'status', label: 'Status' },
];

const LOCKER_HEADERS: TableHeader[] = [
  {
    value: 'locker_number',
    label: 'Identifikators',
  },
  {
    value: 'size',
    label: 'Izmērs',
  },
  {
    value: 'status',
    label: 'Status',
  },
];

interface Props {
  pointId: number;
  pointType: string;
}

export const RentalPoint = ({ pointId, pointType }: Props) => {
  const router = useRouter();
  const user = useUser((state) => state.user);
  const params = useParams();
  const [workDates, setWorkDates] = useState<any[]>([]);
  const [workType, setWorkType] = useState<any>('everytime');
  const [exceptionDates, setExceptionDates] = useState<[]>([]);
  const [rentalPointItems, setRentalPointItems] = useState<any[]>([]);
  const [pointLockers, setPointLockers] = useState<any[]>([]);

  const { data, mutate } = useSWR(
    () => {
      if (pointType === 'automatic') {
        return `/rental-point/${pointId}`;
      }
      if (pointType === 'physical') {
        return `/rentalpoints/get/byid/${pointId}`;
      }
    },
    () => {
      if (pointType === 'automatic') {
        return getMachine(pointId);
      }
      if (pointType === 'physical') {
        return getRentalPoint(pointId);
      }
    },
  );

  const {
    data: cleanUpHistory,
    isLoading: cleanupLoading,
    mutate: reFreshCleanup,
  } = useSWR(
    () => `/rental-point/${pointId}/cleanup/history`,
    () => getMachineCleanups(pointId),
  );

  const [openMaintenance, modalMaintenanceActions] = useDisclosure(false);
  const [openItems, modalItemsActions] = useDisclosure(false);
  const [openRemoval, modalRemovalActions] = useDisclosure(false);
  const [openEdit, modalEditActions] = useDisclosure(false);

  const handleRefresh = async () => {
    await mutate();
    await reFreshCleanup();
    if (pointType === 'physical') {
      getRentalPointItems(pointId).then((response) =>
        setRentalPointItems(response),
      );
    }
  };

  const handleDelete = async () => {
    if (user?.role === 'Admin') {
      router.push(`/${params.lang}/rental-points`);
    } else {
      router.push(`/${params.lang}/physical-points`);
    }
  };

  const cleanupData = useMemo(() => {
    if (!cleanUpHistory) return [];

    return cleanUpHistory
      .filter((row) => row.endTime === null)
      .map((row) => ({
        _id: { value: row.id.toString() },
        start: { value: dayjs(row.startTime).format('DD.MM.YYYY') },
        description: { value: row.description },
        lockers: { value: row.boxes },
      })) as TableDataRow[];
  }, [cleanUpHistory]);

  const cleanupDataHistory = useMemo(() => {
    if (!cleanUpHistory) return [];

    return cleanUpHistory
      .filter((row) => row.endTime != null)
      .map((row) => ({
        _id: { value: row.id.toString() },
        start: { value: dayjs(row.startTime).format('DD.MM.YYYY') },
        end: {
          value: row.endTime ? dayjs(row.endTime).format('DD.MM.YYYY') : '',
        },
        description: { value: row.description },
        lockers: { value: row.boxes },
      })) as TableDataRow[];
  }, [cleanUpHistory]);

  const formatDate = (date: string) => {
    const obj = new Date(date);
    return obj.toLocaleDateString('en-GB');
  };

  const items = useMemo(() => {
    if (!rentalPointItems) return [];

    return rentalPointItems.map(
      (row: any) =>
        ({
          _id: { value: row.id },
          name: {
            value: `${row.name}`,
          },
          producer: { value: row.producer },
          model: { value: row.model },
          role: {
            value: row.role,
          },
          status: {
            value: row.status,
            display: <Badge type={row.status} />,
          },
        }) as TableDataRow,
    );
  }, [rentalPointItems]);

  const lockers = useMemo(() => {
    if (!rentalPointItems) return [];

    return pointLockers.map(
      (row: any) =>
        ({
          _id: { value: row.id },
          locker_number: {
            value: `${row.locker_number}`,
          },
          size: { value: row.size },
          status: {
            value: row.status,
            display: row.current_item_id ? (
              <BoxBadge type="MAINTANENCE" />
            ) : (
              <BoxBadge type="AVAILABLE" />
            ),
          },
        }) as TableDataRow,
    );
  }, [pointLockers]);

  useEffect(() => {
    if (pointType === 'physical') {
      getRentalPointItems(pointId).then((response) =>
        setRentalPointItems(response),
      );
    }

    if (pointType === 'automatic') {
      getMachineBoxesById(pointId).then((response) =>
        setPointLockers(response),
      );
    }

    if (data && data.workflow_type) {
      try {
        const exceptions = JSON.parse(data.exception_dates);
        if (exceptions && typeof exceptions === 'object') {
          setExceptionDates(exceptions);
        }

        const response = JSON.parse(data.workflow_type);
        if (
          response &&
          typeof response === 'object' &&
          response.data &&
          response.type
        ) {
          setWorkDates(response.data);
          setWorkType(response.type);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [data, pointType, pointId]);

  return (
    <>
      <PageHeader
        title="Nomas punkta profils"
        actions={
          <div>
            <Button
              variant="transparent"
              color="grey"
              onClick={() => router.back()}
            >
              Atpakaļ
            </Button>
            <Button
              className={classes.editButton}
              onClick={() => modalEditActions.open()}
            >
              Redigēt
            </Button>
          </div>
        }
      />
      <Flex className={classes.main}>
        <div className={classes.info}>
          <Info title="Nomas punkta nosaukums" value={data?.name} />
          <Info title="Nomas punkta adrese" value={data?.address} />
          <Info
            title="Tips"
            value={
              data?.machine_type === 'automatic' ? 'Automāts' : 'Fiziskais'
            }
          />
          <Info title="Statuss" value={<LockerBadge type={data?.status} />} />
        </div>
        <div className={classes.menu}>
          <Menu position="left" width={200} shadow="md">
            <Menu.Target>
              <UnstyledButton>
                <DotsThreeVertical size={20} />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => modalMaintenanceActions.open()}>
                {data?.status === 'MAINTANENCE' ||
                data?.status === 'PARTMAINTANENCE' ||
                data?.status === 'MAINTENANCE'
                  ? 'Beigt apkopi'
                  : 'Sākt apkopi'}
              </Menu.Item>
              {pointType === 'physical' ? (
                <Menu.Item onClick={() => modalItemsActions.open()}>
                  Pievienot preces
                </Menu.Item>
              ) : null}
              <Menu.Item onClick={() => modalRemovalActions.open()}>
                Dzēst
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </Flex>
      <Maintenance
        machine={data}
        open={openMaintenance}
        onClose={() => {
          modalMaintenanceActions.close();
        }}
        onSuccess={handleRefresh}
      />
      <RemoveMachine
        machine={data}
        open={openRemoval}
        onClose={() => modalRemovalActions.close()}
        onSuccess={handleDelete}
      />
      <EditRentalPoint
        item={data}
        open={openEdit}
        onClose={() => modalEditActions.close()}
        onDone={handleRefresh}
      />
      <RentalItems
        machine={data}
        open={openItems}
        onClose={() => {
          modalItemsActions.close();
        }}
        onSuccess={handleRefresh}
      />
      <div className={classes.content}>
        <aside>
          <SectionTitle>Darba laiks</SectionTitle>
          <Flex mt={rem(40)}>
            <Info
              title="Darba laiks"
              value={workType === 'everytime' ? '24/7' : 'Limitēts'}
            />
          </Flex>
          <Flex w={rem(200)} direction="column" mt={rem(15)} mb={rem(20)}>
            {workDates.map((item, index) => (
              <Flex key={index}>
                {item.closed ? (
                  <Text className={classes.time}>{item.name}: Slēgts</Text>
                ) : (
                  <Text className={classes.time}>
                    {item.name}: {item.from}-{item.to}
                  </Text>
                )}
              </Flex>
            ))}
          </Flex>
          <Info title=" " value="Izņēmuma darba laiki" />
          <Flex w={rem(200)} direction="column" mt={rem(15)}>
            {exceptionDates.map((item: any, index: number) => (
              <Flex key={index}>
                {item.closed === true ? (
                  <Text className={classes.time}>
                    {formatDate(item.date)}: Slēgts
                  </Text>
                ) : (
                  <Text className={classes.time}>
                    {formatDate(item.date)}: {item.timeFrom}-{item.timeTo}
                  </Text>
                )}
              </Flex>
            ))}
          </Flex>
        </aside>
        {pointType === 'automatic' ? (
          <Flex direction="column" gap={rem(50)}>
            <section>
              <SectionTitle>Apkope</SectionTitle>
              <Table
                headers={MAINTENANCE_HEADERS}
                data={cleanupData}
                loading={cleanupLoading}
              />
            </section>
            <section>
              <SectionTitle>Vēsture</SectionTitle>
              <Table headers={HISTORY_HEADERS} data={cleanupDataHistory} />
            </section>
            <section>
              <SectionTitle>Skapīši</SectionTitle>
              <Table
                headers={LOCKER_HEADERS}
                data={lockers}
                searchable
                filters={['size']}
              />
            </section>
          </Flex>
        ) : (
          <Flex direction="column" gap={rem(50)}>
            <section>
              <SectionTitle>Pieejamās preces</SectionTitle>
              <Table
                headers={HEADERS}
                data={items}
                searchable
                filters={['status', 'role']}
              />
            </section>
          </Flex>
        )}
      </div>
    </>
  );
};
