import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const updateItem = async (item: Item) => {
  try {
    const resp = await httpClient().put<Item>(
      `/inventory_mgmt/tools/update/${item?.id}/`,
      {
        ...item,
        images_url: JSON.stringify(item.images_url),
        additional_attributes: JSON.stringify(item.additional_attributes),
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Restored user');
    }
    console.error(resp);
  } catch (error) {
    errorNotification(
      `${item.name} - izmaiņas saglabātas`,
      'Prece tika pievienota jūsu uzņēmuma sarakstā',
    );
    console.error(error);
  }
};
