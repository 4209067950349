import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const deleteItem = async (id: string) => {
  try {
    const resp = await httpClient().delete<Client>(
      `/inventory_mgmt/tools/delete/${id}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Item deleted');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to delete item');
  }
};
