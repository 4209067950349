import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';
import { Category } from '@/interfaces';

export const getCategories = async (): Promise<Category[]> => {
  try {
    const resp = await httpClient().get<Category[]>(
      '/inventory_mgmt/categories/allnodes/list',
    );
    return resp.data
  } catch (error) {
    errorNotification('Failed to get categories');
  }

  return [];
};
