import { MachineBox } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getMachineBoxes = async () => {
  try {
    const resp = await httpClient().get<MachineBox[]>(
      '/machines/machinebox/getall',
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get machines');
  }

  return [];
};
