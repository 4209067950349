import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const removeCategoryAttribute = async (attribute_id: number) => {
  try {
    const resp = await httpClient().delete(`/inventory_mgmt/category/attribute/delete/${attribute_id}`);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Attributs dzēsts');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Attributa dzēšana neveiksmīga');
    console.error(error);
  }
};
