'use client';

import { useMemo } from 'react';
import useSWR from 'swr';
import { useDisclosure } from '@mantine/hooks';
import { useParams, useRouter } from 'next/navigation';
import {
  Button,
  PageHeader,
  Table,
  TableDataRow,
  TableHeader,
} from '@/components';
import { Badge } from '@/components/Badge';
import { useTranslation } from '@/hooks';
import { RowAction } from '@/components/Table/interfaces';
import { getAdmins } from '@/lib/getAdmins';
import { blockAdmin } from '@/lib/blockAdmin';
import { unblockAdmin } from '@/lib/unblockAdmin';
import { deleteAdmin } from '@/lib/deleteAdmin';
import { restoreAdmin } from '@/lib/restoreAdmin';
import { AddAdmin } from './AddAdmin';

const HEADERS: TableHeader[] = [
  {
    value: 'name',
    label: 'Vārds, Uzvārds',
  },
  {
    value: 'phone',
    label: 'Tālr nr.',
  },
  {
    value: 'email',
    label: 'E-pasts',
  },
  { value: 'role', label: 'Loma' },
  { value: 'status', label: 'Status' },
];

export const Admins = () => {
  const { t } = useTranslation();
  const { data, isLoading, mutate } = useSWR('/admins/all', () => getAdmins());
  const router = useRouter();
  const params = useParams();
  const [open, modalActions] = useDisclosure(false);

  const admins = useMemo(() => {
    if (!data) return [];

    return data.map(
      (row) =>
        ({
          _id: { value: row.guid },
          name: {
            value: `${row.name} ${row.surname}`,
            link: `/admins/${row.guid}`,
          },
          phone: { value: row.phone },
          email: { value: row.email },
          role: {
            value: row.role,
          },
          status: {
            value: row.status,
            display: <Badge type={row?.status || 'DEFAULT'} />,
          },
        }) as TableDataRow,
    );
  }, [data]);

  const handleEdit = (id: string) => {
    router.push(`/${params.lang}/admins/${id}`);
  };

  const handleBlock = async (id: string) => {
    const admin = data?.find((u) => u.guid === id);

    if (admin) {
      await blockAdmin(admin);
      await mutate();
    }
  };

  const handleUnblock = async (id: string) => {
    const admin = data?.find((u) => u.guid === id);

    if (admin) {
      await unblockAdmin(admin);
      await mutate();
    }
  };

  const handleDelete = async (id: string) => {
    const admin = data?.find((u) => u.guid === id);

    if (admin) {
      await deleteAdmin(admin);
      await mutate();
    }
  };

  const handleRestore = async (id: string) => {
    const admin = data?.find((u) => u.guid === id);

    if (admin) {
      await restoreAdmin(admin);
      await mutate();
    }
  };

  const handleOnSave = async () => {
    await mutate();
    modalActions.close();
  };

  const rowActions: RowAction[] = useMemo(
    () => [
      {
        label: 'Rediģēt',
        callback: handleEdit,
      },
      {
        label: 'Bloķēt',
        when: { status: ['active', null] },
        callback: handleBlock,
      },
      {
        label: 'Atbloķēt',
        when: { status: ['blocked'] },
        callback: handleUnblock,
      },
      {
        label: 'Dzēst',
        callback: handleDelete,
      },
      {
        label: 'Atjaunot',
        when: { status: ['deleted'] },
        callback: handleRestore,
      },
    ],
    [],
  );

  return (
    <>
      <PageHeader
        title={t('administrators')}
        actions={
          <Button type="submit" onClick={modalActions.open}>
            {t('admins.addAdmin')}
          </Button>
        }
      />
      <Table
        headers={HEADERS}
        data={admins}
        searchable
        filters={['status', 'role']}
        loading={isLoading}
        rowActions={rowActions}
      />
      <AddAdmin
        open={open}
        onClose={modalActions.close}
        onSave={handleOnSave}
      />
    </>
  );
};
