/* eslint-disable react/no-unused-prop-types */
import { Box, Flex } from '@mantine/core';
import { ReactNode } from 'react';
import Image from 'next/image';
import Pin from '@/public/images/pin.svg';
import classes from './Marker.module.css';

interface Props {
  value: ReactNode;
  textHidden: boolean;
  lat: number;
  lng: number;
  onSelect: () => void;
}

export const Marker = ({ value, textHidden, onSelect }: Props) => (
  <Box pos="relative">
    <Flex className={classes.marker}>
      <Image
        src={Pin}
        height={45}
        width={25}
        alt="Map pin"
        onClick={onSelect}
      />
      <Box className={classes.text} display={textHidden ? 'none' : 'block'}>
        {value}
      </Box>
    </Flex>
  </Box>
);
