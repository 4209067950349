import { Flex, Modal, Text, rem } from '@mantine/core';
import { useState } from 'react';
import { Button } from '@/components';
import { acceptSubCompany } from '@/lib/acceptSubCompany';
import classes from './Accept.module.css';

interface Props {
  id?: string;
  open: boolean;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const Accept = ({ id, open, onClose, onDone }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleAccept = async () => {
    setLoading(true);
    await acceptSubCompany(+id!);
    await onDone();
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      classNames={{ header: classes.header }}
      opened={open}
      onClose={onClose}
      centered
    >
      <Flex direction="column" gap={rem(15)} mt={rem(32)}>
        <Text fz={rem(26)} fw={600} ta="center">
          Pieņemt uzņēmumu
        </Text>
        <Text ta="center">Uzņēmums kļūs aktīvs</Text>

        <Flex direction="column">
          <Button loading={loading} onClick={handleAccept}>
            Pieņemt
          </Button>
          <Button variant="transparent" onClick={onClose}>
            Atcelt
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
