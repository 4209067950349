import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';
import { SubAdminRegisterResponse } from '@/interfaces';

interface Body {
  name: string;
  surname: string;
  phone: string;
  email: string;
  password: string;
  countryCode: string;
}

export const addSubAdmin = async (body: Body) => {
  try {
    const resp = await httpClient().post<SubAdminRegisterResponse>(
      '/subadmin/create',
      {
        email: body.email,
        first_name: body.name,
        last_name: body.surname,
        password: body.password,
        phone_number: `${body.countryCode}${body.phone}`,
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Account created successfully');
      return resp.data;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create account');
  }

  return undefined;
};
