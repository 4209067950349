import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const updateItemAttribute = async (item: any) => {
  try {
    const resp = await httpClient().patch<any>(
      `/inventory_mgmt/category/item/edit/${item?.attribute_id}`,
      {
        value: item.value
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Edited attribute');
    }
    console.error(resp);
  } catch (error) {
    errorNotification(
      'Failed to update attribute'
    );
    console.error(error);
  }
};
