import { Flex, Select, ActionIcon, rem } from '@mantine/core';
import classes from './Attribute.module.css';
import { Button, TextInput } from '@/components';
import { Trash } from '@phosphor-icons/react/dist/ssr';


interface Props {
  name: string;
  type: string;
  measure: string;
  onNameChange: (name: string) => void;
  onTypeChange: (attribute_type: string) => void;
  onMeasureChange: (measure: string) => void;
  removeAttributeByIndex: () => void;
}

const TYPES = [
  'Skaitlis',
  'Teksts',
  'Fails',
];

export const Attribute = ({
  name,
  type,
  measure,
  onNameChange,
  onTypeChange,
  onMeasureChange,
  removeAttributeByIndex
}: Props) => (
  <Flex direction={'row'}>
    <Flex w="90%">
      <TextInput
        label="Nosaukums"
        onChange={(event) => onNameChange(event.target.value)}
        value={name}
        w="30%"
        mr={rem(20)}
      />
      <Select
        classNames={{
          input: classes.input,
          label: classes.label,
        }}
        onChange={(attribute_type) => onTypeChange(attribute_type || '')}
        value={type}
        label="Tips"
        data={TYPES}
        w="30%"
        mr={rem(20)}
      />
      {type != 'Fails' ? (
        <TextInput
          label="Mērvienība"
          onChange={(event) => onMeasureChange(event.target.value)}
          value={measure}
          w="30%"
          mr={rem(20)}
        />
      ) : null}
    </Flex>
    <Flex w="10%" justify='center' align='center' mt={rem(15)}>
      <ActionIcon
        mt={rem(20)}
        ml={rem(10)}
        variant="transparent"
        onClick={() => removeAttributeByIndex()}
      >
        <Trash size={22} color="grey" />
      </ActionIcon>
    </Flex>
  </Flex>
);
