import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const setRentalPointStatus = async (
  rental_point_id: number,
  status: string,
) => {
  try {
    const resp = await httpClient().put(
      `/rentalpoints/update/${rental_point_id}`,
      {
        status: status,
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('RentalPoint Updated');
      return resp.data;
    }
    console.error(resp.data.message);
  } catch (error) {
    errorNotification('Failed to update RentalPoint');
    console.error(error);
  }

  return undefined;
};
