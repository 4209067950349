import { useMemo, useState } from 'react';
import { Box, Flex, Modal, Select, ScrollArea, rem } from '@mantine/core';
import useSWR from 'swr';
import { useForm } from '@mantine/form';
import classes from './AddSubCategory.module.css';
import { Button, TextInput } from '@/components';
import { Attribute } from '../EditSubCategory/Attribute';
import { getItemCategories } from '@/lib/getItemCategories';
import { SectionHeader } from '@/components/SectionHeader';
import { addSubCategory } from '@/lib/addSubCategory';
import { addAttribute } from '@/lib/addAttribute';
import { useTranslation } from '@/hooks';

interface FormValues {
  category_name?: string;
  name?: string;
}

interface Props {
  category: any;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const AddSubCategory = ({
  open,
  onClose,
  category,
  onSuccess,
}: Props) => {
  const [selectedCat, setSelectedCat] = useState<string>();
  const [addAttributes, setAttributes] = useState<any[]>([]);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>({
    initialValues: {
      category_name: '',
      name: '',
    },
  });

  const addNewAttribute = () => {
    const newAttribute: any = {
      name: '',
      attribute_type: '',
      measure_unit: '',
    };
    setAttributes((prevAttributes) => [...prevAttributes, newAttribute]);
  };

  const handleNameChange = (name: string, index: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        name: name,
      };
      return updatedAttributes;
    });
  };

  const handleTypeChange = (attribute_type: string, index: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        attribute_type: attribute_type,
      };
      return updatedAttributes;
    });
  };

  const handleMeasureChange = (measure: string, index: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        measure_unit: measure,
      };
      return updatedAttributes;
    });
  };

  const removeAttributeByIndex = (indexToRemove: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes.splice(indexToRemove, 1);
      return updatedAttributes;
    });
  };

  const submitForm = async (values: FormValues) => {
    setLoading(true);
    const payload: { [key: string]: string } = {
      parent_category_id: category.id,
      category_id: category.category_id === 0 ? category.id : category.category_id,
      name: values.name || '',
    };
    try {
      const newCat = await addSubCategory(payload);
      for (const attribute of addAttributes) {
        const attributeData = {
          category_id: newCat.id,
          name: attribute.name,
          attribute_type: attribute.attribute_type,
          measure_unit: attribute.measure_unit
        }
        await addAttribute(attributeData)
      }
      onSuccess();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const categoryOptions = useMemo(
    () => (category ? [category.name]: []),
    [category],
  );

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
        body: classes.body,
      }}
      opened={open}
      onClose={onClose}
      title={t('newsubcategory')}
      centered
      size="70dvw"
    >
      <form onSubmit={form.onSubmit(submitForm)} className={classes.content}>
        <Flex
          direction="column"
          justify="space-between"
          style={{ backgroundColor: '', height: '60vh' }}
        >
          <Box>
            <Flex direction="row" gap={rem(30)}>
              <TextInput
                w="60%"
                label={t('subcatname')}
                {...form.getInputProps('name')}
              />
              <Select
                label={t('categoryname')}
                w={rem(350)}
                placeholder="Izvēlēties"
                data={categoryOptions}
                value={selectedCat}
                onChange={(value) => setSelectedCat(value || '')}
              />
            </Flex>
            <Flex direction="column" justify="start" mt={rem(20)}>
              <SectionHeader title={t('categoryattributes')} />
              <Button
                w={rem(200)}
                justify="start"
                variant="transparent"
                mt={rem(-30)}
                mb={rem(10)}
                onClick={() => addNewAttribute()}
              >
                {t('addcategoryattribute')}
              </Button>
              <ScrollArea h={300}>
                {addAttributes.map((item: any, index: number) => (
                  <Attribute 
                    key={index} 
                    name={item?.name} 
                    type={item?.attribute_type} 
                    measure={item?.measure_unit}
                    onNameChange={(name) => handleNameChange(name, index)}
                    onTypeChange={(attribute_type) => handleTypeChange(attribute_type, index)}
                    onMeasureChange={(measure) => handleMeasureChange(measure, index)}
                    removeAttributeByIndex={() => removeAttributeByIndex(index)}
                  />
                ))}
              </ScrollArea>
            </Flex>
          </Box>
          <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
            <Button
              variant="transparent"
              color="gray"
              onClick={onClose}
              disabled={loading}
            >
               {t('cancel')}
            </Button>
            <Button type="submit" loading={loading}>
              {t('save')}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
