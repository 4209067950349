import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface Updater {
  machine_id: any;
  description: any;
  startTime?: any;
  endTime?: any;
  boxes: any;
}

export const setMachineMaintenance = async (machine_id: any, status: string, item: Updater) => {
  try {
    const resp = await httpClient().put(`/machines/cleanup/machine/status/${machine_id}?status=${status}`, item);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Maintenance action success');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Maintenance action fail');
  }
};
