/* eslint-disable no-restricted-syntax */
import { Item } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getSubAdmin = async (aws_id: any) => {
  try {
    const resp = await httpClient().get<any>(`/subadmin/get/${aws_id}`);

    if (OK_STATUSES.includes(resp.status)) {
      const subadmin = resp.data;

      return subadmin;
    }
  } catch (error) {
    errorNotification('Failed to get subadmin');
  }

  return undefined;
};
