import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Flex, Modal, Text, rem } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Button } from '@/components';
import classes from './EndSubscription.module.css';
import { MachineBox } from '@/interfaces';
import { endSubscription } from '@/lib/endSubscription';

dayjs.extend(LocalizedFormat);

interface Props {
  open: boolean;
  subscription?: MachineBox;
  onClose: () => void;
  onDone: () => Promise<void>;
}

export const EndSubscription = ({
  open,
  subscription,
  onClose,
  onDone,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const handleEndSubscription = async () => {
    if (subscription) {
      setLoading(true);
      await endSubscription(subscription.id.toString());
      await onDone();
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      classNames={{ header: classes.header }}
      opened={open}
      onClose={onClose}
      centered
    >
      <Flex direction="column" gap={rem(15)} mt={rem(32)}>
        <Text fz={rem(26)} fw={600} ta="center">
          Skapīša abonēšanas pārtraukšana
        </Text>
        <Text ta="center">
          {`Skapīša abonēšanas termiņš beigsies ${
            subscription?.sub_end
              ? dayjs(subscription.sub_end, 'YYYY-MM-DD').format('ll')
              : ''
          }`}
        </Text>
        <Flex direction="column">
          <Button disabled={subscription?.current_item_id ? true : false} loading={loading} onClick={handleEndSubscription}>
            Pārtraukt
          </Button>
          <Button variant="transparent" onClick={onClose}>
            Atcelt
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
