import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface Attribute {
  category_id: number;
  name: string;
  attribute_type: string;
  measure_unit: string;
}

export const addAttribute = async (item: Attribute) => {
  try {
    const resp = await httpClient().post('/inventory_mgmt/category/attribute/create', {
      category_id: item.category_id,
      name: item.name,
      attribute_type: item.attribute_type,
      measure_unit: item.measure_unit
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Attribute created');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create attribute');
  }
};
