import React, { useState } from 'react';
import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import { usePlacesWidget } from 'react-google-autocomplete';
import classes from './TextInput.module.css';

interface AutocompleteProps extends TextInputProps {
  onPlaceSelected: (place: any) => void;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const [selectedPlace, setSelectedPlace] = useState<string>();

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: 'AIzaSyDaFxy3SXbjtAA5M42Im2Mz6TYc6uVqakU',
    options: {
      types: ['geocode'],
    },
    onPlaceSelected: (place) => {
      const formattedAddress = place?.formatted_address;
      const coordinates = place?.geometry?.location;
      setSelectedPlace(formattedAddress);
      props.onPlaceSelected({
        address: formattedAddress,
        lattitude: coordinates.lat(),
        longitude: coordinates.lng(),
        object: place,
      });
    },
  });

  return (
    <MantineTextInput
      ref={ref}
      {...props}
      value={selectedPlace}
      onChange={(event) => {
        props.onChange && props.onChange(event);
      }}
      classNames={{
        input: classes.input,
        label: classes.label,
      }}
    />
  );
};
