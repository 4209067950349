import { TableHeader } from '@/components';

export const MAINTENANCE_HEADERS: TableHeader[] = [
  { value: 'start', label: 'Sākums' },
  { value: 'description', label: 'Apraksts' },
  { value: 'lockers', label: 'Skapīši' },
];

export const HISTORY_HEADERS: TableHeader[] = [
  { value: 'start', label: 'Sākums' },
  { value: 'end', label: 'Beigas' },
  { value: 'description', label: 'Apraksts' },
  { value: 'lockers', label: 'Skapīši' },
];
