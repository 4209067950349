import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const removeMachineBox = async (box_id: any) => {
  try {
    const resp = await httpClient().delete(`/machines/machinebox/delete/${box_id}`);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Skapitis dzēsts');
    }
    console.error(resp);
  } catch (error) {
    // errorNotification('Skapisa dzesana neveiksmīga');
    console.error(error);
  }
};
