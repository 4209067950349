import { errorNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const getPaymentMethods = async (awsId: any) => {
  try {
    const resp = await httpClient().get(
      `/payments_mgmt/subadmin/payment/methods/${awsId}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
    console.error(resp.data.message);
  } catch (error) {
    errorNotification('Failed to get payment methods');
    console.error(error);
  }

  return [];
};
