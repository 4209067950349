import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type KeyValue = { [key: string]: string | number };

interface RegistrationState {
  user: KeyValue;
  setUser: (user: KeyValue) => void;
  clear: () => void;
}

export const useRegistration = create<RegistrationState>()(
  devtools(
    (set) => ({
      user: {},
      setUser: (user) =>
        set(
          (state) => ({ user: { ...state.user, ...user } }),
          false,
          'set-user',
        ),
      clear: () => {
        set(() => ({ user: {} }), false, 'clear registration data');
      },
    }),
    { name: 'registration-storage' },
  ),
);
