// TODO: move to BE, should not be hardcoded

export const MONTHLY_PRICES: { [key: string]: number } = {
  'S (64 x 38 x 9 cm)': 0.01,
  'M (64 x 38 x 19 cm)': 0.01,
  'L (64 x 38 x 39 cm)': 0.01,
  'XL (100 x 200 x 150 cm)': 0.01,
  '-': 0,
};

export const YEARLY_PRICES: { [key: string]: number } = {
  'S (64 x 38 x 9 cm)': 0.01,
  'M (64 x 38 x 19 cm)': 0.01,
  'L (64 x 38 x 39 cm)': 0.01,
  'XL (100 x 200 x 150 cm)': 0.01,
  '-': 0,
};

export const OFFLINE_STATUSES = ['MAINTENANCE', 'PARTMAINTENANCE', 'DISABLED'];
