'use client';

import { useState } from 'react';
import Image from 'next/image';
import {
  Box,
  Flex,
  Text,
  Menu,
  rem,
  Button as MantineButton,
} from '@mantine/core';
import ReactCountryFlag from 'react-country-flag';
import { useForm } from '@mantine/form';
import { useParams, useRouter } from 'next/navigation';
import { notifications } from '@mantine/notifications';
import { useTranslation } from '@/hooks';
import classes from './Login.module.css';
import logoSmall from '@/public/images/logo_shorthand_green.svg';
import { Button, TextInput, PasswordField } from '@/components';
import { login } from '@/lib/login';
import { VIEWS } from '../interfaces';
import { getUser } from '@/lib/getUser';

const OK_STATUSES = [200, 201];
const LANGUAGES = {
  lv: 'LV',
  en: 'GB',
};

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const Login = ({ onViewChange }: Props) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [language, setLanguage] = useState<string>(
    (params.lang as string) || 'lv',
  );

  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : t('login.emailInvalid'),
      password: (value: string) => (value ? null : t('login.passwordInvalid')),
    },
  });

  const handleLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    const resp = await login({ ...values });
    await getUser(resp.data.access_token);

    if (!OK_STATUSES.includes(resp.status)) {
      notifications.show({
        title: 'Login failed',
        color: 'red',
        message: resp.data,
        autoClose: 5_000,
        withCloseButton: true,
      });
    } else {
      router.push(`/${params.lang}/home`);
      // router.refresh();
    }

    setLoading(false);
  };

  const handleLanguageChange = (selectedLanguage: string) => {
    setLanguage(selectedLanguage);

    if (selectedLanguage !== language) router.push(`/${selectedLanguage}`);
  };

  return (
    <Box className={classes.rightSide}>
      <Flex direction="column" justify="center" align="center">
        <div style={{ position: 'absolute', top: 30, right: 30 }}>
          <Menu
            width={120}
            shadow="md"
            trigger="click-hover"
            position="bottom-end"
          >
            <Menu.Target>
              <MantineButton variant="transparent">
                {/* @ts-ignore */}
                <ReactCountryFlag countryCode={LANGUAGES[language]} />
              </MantineButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => handleLanguageChange('lv')}>
                <Flex justify="center" align="center">
                  <ReactCountryFlag countryCode="LV" />
                  <Text ml={rem(5)}>Latviešu</Text>
                </Flex>
              </Menu.Item>
              <Menu.Item onClick={() => handleLanguageChange('en')}>
                <Flex justify="center" align="center">
                  <ReactCountryFlag countryCode="GB" />
                  <Text ml={rem(5)}>English</Text>
                </Flex>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
        <form onSubmit={form.onSubmit(handleLogin)}>
          <Image
            className={classes.logoSmall}
            src={logoSmall}
            height={60}
            width={60}
            alt="Neiboo"
          />
          <Flex
            direction="column"
            align="center"
            justify="center"
            w={300}
            gap={rem(20)}
          >
            <TextInput
              label={t('login.email')}
              name="email"
              {...form.getInputProps('email')}
              w="100%"
            />
            <PasswordField
              label={t('login.password')}
              name="password"
              {...form.getInputProps('password')}
              w="100%"
            />
            <Button miw={rem(170)} type="submit" loading={loading}>
              {t('login.login')}
            </Button>

            <Button
              variant="transparent"
              onClick={() => onViewChange(VIEWS.RESTORE_PASS)}
            >
              {t('forgotPass')}
            </Button>
          </Flex>
        </form>
      </Flex>
      <Flex justify="center" align="flex-end">
        <Text fz={rem(13)} lh={rem(38)}>
          {t('stillNotRegistered')}
        </Text>
        <Button
          fz={rem(14)}
          variant="transparent"
          onClick={() => onViewChange(VIEWS.REGISTER)}
        >
          {t('registerNow')}
        </Button>
      </Flex>
    </Box>
  );
};
