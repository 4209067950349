import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const endSubscription = async (id: string) => {
  try {
    const resp = await httpClient().put<Client>(
      `/machines/machinebox/unsubscribe/${id}/`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Unsubscribed from subscription');
      return resp.data;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to unsubscribe');
    console.error(error);
  }

  return undefined;
};
