import { useMemo, useState, useReducer } from 'react';
import {
  Box,
  Flex,
  InputLabel,
  Modal,
  NumberInput,
  Select,
  Text,
  rem,
  Radio,
} from '@mantine/core';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useForm } from '@mantine/form';
import { DateValue, YearPickerInput } from '@mantine/dates';
import classes from './LockerBox.module.css';
import {
  Button,
  MultiLevelSelect,
  MultiLevelSelectGroup,
  TagInput,
  TextInput,
} from '@/components';
import { Item } from '@/interfaces';
import { getItemCategories } from '@/lib/getItemCategories';
import { LOCKER_SIZES } from '@/constants/sizes';
import { addItemImages } from '@/lib/addItemImage';
import { addItem } from '@/lib/addItem';
import { useUser } from '@/hooks/useUser';

interface Props {
  locker_size: string;
  locker_number: string;
  onSizeChange: (size: string) => void;
  onIdChange: (id: string) => void;
}

const SIZES = [
  'S (64 x 38 x 9 cm)',
  'M (64 x 38 x 19 cm)',
  'L (64 x 38 x 39 cm)',
  'XL (100 x 200 x 150 cm)',
];

export const LockerBox = ({
  locker_size,
  locker_number,
  onSizeChange,
  onIdChange,
}: Props) => {
  return (
    <Flex w="100%" justify="space-between">
      <Select
        classNames={{
          input: classes.input,
          label: classes.label,
        }}
        onChange={(selectedSize) => onSizeChange(selectedSize || '')}
        value={locker_size}
        label="Skapīša izmērs"
        data={SIZES}
        w={'48%'}
      />
      <TextInput
        label="Identifikators"
        onChange={(event) => onIdChange(event.target.value)}
        value={locker_number}
        w={'48%'}
      />
    </Flex>
  );
};
