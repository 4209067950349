import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const addCategory = async (name: string) => {
  try {
    const resp = await httpClient().post('/inventory_mgmt/category/create', {
      name,
      parent_category_id: 0,
      category_id: 0
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Category created');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create category');
  }
};
