import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';
import { SubAdminRegisterResponse } from '@/interfaces';

interface Body {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
}

export const registerConnectedAccSubAdmin = async (body: Body) => {
  try {
    // const resp = await httpClient().post<SubAdminRegisterResponse>(
    const resp = await httpClient().post(
      '/payments_mgmt/register/connectedacc/subadmin',
      body,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to register connected account for subadmin');
  }

  return undefined;
};
