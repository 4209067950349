import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const renewSubscription = async (id: string, subType: string) => {
  try {
    const resp = await httpClient().put<Client>(
      `/machines/machinebox/renew/${id}/`,
      {
        sub_type: subType,
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Subscription renewed');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to renew subscription');
    console.error(error);
  }
};
