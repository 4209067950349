import { useState } from 'react';
import { Box, Flex, Group, PinInput, rem, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button } from '@/components';
import { useTranslation } from '@/hooks';
import { verifyRegistration } from '@/lib/registerVerify';
import { useRegistration } from '@/hooks/useRegistration';
import { VIEWS } from '../interfaces';

interface FormValues {
  code: string;
}

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const Verify = ({ onViewChange }: Props) => {
  const { t } = useTranslation();
  const registrationData = useRegistration((state) => state.user);
  const form = useForm<FormValues>({
    validate: {
      code: (value) => (/^\d{6}$/.exec(value) ? undefined : 'Invalid code'),
    },
  });
  const [loading, setLoading] = useState(false);

  const handleVerify = async (values: FormValues) => {
    setLoading(true);
    const { email } = registrationData;

    const resp = await verifyRegistration({
      email: email as string,
      code: values.code,
    });

    if (resp === 200) {
      onViewChange(VIEWS.PROFILE);
    } else {
      form.setFieldError('code', 'Invalid / incorrect code');
    }
    setLoading(false);
  };

  return (
    <Box py={rem(60)} ml={rem(34)} h="100%">
      <form onSubmit={form.onSubmit(handleVerify)} style={{ height: '100%' }}>
        <Stack gap={rem(70)} justify="space-between" h="100%">
          <div>
            <Text fz={rem(35)} fw={600}>
              {t('signup_title')}
            </Text>
            <Text fz={rem(18)} fw={400}>
              {t('verification_text')}
            </Text>
            <PinInput
              autoFocus
              mt={rem(70)}
              size="md"
              length={6}
              placeholder=""
              type="number"
              {...form.getInputProps('code')}
            />
          </div>
          <Flex justify="flex-start" gap={rem(30)}>
            <Button type="submit" loading={loading}>
              {t('continue')}
            </Button>
            <Group align="center" gap="xs">
              <Text fz={14} fw={500} c="#8F9098">
                Nesaņēmāt e-pastu?
              </Text>
              <Button variant="transparent" p="0">
                Pārsūtīt kodu
              </Button>
            </Group>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};
