import {
  BadgeProps,
  Flex,
  Badge as MantineBadge,
  Text,
  rem,
} from '@mantine/core';
import classes from './LockerBadge.module.css';

interface Props extends BadgeProps {
  type?: 'MAINTENANCE' | 'AVAILABLE' | 'PARTMAINTENANCE' | 'DISABLED' | 'MAINTANENCE' | 'PARTMAINTANENCE';
}

const badgeColor: Record<string, string> = {
  AVAILABLE: '#E9F8BF',
  MAINTENANCE: '#FEE1C7',
  MAINTANENCE: '#FEE1C7',
  PARTMAINTENANCE: '#FEE1C7',
  PARTMAINTANENCE: '#FEE1C7',
  DISABLED: '#E8E9F1',
};

const badgeTexColor: Record<string, string> = {
  AVAILABLE: '#8CB80A',
  MAINTENANCE: '#EC7004',
  MAINTANENCE: '#EC7004',
  PARTMAINTENANCE: '#EC7004',
  PARTMAINTANENCE: '#EC7004',
  DISABLED: '#494A50',
};

const statusText: Record<string, string> = {
  AVAILABLE: 'Aktīvs',
  MAINTENANCE: 'Apkopē',
  MAINTANENCE: 'Apkopē',
  PARTMAINTENANCE: 'Apkopē',
  PARTMAINTANENCE: 'Apkopē',
  DISABLED: 'Apkopē',
};

const subtitle: Record<string, string> = {
  MAINTENANCE: 'Nav pieejams',
  MAINTANENCE: 'Nav pieejams',
  PARTMAINTENANCE: 'Pieejams',
  PARTMAINTANENCE: 'Pieejams',
  DISABLED: 'Nav pieejams',
};

export const LockerBadge = ({ type = 'MAINTENANCE' }: Props) => {
  const displayText = statusText[type] || statusText.MAINTENANCE;
  const subtitleText = subtitle[type] || '';
  return (
    <Flex maw={rem(200)} direction="column">
      <MantineBadge
        color={badgeColor[type]}
        w="auto"
        style={{ textTransform: 'none' }}
      >
        <span style={{ color: badgeTexColor[type] }}>{displayText}</span>
      </MantineBadge>
      <Text color={badgeTexColor[type]} className={classes.subtitle}>
        {subtitleText}
      </Text>
    </Flex>
  );
};
