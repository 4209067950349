import { MouseEvent } from 'react';
import { ActionIcon, Menu } from '@mantine/core';
import { DotsThreeVertical } from '@phosphor-icons/react/dist/ssr';
import { Category, SubCategory } from '@/interfaces';

interface Props {
  category: Category | SubCategory;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const CategoryActions = ({ category, onClick, onDelete, onEdit }: Props) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    console.log({ category });
  };

  return (
    <Menu trigger="hover" position="right">
      <Menu.Target>
        <ActionIcon variant="transparent" onClick={handleClick}>
          <DotsThreeVertical size={22} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {category.id != 1 ? (
          <Menu.Item onClick={onEdit}>Redigēt</Menu.Item>
        ) : null}
        <Menu.Item onClick={onClick}>Pievienot apakškategoriju</Menu.Item>
        {category.id != 1 ? (
          <Menu.Item onClick={onDelete}>Dzēst</Menu.Item>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  );
};
