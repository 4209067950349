import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface RentalPoint {
  lattitude?: number;
  longitude?: number;
  address?: string;
  name?: string;
  machine_type?: string;
  exception_dates?: string;
  workflow_type?: string;
  status?: string;
  phone?: string;
  company_id?: any;
}

export const updateRentalPoint = async (point_id: any, item: RentalPoint) => {
  try {
    const resp = await httpClient().put(`/rentalpoints/update/${point_id}`, item);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Rental point updated');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to update Rental Point');
  }
};
