export enum VIEWS {
  LOGIN = 'login',
  REGISTER = 'register',
  VERIFY = 'verify',
  VERIFF = 'veriff',
  CHANGE_PASS = 'changePass',
  // PASSWORD_RESET = 'passwordReset',
  RESTORE_PASS = 'passwordReset',
  PROFILE = 'profile',
  PAYMENT_DETAILS = 'paymentDetails'
}
