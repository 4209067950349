import { useMemo, useState, useEffect } from 'react';
import { Box, Flex, Modal, ScrollArea, Select, rem } from '@mantine/core';
import useSWR from 'swr';
import { useForm } from '@mantine/form';
import classes from './EditSubCategory.module.css';
import { Button, TextInput } from '@/components';
import { getItemCategories } from '@/lib/getItemCategories';
import { SectionHeader } from '@/components/SectionHeader';
import { editSubCategory } from '@/lib/editSubCategory';
import { getCategoryAttributes } from '@/lib/getCategoryAttributes';
import { Attribute } from './Attribute';
import { addAttribute } from '@/lib/addAttribute';
import { removeCategoryAttribute } from '@/lib/removeCategoryAttribute';
import { updateCategoryAttribute } from '@/lib/updateCategoryAttribute';

interface FormValues {
  name?: string;
}

interface Props {
  category: any;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditSubCategory = ({
  open,
  onClose,
  category,
  onSuccess,
}: Props) => {
  const [selectedCat, setSelectedCat] = useState<string>();
  const [addAttributes, setAttributes] = useState<any[]>([]);
  const [currentAttributes, setCurrentAttributes] = useState<any[]>([]);
  const [removeAttributes, setRemoveAttributes] = useState<any[]>([]);
  const [editAttributes, setEditAttributes] = useState<any[]>([]);

  const { data: attributes } = useSWR(`/category/attributes/getall/${category?.id}`, () =>
    getCategoryAttributes(category?.id),
  );

  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>({
    initialValues: {
      name: '',
    },
  });

  const closeForm = () => {
    setAttributes([]);
    setRemoveAttributes([]);
    onClose();
  }

  const submitForm = async (values: FormValues) => {
    setLoading(true);
    const payload: any = {
      category_id: category.id,
      data: {
        name: values.name
      }
    };
    try {
      await editSubCategory(payload);
      for (const attribute of addAttributes) {
        const attributeData = {
          category_id: category.id,
          name: attribute.name,
          attribute_type: attribute.attribute_type,
          measure_unit: attribute.measure_unit
        }
        await addAttribute(attributeData)
      }
      for (const attribute of removeAttributes) {
        await removeCategoryAttribute(attribute.id)
      }
      for (const attribute of currentAttributes) {
        const attributeData = {
          attribute_id: attribute.id,
          name: attribute.name,
          attribute_type: attribute.attribute_type,
          measure_unit: attribute.measure_unit
        }
        await updateCategoryAttribute(attributeData)
      }
      onSuccess();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addNewAttribute = () => {
    const newAttribute: any = {
      name: '',
      attribute_type: '',
      measure_unit: '',
    };
    setAttributes((prevAttributes) => [...prevAttributes, newAttribute]);
  };

  const handleNameChange = (name: string, index: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        name: name,
      };
      return updatedAttributes;
    });
  };

  const handleTypeChange = (attribute_type: string, index: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        attribute_type: attribute_type,
      };
      return updatedAttributes;
    });
  };

  const handleMeasureChange = (measure: string, index: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        measure_unit: measure,
      };
      return updatedAttributes;
    });
  };

  const removeAttributeByIndex = (indexToRemove: number) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes.splice(indexToRemove, 1);
      return updatedAttributes;
    });
  };

  const handleCurrentNameChange = (name: string, index: number) => {
    setCurrentAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        name: name,
      };
      setEditAttributes(updatedAttributes);
      return updatedAttributes;
    });
  };

  const handleCurrentTypeChange = (attribute_type: string, index: number) => {
    setCurrentAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        attribute_type: attribute_type,
      };
      setEditAttributes(updatedAttributes);
      return updatedAttributes;
    });
  };

  const handleCurrentMeasureChange = (measure: string, index: number) => {
    setCurrentAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        measure_unit: measure,
      };
      setEditAttributes(updatedAttributes);
      return updatedAttributes;
    });
  };

  const removeCurrentAttributeByIndex = (indexToRemove: number) => {
    setCurrentAttributes((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];
      const removedAttribute = updatedAttributes.splice(indexToRemove, 1)[0];
      setRemoveAttributes((prevRemoveAttributes) => [...prevRemoveAttributes, removedAttribute]);
      return updatedAttributes;
    });
  };

  useEffect(() => {
    form.setValues({
      name: category?.name,
    });
    setSelectedCat(category?.name)
    setCurrentAttributes(attributes)
  }, [category, attributes]);

  const categoryOptions = useMemo(
    () => (category ? [category.name]: []),
    [category],
  );

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
        body: classes.body,
      }}
      opened={open}
      onClose={closeForm}
      title="Kategorijas rediģēšana"
      centered
      size="70dvw"
    >
      <form onSubmit={form.onSubmit(submitForm)} className={classes.content}>
        <Flex
          direction="column"
          justify="space-between"
          style={{ backgroundColor: '', height: '60vh' }}
        >
          <Box>
            <Flex direction="row" gap={rem(30)}>
              <TextInput
                w="60%"
                label="Nosaukums"
                {...form.getInputProps('name')}
              />
              <Select
                label="Kategorija"
                w={rem(350)}
                disabled
                placeholder="Izvēlēties"
                data={categoryOptions}
                value={selectedCat}
                onChange={(value) => setSelectedCat(value || '')}
              />
            </Flex>
            <Flex direction="column" justify="start" mt={rem(20)}>
              <SectionHeader title="Papildus atribūti" />
              <Button
                w={rem(200)}
                justify="start"
                variant="transparent"
                mt={rem(-30)}
                mb={rem(10)}
                onClick={() => addNewAttribute()}
              >
                Pievienot atribūtu
              </Button>
              <ScrollArea h={300}>
                {currentAttributes && currentAttributes.map((item: any, index: number) => (
                  <Attribute 
                    key={index} 
                    name={item?.name} 
                    type={item?.attribute_type} 
                    measure={item?.measure_unit}
                    onNameChange={(name) => handleCurrentNameChange(name, index)}
                    onTypeChange={(attribute_type) => handleCurrentTypeChange(attribute_type, index)}
                    onMeasureChange={(measure) => handleCurrentMeasureChange(measure, index)}
                    removeAttributeByIndex={() => removeCurrentAttributeByIndex(index)}
                  />
                ))}
                {addAttributes.map((item: any, index: number) => (
                  <Attribute 
                    key={index} 
                    name={item?.name} 
                    type={item?.attribute_type} 
                    measure={item?.measure_unit}
                    onNameChange={(name) => handleNameChange(name, index)}
                    onTypeChange={(attribute_type) => handleTypeChange(attribute_type, index)}
                    onMeasureChange={(measure) => handleMeasureChange(measure, index)}
                    removeAttributeByIndex={() => removeAttributeByIndex(index)}
                  />
                ))}
              </ScrollArea>
            </Flex>
          </Box>
          <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
            <Button
              variant="transparent"
              color="gray"
              onClick={closeForm}
              disabled={loading}
            >
              Atcelt
            </Button>
            <Button type="submit" loading={loading}>
              Saglabāt
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
