'use client';

import { useMemo } from 'react';
import useSWR from 'swr';
import { useParams, useRouter } from 'next/navigation';
import { Text, Menu, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DotsThreeVertical } from '@phosphor-icons/react/dist/ssr';
import { Button, PageHeader, Table, TableHeader } from '@/components';
import { LockerBadge } from '@/components/Badge/LockerBadge';
import { AddRentalPoint } from './AddRentalPoint';
import { getMachines } from '@/lib/getMachines';
import { getRentalPoints } from '@/lib/getRentalPoints';
import classes from './RentalPoints.module.css';
import { useTranslation } from '@/hooks';

const HEADERS: TableHeader[] = [
  {
    value: 'name',
    label: 'Nomas punkta nosaukums',
  },
  {
    value: 'address',
    label: 'Nomas punkta adrese',
  },
  {
    value: 'type',
    label: 'Tips',
  },
  { value: 'status', label: 'Status' },
  { value: 'actions', label: 'Darbības' },
];

export const RentalPoints = () => {
  const router = useRouter();
  const params = useParams();
  const {
    data: Machines,
    isLoading: MachinesLoading,
    mutate: ReFetchMachines,
  } = useSWR('/machines/getall', () => getMachines());
  const { data: Points, mutate: ReFetchPoints } = useSWR(
    '/rentalpoints/getall',
    () => getRentalPoints(),
  );
  const [open, modalActions] = useDisclosure(false);
  const { t } = useTranslation();

  const handleEdit = (id: string, machine_type: string) => {
    router.push(`/${params.lang}/rental-points/${id}/${machine_type}`);
  };

  const machines = useMemo(() => {
    if (!Machines) return [];

    const combinedArray = [...Machines, ...(Points || [])];

    const mappedArray = combinedArray.map((row) => ({
      _id: { value: row.uuid.toString() },
      name: {
        value: row.name,
        display: (
          <Text
            className={classes.black12}
            onClick={() =>
              router.push(
                `/${params.lang}/rental-points/${row.id}/${row.machine_type}`,
              )
            }
            style={{ cursor: 'pointer' }}
          >
            {row.name}
          </Text>
        ),
      },
      address: {
        value: row.address,
        display: <Text className={classes.black12}> {row.address} </Text>,
      },
      type: {
        value: row.machine_type === 'automatic' ? 'Automātiskais' : 'Fiziskais',
        display: (
          <Text className={classes.type}>
            {row.machine_type === 'automatic' ? 'Automātiskais' : 'Fiziskais'}
          </Text>
        ),
      },
      status: {
        value: row.status,
        display: <LockerBadge type={row.status} />,
      },
      actions: {
        value: '',
        display: (
          <Menu position="bottom-end">
            <Menu.Target>
              <UnstyledButton className={classes.dropdown}>
                <DotsThreeVertical size={22} />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => handleEdit(row?.id, row?.machine_type)}>
                {t('edit')}
              </Menu.Item>
              <Menu.Item onClick={() => handleEdit(row?.id, row?.machine_type)}>
                {t('maintenance')}
              </Menu.Item>
              <Menu.Item onClick={() => handleEdit(row?.id, row?.machine_type)}>
                {t('delete')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
    }));

    return mappedArray;
  }, [Machines, Points]);

  const handleRefresh = async () => {
    await ReFetchMachines();
    await ReFetchPoints();
  };

  // const rowActions: RowAction[] = useMemo(
  //   () => [
  //     {
  //       label: 'Rediģēt',
  //       callback: handleEdit,
  //     },
  //     {
  //       label: 'Apkope',
  //       callback: handleMaintenance,
  //     },
  //     {
  //       label: 'Dzēst',
  //       callback: handleEdit,
  //     },
  //   ],
  //   [],
  // );

  return (
    <>
      <PageHeader
        title={t('rentalpoints')}
        actions={
          <Button type="submit" onClick={() => modalActions.open()}>
            {t('addrentalpoint')}
          </Button>
        }
      />
      <Table
        headers={HEADERS}
        data={machines}
        searchable
        filters={['status', 'type']}
        loading={MachinesLoading}
        // rowActions={rowActions}
      />
      <AddRentalPoint
        open={open}
        onDone={handleRefresh}
        onClose={() => modalActions.close()}
      />
    </>
  );
};
