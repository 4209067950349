'use client';

import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button, PageHeader, Table } from '@/components';
import { SubscriptionBadge } from '@/components/Badge/SubscriptionBadge';
import {
  RowAction,
  TableDataRow,
  TableHeader,
} from '@/components/Table/interfaces';
import { useUser } from '@/hooks/useUser';
import { getCompanyMachineBoxes } from '@/lib/getCompanyMachineBoxes';
import classes from './Subscriptions.module.css';
import { getMachines } from '@/lib/getMachines';
import { Machine, MachineBox } from '@/interfaces';
import { AddSubscriptions } from './AddSubscription';
import { EndSubscription } from './EndSubscription';
import { RenewSubscription } from './RenewSubscription';
import { getMachineBoxes } from '@/lib/getMachineBoxes';
import { useTranslation } from '@/hooks';

const headers: TableHeader[] = [
  {
    value: 'size',
    label: 'Izmērs',
  },
  {
    value: 'address',
    label: 'Adrese',
  },
  {
    value: 'lockerNumber',
    label: 'Skapīša Nr.',
  },
  {
    value: 'start',
    label: 'Sākums',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'item',
    label: 'Prece',
  },
];

export const Subscriptions = () => {
  const [addOpen, addActions] = useDisclosure(false);
  const [endSubOpen, endSubscriptionActions] = useDisclosure(false);
  const [renewSubOpen, renewSubActions] = useDisclosure(false);
  const [selectedSub, setSelectedSub] = useState<MachineBox>();
  const [loading, setLoading] = useState(false);
  const user = useUser((state) => state.user);
  const { t } = useTranslation();

  const { data: machines, isLoading: loadingMachines } = useSWR(
    '/machines/all',
    () => getMachines(),
  );
  const {
    data: subs,
    isLoading: loadingSubs,
    mutate,
  } = useSWR(`/machines/company/${user?.companyId}/machine-boxes`, () =>
    getCompanyMachineBoxes(user?.companyId),
  );

  const {
    data: boxes,
    isLoading: loadingBoxes,
    mutate: mutateBoxes,
  } = useSWR(`/machines/machinebox/getall`, () =>
    getMachineBoxes(),
  );

  const getMachineFromList = (machineId: number) =>
    machines
      ? machines.find((machine) => machine.id === machineId)
      : ({} as Machine);

  const handleListRefresh = async () => {
    setLoading(true);
    await mutate();
    await mutateBoxes();
    setLoading(false);
  };

  const subscriptions = useMemo(
    () =>
      subs
        ? subs?.map(
            (sub) =>
              ({
                _id: { value: sub.id.toString() },
                size: {
                  value: sub.size,
                  display: (
                    <Text className={classes.title} fw={600}>
                      {sub.size}
                    </Text>
                  ),
                },
                address: {
                  value: getMachineFromList(sub.machine_id)?.address,
                  display: (
                    <Text className={classes.grey12}>
                      {getMachineFromList(sub.machine_id)?.address}
                    </Text>
                  ),
                },
                lockerNumber: {
                  value: sub.locker_number.toString(),
                  display: (
                    <Text className={classes.regular14}>
                      {sub.locker_number.toString()}
                    </Text>
                  ),
                },
                start: {
                  value: sub.sub_start,
                  display: (
                    <Text className={classes.grey12}>{sub.sub_start}</Text>
                  ),
                },
                status: {
                  value: sub.status,
                  // @ts-ignore
                  display: <SubscriptionBadge type={sub.status} />,
                },
                item: {
                  value: sub.current_item_id?.toString() ?? '-',
                },
                sub_type: {
                  value: sub.sub_type,
                },
              }) as TableDataRow,
          )
        : [],
    [subs, machines],
  );

  const handleEndSubscription = async (id: string) => {
    const subscription = subs?.find((sub) => sub.id === parseInt(id, 10));
    setSelectedSub(subscription);
    endSubscriptionActions.open();
  };

  const handleRenewSubscription = async (id: string) => {
    const subscription = subs?.find((sub) => sub.id === parseInt(id, 10));
    setSelectedSub(subscription);
    renewSubActions.open();
  };

  const rowActions: RowAction[] = useMemo(
    () => [
      {
        label: 'Pārtraukt',
        when: { sub_type: ['!unsub,monthly'] },
        callback: handleEndSubscription,
      },
      {
        label: 'Atsākt',
        when: { sub_type: ['unsub,monthly'] },
        callback: handleRenewSubscription,
      },
    ],
    [subs],
  );

  return (
    <>
      <PageHeader
        title={t('subscriptions')} 
        actions={<Button onClick={addActions.open}>{t('add_subscription')} </Button>}
      />

      <Table
        headers={headers}
        data={subscriptions}
        loading={loadingMachines || loadingSubs || loading}
        searchable
        filters={['status', 'size', 'address']}
        rowActions={rowActions}
      />
      <AddSubscriptions
        open={addOpen}
        machines={machines}
        machineBoxes={boxes}
        onClose={addActions.close}
        onDone={handleListRefresh}
      />
      <EndSubscription
        open={endSubOpen}
        onClose={endSubscriptionActions.close}
        subscription={selectedSub}
        onDone={handleListRefresh}
      />
      <RenewSubscription
        open={renewSubOpen}
        subscription={selectedSub}
        onClose={renewSubActions.close}
        onDone={handleListRefresh}
      />
    </>
  );
};
