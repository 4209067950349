import { Admin } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const restoreAdmin = async (admin: Admin) => {
  try {
    const resp = await httpClient().post<Admin>(
      '/admin/adminrestoreusr',
      admin,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Restored user');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to restore user');
    console.error(error);
  }
};
