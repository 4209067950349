'use client';

import Image from 'next/image';
import { useDisclosure } from '@mantine/hooks';
import { Flex, Text, Card, Group, rem } from '@mantine/core';
import { Button, PageHeader } from '@/components';
import classes from './Profile.module.css';
import { useUser } from '@/hooks/useUser';
import defaultImage from '@/public/images/default_image.svg';
import { STATUS_COLORS } from '@/constants/status';
import { EditSelf } from './EditSelf/EditSelf';
import { getUser } from '@/lib/getUser';
import { getAuthToken } from '@/app/[lang]/@unauthorized/actions';
import { Badge } from '@/components/Badge';
import { useEffect, useState } from 'react';
import { getPaymentMethods } from '@/lib/getPaymentMethods';
import { getExternalBankaccounts } from '@/lib/getExternalBankaccounts';
import { getSubAdmin } from '@/lib/getSubAdmin';
import { createConnectedAccountSession } from '@/lib/createConnectedAccountSession';
import { registerConnectedAccSubAdmin } from '@/lib/registerConnectedAccSubadmin';


export const Profile = () => {
  const [open, actions] = useDisclosure(false);
  const user = useUser((state) => state.user);
  const [bankAccs, setBankAccs] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subAdminDetails, setSubAdminDetails] = useState<any>({})

  const closeModal = async () => {
    actions.close()
    const token = await getAuthToken()
    await getUser(token);
    window.location.reload();
  }

  const openEdit = async () => {
    const token = await getAuthToken()
    await getUser(token);
    actions.open()
  }

  const openStripe = async () => {
    const details = await getSubAdmin(user?.awsId);
    if (details.stripe_connected_acc_id) {
      const session = await createConnectedAccountSession(details?.id)
      window.open(session.url, '_blank');
    } else {
      await registerConnectedAccSubAdmin({
        id: details.id,
        email: details.email,
        first_name: details.first_name,
        last_name: details.last_name,
        phone: details.phone_number,
      })

      const session = await createConnectedAccountSession(details?.id)
      window.open(session.url, '_blank');
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentMet = await getPaymentMethods(user?.awsId);
        const bankAccounts = await getExternalBankaccounts(user?.awsId);

        setPaymentMethods(paymentMet.cards);
        setBankAccs(bankAccounts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (user?.awsId) {
      fetchData();
    }
  }, [user]);

  return (
    <>
      <PageHeader
        title="Mans profils"
        actions={<Button onClick={openEdit}>Rediģēt profilu</Button>}
      />
      <div className={classes.wrapper}>
        <Flex direction="column" justify="flex-start" align="center">
          <Image
            src={user?.imageUrl || defaultImage}
            height={140}
            width={140}
            alt="user image"
            className={classes.avatar}
          />
          {user?.imageUrl && (
            <Button variant="transparent" color="gray.5">
              Dzēst attēlu
            </Button>
          )}
        </Flex>

        <Flex className={classes.infoBox}>
          <div>
            <Text className={classes.label}>Vārds, Uzvārds</Text>
            <Text
              className={classes.value}
              fw={600}
            >{`${user?.name} ${user?.surname}`}</Text>
          </div>
          <div>
            <Text className={classes.label}>Status</Text>
            <Text className={classes.value}>
              <Badge type={user?.status || 'unknown'} />
            </Text>
          </div>
        </Flex>

        <Flex className={classes.infoBox}>
          <div>
            <Text className={classes.label}>E-pasts</Text>
            <Text className={classes.value}>{user?.email}</Text>
          </div>
          <div>
            <Text className={classes.label}>Tālr. Nr.</Text>
            <Text className={classes.value}>{user?.phone}</Text>
          </div>
        </Flex>

        <Flex direction="column">
          <Text fz={rem(22)} fw={400} c="#1F2024">
            Lomas
          </Text>

          {user?.role && <Text className={classes.value}>{user.role}</Text>}
        </Flex>
        <Flex direction="column">
          <Flex>
            <Text fz={rem(22)} fw={400} c="#1F2024">
              Bankas Kartes
            </Text>
            {/* <Text>Darbības</Text> */}
          </Flex>
          {paymentMethods && paymentMethods.map((method: any) => (
            <Card radius="lg" mb={rem(50)} className={classes.container}>
              <Group justify='space-between'>
                <Flex direction="column">
                  <Text className={classes.label}>Kods</Text>
                  <Text p={rem(10)} className={classes.nameText}>**** **** **** {method.card.last4 || '---'}</Text>
                </Flex>
                <Flex direction="column">
                  <Text className={classes.label}>Derīga līdz</Text>
                  <Text  p={rem(10)} className={classes.detailsText}>{method.card.exp_month || '---'}/{method.card.exp_year || '---'}</Text>
                </Flex>
                <Flex direction="column">
                  <Text className={classes.label}>Tips</Text>
                  <Text  p={rem(10)} className={classes.detailsText}>{method.card.brand || '---'}</Text>
                </Flex>
              </Group>
            </Card>
          ))}
        </Flex>
        <Flex direction="column">
          <Flex justify='space-between'>
            <Text fz={rem(22)} fw={400} c="#1F2024">
              Bankas Konti
            </Text>
            <Text pt={rem(10)} className={classes.label} onClick={openStripe}>Darbības</Text>
          </Flex>
          {bankAccs && bankAccs.map((account: any) => (
            <Card radius="lg" mb={rem(10)} className={classes.container}>
            <Group justify='space-between'>
              <Flex direction="column">
                <Text className={classes.label}>Vārds, Uzvārds</Text>
                <Text p={rem(10)} className={classes.nameText}>{account.account_holder_name || '---'}</Text>
              </Flex>
              <Flex direction="column">
                <Text className={classes.label}>Bankas nosaukums</Text>
                <Text  p={rem(10)} className={classes.detailsText}>{account.bank_name || '---'}</Text>
              </Flex>
              <Flex direction="column">
                <Text className={classes.label}>Valūta</Text>
                <Text  p={rem(10)} className={classes.detailsText}>{account.currency || '---'}</Text>
              </Flex>
            </Group>
          </Card>
          ))}
        </Flex>
      </div>
      <EditSelf 
        open={open}
        user={user}
        onClose={() => actions.close()}
        onSave={closeModal}

      />
    </>
  );
};
