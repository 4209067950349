import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const deleteCategory = async (category_id: number) => {
  try {
    const resp = await httpClient().delete(
      `/inventory_mgmt/category/delete/${category_id}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Category deleted');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to delete category');
  }
};
