import { MachineBox } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getMachineBoxesById = async (machine_id: number) => {
  try {
    const resp = await httpClient().get<MachineBox[]>(
      `/machines/machineboxes/machine/getall/${machine_id}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get boxes');
  }

  return [];
};
