import { useState } from 'react';
import { useForm } from '@mantine/form';
import { Box, Button, Flex, Group, Stack, Text, rem } from '@mantine/core';
import { VIEWS } from '../interfaces';
import { useRegistration } from '@/hooks/useRegistration';
import { useTranslation } from '@/hooks';
import { changePass } from '@/lib/changePass';
import { PasswordField } from '@/components';

interface FormValues {
  password: string;
  passwordRepeat: string;
}

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const ChangePass = ({ onViewChange }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const registrationData = useRegistration((state) => state.user);
  const form = useForm<FormValues>({
    validate: {
      password: (value) => (!value ? 'Please enter valid password' : null),
      passwordRepeat: (value, values) =>
        value !== values.password ? 'Passwords should match' : null,
    },
  });

  const handlePasswordChange = async (values: FormValues) => {
    setLoading(true);
    const { email } = registrationData;

    const resp = await changePass(email as string, values.password);

    if (resp?.status === 200) {
      onViewChange(VIEWS.LOGIN);
      setLoading(false);
    }
  };

  return (
    <Box py={rem(60)} ml={rem(34)} h="100%">
      <form onSubmit={form.onSubmit(handlePasswordChange)}>
        <Stack gap={rem(70)} justify="space-between" h="100%">
          <Stack>
            <Text fz={rem(35)} fw={600}>
              Paroles maiņa
            </Text>
            <PasswordField
              label="Jaunā parole"
              {...form.getInputProps('password')}
            />
            <PasswordField
              label="Atkārtota parole"
              {...form.getInputProps('passwordRepeat')}
            />
          </Stack>

          <Flex justify="flex-start" gap={rem(30)}>
            <Button type="submit" loading={loading}>
              {t('continue')}
            </Button>
            <Group align="center" gap="xs">
              <Button
                variant="transparent"
                p="0"
                onClick={() => onViewChange(VIEWS.RESTORE_PASS)}
              >
                Aimzirsāt paroli?
              </Button>
            </Group>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};
