'use client';

import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { useParams, useRouter } from 'next/navigation';
import { Badge } from '@/components/Badge';
import { PageHeader, Table, TableDataRow, TableHeader } from '@/components';
import { useTranslation } from '@/hooks';
import { getClients } from '@/lib/getClients';
import { blockClient } from '@/lib/blockClient';
import { deleteClient } from '@/lib/deleteClient';
import { RowAction } from '@/components/Table/interfaces';
import { restoreClient } from '@/lib/restoreClient';
import { unblockClient } from '@/lib/unblockClient';

const HEADERS: TableHeader[] = [
  {
    value: 'name',
    label: 'Vārds, Uzvārds',
  },
  {
    value: 'phone',
    label: 'Tālr nr.',
  },
  {
    value: 'email',
    label: 'E-pasts',
  },
  {
    value: 'status',
    label: 'Status',
  },
];

export const Users = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { data, isLoading, mutate } = useSWR('/clients/all', () =>
    getClients(),
  );
  const router = useRouter();
  const params = useParams();

  const users = useMemo(() => {
    if (!data) return [];

    return data.map(
      (row) =>
        ({
          _id: { value: row.id.toString() },
          name: {
            value: `${row.first_name} ${row.last_name}`,
            link: `/users/${row.id}`,
          },
          phone: { value: `+${row.phone_area_code} ${row.phone}` },
          email: { value: row.email },
          status: {
            value: row.status,
            display: <Badge type={row.status || 'ACTIVE'} />,
          },
        }) as TableDataRow,
    );
  }, [data]);

  const handleEdit = (id: string) => {
    router.push(`/${params.lang}/users/${id}?action=edit`);
  };

  const handleBlock = async (id: string) => {
    setLoading(true);
    const user = data?.find((u) => u.id === +id);

    if (user) {
      await blockClient(user);
      await mutate();
    }

    setLoading(false);
  };

  const handleUnblock = async (id: string) => {
    setLoading(true);
    const user = data?.find((u) => u.id === +id);

    if (user) {
      await unblockClient(user);
      await mutate();
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    const user = data?.find((u) => u.id === +id);

    if (user) {
      await deleteClient(user);
      await mutate();
    }
    setLoading(false);
  };

  const handleRestore = async (id: string) => {
    setLoading(true);
    const user = data?.find((u) => u.id === +id);

    if (user) {
      await restoreClient(user);
      await mutate();
    }
    setLoading(false);
  };

  const rowActions: RowAction[] = useMemo(
    () => [
      {
        label: 'Rediģēt',
        callback: handleEdit,
      },
      {
        label: 'Bloķēt',
        when: { status: ['active', null] },
        callback: handleBlock,
      },
      {
        label: 'Atbloķēt',
        when: { status: ['blocked'] },
        callback: handleUnblock,
      },
      {
        label: 'Dzēst',
        when: { status: ['active', null] },
        callback: handleDelete,
      },
      {
        label: 'Atjaunot',
        when: { status: ['deleted'] },
        callback: handleRestore,
      },
    ],
    [data],
  );

  return (
    <>
      <PageHeader title={t('systemusers')} />
      <Table
        headers={HEADERS}
        data={users}
        searchable
        filters={['status']}
        loading={isLoading || loading}
        rowActions={rowActions}
      />
    </>
  );
};
