import { Machine } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getRentalPoint = async (rental_point_id: number) => {
  try {
    const resp = await httpClient().get(
      `/rentalpoints/get/byid/${rental_point_id}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get machine');
  }

  return undefined;
};
