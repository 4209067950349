import { Flex, Text, rem } from '@mantine/core';
import { Bag, ListDashes } from '@phosphor-icons/react/dist/ssr';
import { Category } from '@/interfaces';
import { CategoryActions } from './CategoryActions';
import classes from './Categories.module.css'

interface Props {
  category: any;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const CategoryLabel = ({ category, onClick, onDelete, onEdit }: Props) => (
  <Flex justify="space-between" align="center" p={rem(7)} bg="#F8F9FE">
    <Text className={classes.title} fz={14}>{category.name}</Text>
    <Flex justify="flex-end" align="center" gap={rem(30)}>
      {category.subcategories.length > 0 && (
        <Flex gap={rem(5)}>
          <Text fz={12} c="#8F9098">
            {category.subcategories.length}
          </Text>
          <ListDashes color="#8F9098" />
        </Flex>
      )}
      <Flex gap={rem(5)}>
        <Text fz={12} c="#8F9098">
          {category.items}
        </Text>
        <Bag color="#8F9098" />
      </Flex>
      <CategoryActions
        onEdit={onEdit}
        onDelete={onDelete}
        onClick={onClick}
        category={category}
      />
    </Flex>
  </Flex>
);
