import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

interface Box {
  locker_id: any;
  size: string;
  locker_number: string;
}

export const updateMachineBox = async (box: Box) => {
  try {
    const resp = await httpClient().put(
      `/machines/machinebox/edit/${box?.locker_id}/`,
      {
        size: box.size,
        locker_number: box.locker_number
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      // successNotification('LockerBox update success');
    }
    console.error(resp);
  } catch (error) {
    errorNotification(
      'LockerBox update fail',
    );
    console.error(error);
  }
};
