import { useEffect, useState } from 'react';
import { Box, Flex, Modal, Text, rem } from '@mantine/core';
import classes from './DeleteCategory.module.css';
import { Button } from '@/components';
import { deleteCategory } from '@/lib/deleteCategory';
import { getCategoryItems } from '@/lib/getCategoryItems';
import { updateItemCat } from '@/lib/udateItemCategory';

interface Props {
  category: any;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteCategory = ({
  open,
  onClose,
  category,
  onSuccess,
}: Props) => {
  const [selectedCat, setSelectedCat] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [categoryItems, setCategoryItems] = useState<any>([]);


  const handleDelete = async () => {
    setLoading(true);
    
    const category_id = category.id;
    try {
      await deleteCategory(category_id);

      for (const item of categoryItems) {
        const payload = {
          item_id: item.id,
          category_id: 0
        }

        await updateItemCat(payload)
      }
      onSuccess();
    } catch (error) {
      console.log('error');
    }
    setLoading(false);
  };

  useEffect(() => {
    const getItems = async () => {
      const subCatItems = await getCategoryItems(category.id);
      setCategoryItems(subCatItems);
    };
  
    getItems();
  }, [category]);
  

  return (
    <Modal opened={open} onClose={onClose} centered size="30dvw">
      <Flex justify="center">
        {category && category.subcategories.length < 1 ? (
          <Box>
            <Text mb={rem(10)} className={classes.title}>
              Kategorijas dzēšana
            </Text>
            <Text m={rem(20)} className={classes.subtitle}>
              Kategorijai šobrīd piesaistītas {categoryItems.length} preces. Tās tiks pārvietotas uz kategoriju "Cits", bet kategorija tiks dzēsta no saraksta.
            </Text>
            <Flex m={rem(10)} direction="column">
              <Button color="red" loading={loading} onClick={handleDelete}>
                Dzēst
              </Button>
              <Button variant="transparent" type="reset" color="grey">
                Atcelt
              </Button>
            </Flex>
          </Box>
        ) : (
          <Box>
            <Text mb={rem(10)} className={classes.title}>
              Darbība nav iespējama
            </Text>
            <Text m={rem(10)} className={classes.subtitle}>
              Kategoriju nav iespējams dzēst, kamēr tā satur vismaz vienu
              apakškategoriju
            </Text>
            <Flex m={rem(10)} direction="column">
              <Button
                onClick={onClose}
                variant="transparent"
                type="reset"
                color="grey"
              >
                Labi
              </Button>
            </Flex>
          </Box>
        )}
      </Flex>
    </Modal>
  );
};
