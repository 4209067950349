import { Machine } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getMachines = async () => {
  try {
    const resp = await httpClient().get<Machine[]>('/machines/getall');

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get machine');
  }

  return undefined;
};
