import { useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  InputLabel,
  Modal,
  Text,
  rem,
} from '@mantine/core';
import cx from 'classnames';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { Button, CountryCode, TextInput } from '@/components';
import classes from './AddAdmin.module.css';
import { useTranslation } from '@/hooks';
import { ADMIN_ROLES } from './roles';
import { addAdmin } from '@/lib/addAdmin';

interface FormType {
  name: string;
  surname: string;
  phone: string;
  email: string;
  generatePassword: boolean;
  countryCode: string;
  password: string;
  roles: string[];
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
}

export const AddAdmin = ({ open, onClose, onSave }: Props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const form = useForm<FormType>({
    initialValues: {
      name: '',
      surname: '',
      phone: '',
      email: '',
      countryCode: '',
      password: '',
      generatePassword: true,
      roles: [],
    },
    validate: {
      name: (value: string) => (!value ? 'Please enter name' : null),
      surname: (value: string) => (!value ? 'Please enter surname' : null),
      phone: (value: string) => (!value ? 'Please enter phone number' : null),
      email: (value: string) =>
        !value ? 'Please enter valid email number' : null,
      countryCode: (value: string) => (!value ? 'Please select' : null),
      password: (value: string, values: FormType) =>
        !value && !values.generatePassword ? 'Please enter password' : null,
      roles: (value: string[]) =>
        value.length === 0 ? 'Please select role' : null,
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const handleRoleSelect = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const exists = form.values.roles.includes(value);
    form.setFieldValue(
      'roles',
      exists
        ? form.values.roles.filter((r) => r !== value)
        : [...form.values.roles, value],
    );
  };

  const handleSubmit = async (values: FormType) => {
    setLoading(true);
    try {
      await addAdmin(values);
      await onSave();
      form.reset();
    } catch (error) {
      notifications.show({ message: 'Failed to add administrator' });
    }
    setLoading(false);
  };

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
      }}
      opened={open}
      onClose={onClose}
      title="Pievienot administratoru"
      centered
      size="xl"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className={classes.grid}>
          <TextInput label="Vārds" {...form.getInputProps('name')} />
          <TextInput label="Uzvārds" {...form.getInputProps('surname')} />
          <Flex align="center" gap={rem(5)}>
            <CountryCode
              label="Valsts kods"
              defaultValue="371"
              {...form.getInputProps('countryCode')}
            />
            <TextInput label="Telefons" {...form.getInputProps('phone')} />
          </Flex>
          <TextInput label="E-pasts" {...form.getInputProps('email')} />
        </div>
        <Box mt={rem(30)}>
          <InputLabel mb={rem(15)}>Lomas</InputLabel>
          <div
            className={cx(classes.roleGrid, {
              [classes.errorBox]: !!form.errors.roles,
            })}
          >
            <Checkbox
              value="Admin"
              label={t('Admin')}
              radius={rem(4)}
              checked={form.values.roles.includes('Admin')}
              onChange={() =>
                form.setFieldValue(
                  'roles',
                  form.values.roles.includes('Admin') ? [] : ['Admin'],
                )
              }
            />
            {ADMIN_ROLES.map(({ value, label }) => (
              <Checkbox
                key={value}
                value={value}
                label={t(label)}
                radius={rem(4)}
                checked={form.values.roles.includes(value)}
                disabled={form.values.roles.includes('Admin')}
                onChange={handleRoleSelect}
              />
            ))}
          </div>
          {!!form.errors.roles && (
            <Text className={classes.error}>{form.errors.roles}</Text>
          )}
        </Box>
        <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
          <Button
            variant="transparent"
            color="gray"
            onClick={handleClose}
            disabled={loading}
          >
            Atcelt
          </Button>
          <Button type="submit" loading={loading}>
            Saglabāt
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
