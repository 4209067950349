'use client';

import useSWR from 'swr';
import { useState } from 'react';
import { Accordion, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PageHeader } from '@/components';
import { getCategories } from '@/lib/getCategories';
import { SubCategory } from './SubCategory';
import { CategoryLabel } from './CategoryLabel';
import classes from './Categories.module.css';
import { AddCategory } from './AddCategory/AddCategory';
import { AddSubCategory } from './AddSubCategory';
import { DeleteCategory } from './DeleteCategory';
import { Category } from '@/interfaces';
import { EditSubCategory } from './EditSubCategory';
import { useTranslation } from '@/hooks';

export const Categories = () => {
  const [visible, setVisible] = useState(false);
  const [open, modalActions] = useDisclosure(false);
  const [openDelete, modalDeleteActions] = useDisclosure(false);
  const [openEdit, modalEditActions] = useDisclosure(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const { t } = useTranslation();

  const { data: categories, mutate } = useSWR('/inventory_mgmt/categories/allnodes/list', () =>
    getCategories(),
  );

  const handleCategoryAdd = async () => {
    await mutate();

    setVisible(false);
    modalActions.close();
  };

  const handleCategoryDelete = async () => {
    await mutate();
    modalDeleteActions.close();
  };

  const handleCategoryEdit = async () => {
    await mutate();
    modalEditActions.close();
  };

  const selectCategory = (category: Category) => {
    setSelectedCategory(category);
    modalActions.open();
  };

  const selectCategoryEdit = (category: Category) => {
    setSelectedCategory(category);
    modalEditActions.open();
  };

  const handleDelete = (category: Category) => {
    setSelectedCategory(category);
    modalDeleteActions.open();
  };

  return (
    <>
      <PageHeader
        title={t('categories')}
        actions={
          <Button w={170} onClick={() => setVisible(true)}>
            {t('newcategory')}
          </Button>
        }
      />
      <main>
        <AddCategory
          visible={visible}
          onSuccess={handleCategoryAdd}
          onClose={() => setVisible(false)}
        />
        <AddSubCategory
          onSuccess={handleCategoryAdd}
          category={selectedCategory}
          open={open}
          onClose={() => modalActions.close()}
        />
        <EditSubCategory 
          onSuccess={handleCategoryEdit}
          category={selectedCategory}
          open={openEdit}
          onClose={() => modalEditActions.close()}
        />
        <DeleteCategory
          open={openDelete}
          onClose={() => modalDeleteActions.close()}
          category={selectedCategory}
          onSuccess={handleCategoryDelete}
        />
        <Accordion chevronPosition="left" multiple>
          {categories?.map((category) => (
            <Accordion.Item
              key={category.id}
              className={classes.item}
              value={category.id.toString()}
            >
              <Accordion.Control className={classes.category}>
                <CategoryLabel
                  onEdit={() => selectCategoryEdit(category)}
                  onDelete={() => handleDelete(category)}
                  onClick={() => selectCategory(category)}
                  category={category}
                />
              </Accordion.Control>
              <Accordion.Panel>
                  <SubCategory
                    onClick={(cat) => selectCategory(cat)}
                    onEdit={(cat) => selectCategoryEdit(cat)}
                    onDelete={(cat) => handleDelete(cat)}
                    category={category}
                  />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </main>
    </>
  );
};
