import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const updateItemStatus = async (point_id: any, item_id: any, status: string) => {
  try {
    const resp = await httpClient().put(
      `/inventory_mgmt/tools/update/${item_id}/`,
      {
        rental_point_id: point_id,
        status: status
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Statusa maina veiksmiga');
    }
    console.error(resp);
  } catch (error) {
    errorNotification(
      'Statusa maina neveiksmiga'
    );
    console.error(error);
  }
};
