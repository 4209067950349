import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const stopItemRent = async (id: string) => {
  try {
    const resp = await httpClient().put(`/inventory_mgmt/tools/stoprent/${id}`);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Item rent cancelled');
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to cancel rent');
  }
};
