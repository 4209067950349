import { Client } from '@/interfaces/client';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const restoreClient = async (user: Client) => {
  try {
    const resp = await httpClient().post<Client>(
      `/clients/client/restore/${user?.id}/`,
      user,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Restored user');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to restore user');
    console.error(error);
  }
};
