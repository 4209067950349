import { Admin } from '@/interfaces';
import { errorNotification, getUserAttribute } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const getAdmins = async (): Promise<Admin[]> => {
  try {
    const resp = await httpClient().post('/admin/getadmins');

    if (OK_STATUSES.includes(resp.status)) {
      const admins = resp.data.Users.map(
        (admin: { [x: string]: any; Attributes: any }) => {
          const data = admin.Attributes;
          return {
            awsId: getUserAttribute(data, 'sub'),
            guid: admin.Username,
            email: getUserAttribute(data, 'email'),
            name: getUserAttribute(data, 'given_name'),
            surname: getUserAttribute(data, 'family_name'),
            phone: getUserAttribute(data, 'phone_number'),
            status: getUserAttribute(data, 'custom:status'),
            emailVerified: getUserAttribute(data, 'email_verified') === 'True',
            role: getUserAttribute(data, 'custom:role'),
            company_id: getUserAttribute(data, 'custom:company_id'),
          };
        },
      );

      return admins;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to get users');
    console.error(error);
  }

  return [];
};
