import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const cancelMaintenance = async (machine_id: any) => {
  try {
    const resp = await httpClient().put(`/machines/cleanup/machine/maintenance/finish/${machine_id}`);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Machine maintenance action success');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Machine maintenance action fail');
  }
};
