import { Accordion } from '@mantine/core';
import { SubCategory as ISubCategory } from '@/interfaces';
import { AccordionLabel } from './AccordionLabel';
import classes from './SubCategory.module.css';

interface Props {
  category: ISubCategory;
  onClick: (cat: ISubCategory) => void;
  onEdit: (cat: ISubCategory) => void;
  onDelete: (cat: ISubCategory) => void;
}

export const SubCategory = ({ category, onClick, onDelete, onEdit }: Props) => {
  console.log({ category });

  const handleClick = (cat: ISubCategory) => {
    return () => onClick(cat);
  };

  const handleDelete = (cat: ISubCategory) => {
    return () => onDelete(cat);
  };

  const handleEdit = (cat: ISubCategory) => {
    return () => onEdit(cat);
  };

  return (
    <Accordion chevronPosition="left" multiple pr={0}>
      {category.subcategories?.map((cat: ISubCategory) => (
        <Accordion.Item
          key={cat.id}
          value={cat.id.toString()}
          className={classes.item}
        >
          <Accordion.Control pr={0}>
            <AccordionLabel
              onDelete={handleDelete(cat)}
              onClick={handleClick(cat)}
              category={cat}
              onEdit={handleEdit(cat)}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <SubCategory onDelete={onDelete} onClick={onClick} category={cat} onEdit={onEdit} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
