import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const addSubCategory = async (subCat: { [key: string]: string }) => {
  try {
    const resp = await httpClient().post(
      `/inventory_mgmt/category/create`,
      {
        category_id: subCat.category_id,
        parent_category_id: subCat.parent_category_id,
        name: subCat.name
      },
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Category created');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create category');
  }
};
