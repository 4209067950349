import { useState, useMemo, useEffect } from 'react';
import useSWR from 'swr';
import { Box, Flex, Modal, MultiSelect, Select, Text, TextInput, Textarea, rem } from '@mantine/core';
import classes from './RentalItems.module.css';
import { Button } from '@/components';
import { deleteCategory } from '@/lib/deleteCategory';
import { setMachineStatus } from '@/lib/setMachineStatus';
import { setRentalPointStatus } from '@/lib/setRentalPointStatus';
import { getMachineBoxesById } from '@/lib/getMachineBoxesById';
import { setMachineMaintenance } from '@/lib/setMachineMaintenance';
import { setLockerMaintenance } from '@/lib/setLockerMaintenance';
import { useUser } from '@/hooks/useUser';
import { cancelMaintenance } from '@/lib/cancelMainenance';
import { getItems } from '@/lib/getItems';
import { updateItemStatus } from '@/lib/updateItemStatus';

interface Props {
  open: boolean;
  machine: any;
  onClose: () => void;
  onSuccess: () => void;
}

const MACHINE_STATUS = [
  {
    label: 'Apkopē (nav pieejams)',
    value: 'MAINTANENCE'
  },
  {
    label: 'Apkopē (pieejams)',
    value: 'PARTMAINTANENCE'
  }
]

export const RentalItems = ({ open, onClose, onSuccess, machine }: Props) => {
  const [loading, setLoading] = useState(false);
  const user = useUser((state) => state.user);

  const [fetchedTools, setFetchedTools] = useState<any>([]);
  const [selectedTools, setselectedTools] = useState<any>([]);


  const { data, isLoading } = useSWR(
    `/inventory_mgmt/get/company/${user?.companyId}`,
    () => getItems(user?.companyId),
  );

  useEffect(() => {
    if (!data) return;
  
    const filteredRows = data.filter(row => row.status === 'FREE');
  
    const maintenanceBoxesData = filteredRows.map(row => ({
      value: String(row.id),
      label: `${row.producer} ${row.model} ${row.name}`,
    }));
  
    setFetchedTools(maintenanceBoxesData);
  }, [data]);

  const handleMaintenance = async () => {
    setLoading(true);

    try {
      for (const tool of selectedTools) {
        await updateItemStatus(machine.id, tool, 'AVAILABLE')
      }

      onSuccess();
      onClose()
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal 
      opened={open} 
      onClose={handleClose} 
      centered 
      size="30dvw"
      title="Pievienot preces"
    >
      <Flex direction="column" h={rem(400)} justify="space-between">
          <Box>
            <MultiSelect
              p={rem(5)}
              label="Preces"
              placeholder="Izvēlēties vienu vai vairākus"
              data={fetchedTools}
              onChange={(value: any) => {
                setselectedTools(value);
                console.log("Selected value:", value);
              }}
              clearable
            />
          </Box>
          <Flex justify="flex-end" gap={rem(5)} p={rem(20)}>
            <Button
              variant="transparent"
              color="gray"
              onClick={handleClose}
              disabled={loading}
            >
              Atcelt
            </Button>
            <Button type="submit" onClick={handleMaintenance} loading={loading}>
              Pievienot
            </Button>
          </Flex>
      </Flex>
    </Modal>
  );
  
};
