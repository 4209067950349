import { useMemo, useState, useReducer } from 'react';
import {
  Flex,
  rem,
  Switch,
  ActionIcon
} from '@mantine/core';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useForm } from '@mantine/form';
import {
  DatesProvider,
  MonthPickerInput,
  DatePickerInput,
  TimeInput
} from '@mantine/dates';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import classes from './ExceptionDate.module.css';

interface Props {
  item: any;
  onStatusChange: (status: any) => void;
  onDateChange: (date: any) => void;
  onIdChange?: (id: string) => void;
  onTimeFromChange: (date: any) => void;
  onTimeToChange: (date: any) => void;
  removeItemByIndex: () => void;
}

export const ExceptionDate = ({
  item,
  onStatusChange,
  onDateChange,
  onTimeFromChange,
  onTimeToChange,
  removeItemByIndex
}: Props) => {
  return (
    <Flex w="100%" justify="space-between" mt={rem(10)}>
      <DatesProvider
        settings={{
          locale: 'lv',
          firstDayOfWeek: 0,
          weekendDays: [0],
          timezone: 'UTC',
        }}
      >
        <DatePickerInput
          w={'48%'}
          // className={classes.selection}
          value={new Date (item.date)}
          onChange={(value) => onDateChange(value)}
          label="Izņēmuma darba laiks"
          placeholder="Izvēlaties datumu"
        />
      </DatesProvider>
      <Flex justify="space-between" ml={rem(10)} w={'48%'}>
        <TimeInput 
          w={'48%'} 
          label=" " 
          value={item.timeFrom}
          disabled={item.closed}
          onChange={(event) => onTimeFromChange(event.target.value)}
          />
        <TimeInput 
          w={'48%'} 
          label=" " 
          value={item.timeTo}
          disabled={item.closed}
          onChange={(event) => onTimeToChange(event.target.value)}
        />
      </Flex>
      <Flex align="center" ml={rem(10)} >
        <Switch 
          size='sm' 
          mt={rem(25)} 
          label="Slēgts" 
          checked={item.closed}
          onChange={(event) => onStatusChange(event.currentTarget.checked)}
        />
      </Flex>
      <Flex w="30%" justify="end">
        <ActionIcon
            mt={rem(30)}
            ml={rem(10)}
            variant="transparent"
            onClick={() => removeItemByIndex()}
          >
            <Trash size={20} color="grey" />
        </ActionIcon>
      </Flex>
    </Flex>
  );
};
