import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

interface Locker {
  machine_id: number;
  locker_number: string;
  size: string;
  status: string;
}

export const addLockerBox = async (item: any) => {
  try {
    const resp = await httpClient().post('/machines/machinebox/create', {
      machine_id: item.machine_id,
      status: item.status,
      locker_number: item.locker_number,
      size: item.size
    });

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Box created');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create item');
  }
};
