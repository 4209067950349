import { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  Box,
  Flex,
  ActionIcon,
  Modal,
  Text,
  rem,
  Radio,
  ScrollArea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import classes from './EditRentalPoint.module.css';
import { Trash } from '@phosphor-icons/react/dist/ssr';

import { Button, CountryCode, TextInput } from '@/components';
import { LockerBox } from './LockerBox';
import { SectionHeader } from '@/components/SectionHeader';
import { Autocomplete } from '@/components/AutoComplete';
import { addMachine } from '@/lib/addMachine';
import { addRentalPoint } from '@/lib/addRentalPoint';
import { getMachineBoxesById } from '@/lib/getMachineBoxesById';
import { WorkDay } from '../AddRentalPoint/WorkDay';
import { updateMachine } from '@/lib/updateMachine';
import { ExceptionDate } from '../AddRentalPoint/ExceptionDate';
import { updateRentalPoint } from '@/lib/updateRentalPoint';
import { removeMachineBox } from '@/lib/removeMachineBox';
import { addLockerBox } from '@/lib/addLocker';
import { updateMachineBox } from '@/lib/updateMachineBox';

interface FormValues {
  lattitude?: number;
  longitude?: number;
  coordinates: string;
  address?: string;
  name?: string;
  machine_type?: string;
  status?: string;
  phone?: string;
  workflow_type?: string;
  exception_dates?: string;
  company_id?: number;
  city?: string;
  post_code?: string;
}

interface Props {
  open: boolean;
  item?: any;
  onClose: () => void;
  onDone: () => Promise<void>;
}

interface ExceptionDate {
  date: any;
  timeFrom: string;
  timeTo: string;
  closed: boolean;
}

interface Locker {
  size: any;
  locker_number: string;
}

export const EditRentalPoint = ({ open, onClose, onDone, item }: Props) => {
  const [type, setType] = useState('automatic');
  const [workTime, setWorkTime] = useState('everytime');
  const [times, setTimes] = useState<[]>([]);
  const [lockers, setLockers] = useState<any[]>([]);
  const [deleteLockers, setDeleteLockers] = useState<any[]>([]);
  const [addLockers, setAddLockers] = useState<any[]>([]);
  const [exceptionDates, setExceptionDates] = useState<any[]>([])
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useSWR(
    `/machines/machineboxes/getall/${item?.id}`,
    () => getMachineBoxesById(item?.id),
  );

  const form = useForm<FormValues>({
    initialValues: {
      lattitude: item?.lattitude,
      longitude: item?.longitude,
      coordinates: `${item?.lattitude} - ${item?.longitude}`,
      address: item?.address,
      name: item?.name,
      machine_type: item?.machine_type,
      phone: item?.phone,
      workflow_type: item?.workflow_type,
      exception_dates: item?.exception_dates,
      city: item?.city,
      post_code: item?.post_code,
    },
    validate: {
      lattitude: (value) => (!value ? 'Value required' : null),
      longitude: (value) => (!value ? 'Value required' : null),
      address: (value) => (!value ? 'Value required' : null),
      name: (value) => (!value ? 'Value required' : null),
      machine_type: (value) => (!value ? 'Value required' : null),
      status: (value) => (!value ? 'Value required' : null),
      phone: (value) => (!value ? 'Value required' : null),
      workflow_type: (value) => (!value ? 'Value required' : null),
      company_id: (value) => (!value ? 'Value required' : null),
      city: (value) => (!value ? 'Value required' : null),
    },
  });

  useEffect(() => {
    form.setValues({
      lattitude: item?.lattitude,
      longitude: item?.longitude,
      coordinates: `${item?.lattitude} - ${item?.longitude}`,
      address: item?.address,
      name: item?.name,
      machine_type: item?.machine_type,
      phone: item?.phone,
      workflow_type: item?.workflow_type,
      exception_dates: item?.exception_dates,
      city: item?.city,
      post_code: item?.post_code,
    });
    setType(item?.machine_type);

    try {
      const exceptions = JSON.parse(item.exception_dates);
      if (exceptions && typeof exceptions === 'object') {
        setExceptionDates(exceptions);
      }

      const response = JSON.parse(item.workflow_type);
      setWorkTime(item?.workflow_type);
      if (response && typeof response === 'object' && response.data && response.type) {
        setWorkTime(response.type);
        setTimes(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    
    setLockers(data || [])

  }, [item, data]);

  const handlePlaceSelected = (place: any) => {
    const getObjectByType = (data: any, targetType: any) => {
      const foundObject = data.find((component: any) => {
        return component.types.includes(targetType);
      });

      return foundObject || null;
    };

    const postal_code = getObjectByType(
      place.object.address_components,
      'postal_code',
    );
    const city = getObjectByType(place.object.address_components, 'locality');

    form.setFieldValue('address', place.address);
    console.log(place);
    form.setFieldValue('lattitude', place.lattitude);
    form.setFieldValue('longitude', place.longitude);
    form.setFieldValue(
      'coordinates',
      `${place.longitude} - ${place.lattitude}`,
    );
    form.setFieldValue('city', city.short_name);
    form.setFieldValue('post_code', postal_code.short_name);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const data: any = form.values;
    if (type === 'automatic') {
      const payload = {
        ...data,
      }
      await updateMachine(
        item.id,
        payload
      );

      for (const box of deleteLockers) {
        await removeMachineBox(box.id)
      }

      for (const locker of addLockers) {
        const lockerData = {
          locker_number: locker.locker_number,
          size: locker.size,
          status: 'AVAILABLE',
          machine_id: item.id
        };
        await addLockerBox(lockerData)
      }

      for (const locker of lockers) {
        const lockerData = {
          locker_id: locker.id,
          size: locker.size,
          locker_number: locker.locker_number
        }
        await updateMachineBox(lockerData)
      }

      await onDone();
      form.reset();
    } else if (type === 'physical') {
      const payload = {
        ...data,
      }
      await updateRentalPoint(
        item.id,
        payload,
      );
      await onDone();
      form.reset();
    }
    onClose();

    setLoading(false);
  };

  const setWorkFlowType = (type: string) => {
    form.setFieldValue('workflow_type', type);
    setWorkTime(type)
  }

  const handleDatesChange = (updatedDates: any) => {
    const timeField = {
      type: workTime,
      data: updatedDates
    }
    form.setFieldValue('workflow_type', JSON.stringify(timeField))
    // setWorkDays(updatedDates);
  };

  const addLocker = () => {
    const newLocker = {
      size: '',
      locker_number: '',
    };
    setAddLockers((prevLockers) => [...prevLockers, newLocker]);
  };

  const removeLockerByIndex = (indexToRemove: number) => {
    setLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      const deletedLocker = updatedLockers.splice(indexToRemove, 1)[0];
      setDeleteLockers((prevDeletedLockers) => [...prevDeletedLockers, deletedLocker]);
      return updatedLockers;
    });
  };

  const removeNewLockerByIndex = (indexToRemove: number) => {
    setAddLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers.splice(indexToRemove, 1);
      return updatedLockers;
    });
  };

  const handleSizeChange = (selectedSize: any, index: number) => {
    setLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers[index] = {
        ...updatedLockers[index],
        size: selectedSize,
      };
      return updatedLockers;
    });
  };

  const handleIdChange = (inputId: any, index: number) => {
    setLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers[index] = {
        ...updatedLockers[index],
        locker_number: inputId,
      };
      return updatedLockers;
    });
  };

  const handleAddSizeChange = (selectedSize: any, index: number) => {
    setAddLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers[index] = {
        ...updatedLockers[index],
        size: selectedSize,
      };
      return updatedLockers;
    });
  };

  const handleAddIdChange = (inputId: any, index: number) => {
    setAddLockers((prevLockers) => {
      const updatedLockers = [...prevLockers];
      updatedLockers[index] = {
        ...updatedLockers[index],
        locker_number: inputId,
      };
      return updatedLockers;
    });
  };

  const handleStatusChange = (status: boolean, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        closed: status,
      };
      return updatedExceptions;
    });
  };

  const handleDate = (date: any, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        date: date,
      };
      return updatedExceptions;
    });
  };

  const handleFrom = (timeFrom: any, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        timeFrom: timeFrom,
      };
      return updatedExceptions;
    });
  };

  const handleTo = (timeTo: any, index: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions[index] = {
        ...updatedExceptions[index],
        timeTo: timeTo,
      };
      return updatedExceptions;
    });
  };

  const removeExceptionByIndex = (indexToRemove: number) => {
    setExceptionDates((prevExceptions) => {
      const updatedExceptions = [...prevExceptions];
      updatedExceptions.splice(indexToRemove, 1);
      return updatedExceptions;
    });
  };

  const addExceptionDate = () => {
    const exceptionDate: ExceptionDate = {
      date: new Date(),
      timeFrom: '',
      timeTo: '',
      closed: false
    };
    setExceptionDates((prevExceptions) => [...prevExceptions, exceptionDate]);
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    form.setFieldValue('exception_dates', JSON.stringify(exceptionDates));
  }, [exceptionDates])

  return (
    <Modal
      classNames={{
        content: classes.content,
        title: classes.title,
        close: classes.close,
        body: classes.body,
      }}
      opened={open}
      onClose={onClose}
      title="Nomas punkta rediģēšana"
      centered
      size="70dvw"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.uppergrid} mb={rem(20)}>
          <TextInput label="Nosaukums" {...form.getInputProps('name')} />
          <Autocomplete
            label="Adrese"
            onPlaceSelected={handlePlaceSelected}
            {...form.getInputProps('address')}
          />
          <Flex justify="space-between">
            <TextInput
              w={'48%'}
              label="Pilsēta"
              {...form.getInputProps('city')}
            />
            <TextInput
              w={'48%'}
              label="Pasta indekss"
              {...form.getInputProps('post_code')}
            />
          </Flex>
          <TextInput
            label="Koordinātes"
            {...form.getInputProps('coordinates')}
            readOnly={true}
          />
          <Radio.Group value={type}>
            <Text className={classes.label}>Nomas punkta tips</Text>
            <Flex justify="space-between">
              <Flex w={'48%'} className={classes.selection}>
                <Radio
                  disabled
                  pl={rem(10)}
                  label="Automātiskais"
                  value="automatic"
                />
              </Flex>
              <Flex w={'48%'} className={classes.selection}>
                <Radio
                  disabled
                  pl={rem(10)}
                  label="Fiziskais"
                  value="physical"
                />
              </Flex>
            </Flex>
          </Radio.Group>
          <div />
          {type === 'automatic' ? (
            <Flex direction="column">
              <Button justify="start" variant="transparent" onClick={addLocker}>
                Pievienot skapīti
              </Button>
              {lockers ? (
                <ScrollArea mah={130}>
                {lockers.map((locker, index) => (
                  <Flex align="center">
                    <LockerBox
                      key={index}
                      locker_number={locker.locker_number}
                      locker_size={locker.size}
                      onIdChange={(locker_number) =>
                        handleIdChange(locker_number, index)
                      }
                      onSizeChange={(selectedSize) =>
                        handleSizeChange(selectedSize, index)
                      }
                    />
                    <ActionIcon
                      mt={rem(20)}
                      ml={rem(10)}
                      variant="transparent"
                      onClick={() => removeLockerByIndex(index)}
                      disabled={locker.current_item_id}
                    >
                      <Trash size={22} color="grey" />
                    </ActionIcon>
                  </Flex>
                ))}
                {addLockers.map((locker, index) => (
                  <Flex align="center">
                    <LockerBox
                      key={index}
                      locker_number={locker.locker_number}
                      locker_size={locker.size}
                      onIdChange={(locker_number) =>
                        handleAddIdChange(locker_number, index)
                      }
                      onSizeChange={(selectedSize) =>
                        handleAddSizeChange(selectedSize, index)
                      }
                    />
                    <ActionIcon
                      mt={rem(20)}
                      ml={rem(10)}
                      variant="transparent"
                      onClick={() => removeNewLockerByIndex(index)}
                      disabled={locker.current_item_id}
                    >
                      <Trash size={22} color="grey" />
                    </ActionIcon>
                  </Flex>
                ))}
              </ScrollArea>
              ) : null}
            </Flex>
          ) : (
            <Flex justify="end">
              <Flex w={'48%'} justify="space-between">
                <CountryCode
                  label="Tālruņa numurs"
                  value="371"
                  defaultValue="371"
                />
                <TextInput w={'60%'} label=" " />
              </Flex>
            </Flex>
          )}
          <div />
        </Box>
        <SectionHeader title="Darba laiks" />
        <Box className={classes.uppergrid}>
          <Radio.Group value={workTime}>
            <Text className={classes.label}>Pieejamība</Text>
            <Flex justify="space-between">
              <Flex
                w="48%"
                className={classes.selection}
                onClick={() => setWorkFlowType('everytime')}
              >
                <Radio pl={rem(10)} label="24/7" value="everytime" />
              </Flex>
              <Flex
                w="48%"
                className={classes.selection}
                onClick={() => setWorkFlowType('selected')}
              >
                <Radio pl={rem(10)} label="Limitēts" value="selected" />
              </Flex>
            </Flex>
          </Radio.Group>
        </Box>
        {workTime === 'selected' ? (
          <Box className={classes.grid}>
            <div>
            </div>
            <Flex>
              <WorkDay
                times={times}
                onDatesChange={handleDatesChange}
              />
            </Flex>
          </Box>
          ) : null}
        <Box>
        <Button justify="start" variant="transparent" onClick={addExceptionDate}>
            Pievienot izņēmuma darba laiku
          </Button>
          <div />
          <Flex w={'100%'} direction="column">
            {exceptionDates.map((item, index) => (
              <ExceptionDate
                onStatusChange={(status: any) => handleStatusChange(status, index)}
                onDateChange={(value: any) => handleDate(value, index)}
                onTimeFromChange={(date: any) => handleFrom(date, index)}
                onTimeToChange={(date: any) => handleTo(date, index)}
                removeItemByIndex={() => removeExceptionByIndex(index)}
                key={index} 
                item={item}  
              />
            ))}
          </Flex>
        </Box>
        <Flex justify="flex-end" gap={rem(5)} mt={rem(40)}>
          <Button
            variant="transparent"
            color="gray"
            onClick={handleClose}
            disabled={loading}
          >
            Atcelt
          </Button>
          <Button type="submit" loading={loading} onClick={handleSubmit}>
            Saglabāt
          </Button>
        </Flex>
        {/* <div>{JSON.stringify(lockers)}</div> */}
      </form>
    </Modal>
  );
};
