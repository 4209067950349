import { Admin } from '@/interfaces';
import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';

export const acceptSubCompany = async (companyId: number) => {
  try {
    const resp = await httpClient().post<Admin>(
      `/subcompany/accept/${companyId}`,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Company accepted');
      return resp.data;
    }
  } catch (error) {
    console.error(error);
    errorNotification('Failed to accept company');
  }

  return undefined;
};
