import { useMemo, useState, useReducer, useEffect } from 'react';
import {
  Flex,
  rem,
  Switch,
  Text
} from '@mantine/core';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useForm } from '@mantine/form';
import {
  DatesProvider,
  MonthPickerInput,
  DatePickerInput,
  TimeInput
} from '@mantine/dates';
import classes from './WorkDay.module.css';

interface Props {
  times?: any;
  onDatesChange: (dates: any[]) => void;
}

const DAYS = [
  {
    name: 'P',
    from: '',
    to: '',
    closed: false
  },
  {
    name: 'O',
    from: '',
    to: '',
    closed: false
  },
  {
    name: 'T',
    from: '',
    to: '',
    closed: false
  },
  {
    name: 'C',
    from: '',
    to: '',
    closed: false
  },
  {
    name: 'P',
    from: '',
    to: '',
    closed: false
  },
  {
    name: 'S',
    from: '',
    to: '',
    closed: false
  },
  {
    name: 'Sv',
    from: '',
    to: '',
    closed: false
  },
]

export const WorkDay = ({
  onDatesChange,
  times
}: Props) => {
  const [dates, setDates] = useState<any[]>(DAYS);
  const [savedTimes, setSavedTimes] = useState<any[]>([]);


  const handleStatusChange = (newValue: boolean, index: number) => {
    setDates((prevDates) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = {
        ...updatedDates[index],
        closed: newValue,
      };
      return updatedDates;
    });
  };

  const handleSavedStatusChange = (newValue: boolean, index: number) => {
    setSavedTimes((prevSavedTimes) => {
      const updatedSavedTimes = [...prevSavedTimes];
      updatedSavedTimes[index] = {
        ...updatedSavedTimes[index],
        closed: newValue,
      };
      return updatedSavedTimes;
    });
  };

  const handleSavedTimeFrom = (newValue: any, index: number) => {
    setSavedTimes((prevSavedTimes) => {
      const updatedSavedTimes = [...prevSavedTimes];
      updatedSavedTimes[index] = {
        ...updatedSavedTimes[index],
        from: newValue,
      };
      return updatedSavedTimes;
    });
  };

  const handleSavedTimeTo = (newValue: any, index: number) => {
    setSavedTimes((prevSavedTimes) => {
      const updatedSavedTimes = [...prevSavedTimes];
      updatedSavedTimes[index] = {
        ...updatedSavedTimes[index],
        to: newValue,
      };
      return updatedSavedTimes;
    });
  };

  const handleFrom = (timeFrom: any, index: number) => {
    setDates((prevDates) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = {
        ...updatedDates[index],
        from: timeFrom,
      };
      return updatedDates;
    });
  };

  const handleTo = (timeTo: any, index: number) => {
    setDates((prevDates) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = {
        ...updatedDates[index],
        to: timeTo,
      };
      return updatedDates;
    });
  };

  useEffect(() => {
    if (times && times.length) {
      setSavedTimes(times)
      onDatesChange(times);
    } else {
      onDatesChange(dates);
    }
  }, [dates])


  useEffect(() => {
    onDatesChange(savedTimes);
  }, [savedTimes])

  useEffect(() => {
    onDatesChange(dates);
  }, [dates])

  return (
    <Flex direction="column" w="100%">
      {times && times.length ? (
        savedTimes.map((day: any, index: number) => (
          <Flex w="100%" justify="space-between" key={index}>
            <Text className={classes.day} mt={rem(35)}>{day.name}</Text>
            <TimeInput
              rightSection="No"
              w={'30%'}
              label=" "
              value={day.from}
              disabled={day.closed}
              onChange={(event) => handleSavedTimeFrom(event.currentTarget.value, index)}
            />
            <TimeInput 
              label=" " 
              w={'30%'}
              value={day.to}
              rightSection="Līdz"
              disabled={day.closed}
              onChange={(event) => handleSavedTimeTo(event.currentTarget.value, index)}
            />
            <Switch
              size='sm' 
              mt={rem(35)} 
              label="Slēgts"
              checked={day.closed}
              onChange={(event) => handleSavedStatusChange(event.target.checked, index)}
            />
          </Flex>
        ))
      ) : (
        dates.map((day, index) => (
          <Flex w="100%" justify="space-between" key={index}>
            <Text className={classes.day} mt={rem(35)}>{day.name}</Text>
            <TimeInput
              rightSection="No"
              w={'30%'}
              label=" "
              disabled={day.closed}
              onChange={(event) => handleFrom(event.currentTarget.value, index)}
            />
            <TimeInput 
              label=" " 
              w={'30%'}
              rightSection="Līdz"
              disabled={day.closed}
              onChange={(event) => handleTo(event.currentTarget.value, index)}
            />
            <Switch
              size='sm' 
              mt={rem(35)} 
              label="Slēgts" 
              checked={day.closed}
              onChange={(event) => handleStatusChange(event.target.checked, index)}
            />
          </Flex>
        ))
      )}
    </Flex>
  );  
};
