import { Client } from '@/interfaces/client';
import { errorNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const getClients = async () => {
  try {
    const resp = await httpClient().get<Client[]>('/clients/all');

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to get users');
    console.error(error);
  }

  return [];
};
