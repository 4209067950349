import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification } from '@/util';

export const getItemAttributes = async (item_id: any) => {
  try {
    const resp = await httpClient().get(`/inventory_mgmt/category/itemattributes/getall/${item_id}`);

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    errorNotification('Failed to get attributes');
  }

  return undefined;
};
