import { OK_STATUSES, httpClient } from '@/util/httpClient';
import { errorNotification, successNotification } from '@/util';
import { Item } from '@/interfaces';

export const addItem = async (item: Partial<Item>) => {
  try {
    const resp = await httpClient().post('/inventory_mgmt/tools/create', item);

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Item created');
    }
    return resp.data
  } catch (error) {
    console.error(error);
    errorNotification('Failed to create item');
  }
};
