'use client';

import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { Text, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  Button,
  PageHeader,
  Table,
  TableDataRow,
  TableHeader,
} from '@/components';
import { Badge } from '@/components/Badge';
import { useUser } from '@/hooks/useUser';
import { getItems } from '@/lib/getItems';
import { STATUS_COLORS } from '@/constants/status';
import { ItemImage } from './ItemImage';
import { RowAction } from '@/components/Table/interfaces';
import { EditItem } from './EditItem';
import { Item } from '@/interfaces';
import { addItem } from '@/lib/addItem';
import { deleteItem } from '@/lib/deleteItem';
import { stopItemRent } from '@/lib/stopItemRent';
import { NewItem } from './NewItem';
import { useTranslation } from '@/hooks';

const headers: TableHeader[] = [
  {
    value: 'item',
    label: 'Prece',
  },
  {
    value: 'manufacturer',
    label: 'Ražotājs',
  },
  {
    value: 'model',
    label: 'Modelis',
  },
  {
    value: 'subCategory',
    label: 'Apakškategorija',
  },
  {
    value: 'valuation',
    label: 'Vērtējums',
  },
  {
    value: 'status',
    label: 'Status',
  },
];

export const Items = () => {
  const [loading, setLoading] = useState(false);
  const user = useUser((state) => state.user);
  const {
    data: items,
    isLoading,
    mutate,
  } = useSWR(`/company/${user?.companyId}/items`, () =>
    getItems(user?.companyId || ''),
  );
  const [newOpen, newActions] = useDisclosure(false);
  const [editOpen, editActions] = useDisclosure(false);
  const [selectedItem, setSelectedItem] = useState<Item>();
  const { t } = useTranslation();

  const handleItemSelect = (ids: string[]) => {
    console.log({ ids });
  };

  const handleRefresh = async () => {
    await mutate();
  };

  const data = useMemo(
    () =>
      items
        ? items.map((companyItem) => ({
            _id: { value: companyItem.id?.toString() },
            item: {
              value: companyItem.name,
              display: (
                <ItemImage
                  id={companyItem.id.toString()}
                  name={companyItem.name}
                  urls={JSON.parse(companyItem.images_url)}
                />
              ),
            },
            manufacturer: {
              value: companyItem.producer,
              display: (
                <Text fz={rem(12)} c="#8F9098">
                  {companyItem.producer}
                </Text>
              ),
            },
            model: { value: companyItem.model },
            subCategory: {
              value: companyItem.category_name,
              display: (
                <Text fz={rem(12)} c="#8F9098">
                  {companyItem.category_name}
                </Text>
              ),
            },
            valuation: {
              value: companyItem.rating.toString(),
              display: companyItem.rating.toFixed(1),
            },
            status: {
              value: companyItem.status,
              display: <Badge type={companyItem?.status} />,
            },
          }))
        : ([] as TableDataRow[]),
    [items],
  );

  const handleEdit = (id: string) => {
    const selected = items?.find((item) => item.id === +id);

    setSelectedItem(selected);
    editActions.open();
  };

  const handleDuplicate = async (id: string) => {
    setLoading(true);
    const selected = items?.find((item) => item.id === +id);

    if (selected) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { id, ...itemDate } = selected;

      const duplicate = {
        additional_equipment: itemDate.additional_equipment || '',
        category_name: itemDate.category_name || '',
        company_id: itemDate.company_id || 0,
        cost_per_day: itemDate.cost_per_day,
        cost_per_h: itemDate.cost_per_h,
        images_url: itemDate.images_url,
        inventory_number: 'duplicate',
        locker_size: itemDate.locker_size,
        model: itemDate.model,
        name: itemDate.name,
        price: itemDate.price,
        producer: itemDate.producer,
        status: 'FREE',
        sub_category_id: itemDate.sub_category_id,
        year_made: itemDate.year_made,
        rating: 0
      }

      await addItem(duplicate);
      await mutate();
    }

    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    setLoading(true);

    await deleteItem(id);
    await mutate();

    setLoading(false);
  };

  const handleRentStop = async (id: string) => {
    setLoading(true);

    await stopItemRent(id);
    await mutate();

    setLoading(false);
  };

  const rowActions: RowAction[] = useMemo(
    () => [
      {
        label: 'Rediģēt',
        callback: handleEdit,
      },
      {
        label: 'Dublēt',
        when: { status: ['available', 'free', 'rented', 'disabled'] },
        callback: handleDuplicate,
      },
      {
        label: 'Apstādināt nomu',
        when: { status: ['available'] },
        callback: handleRentStop,
      },
      {
        label: 'Dzēst',
        color: 'red',
        when: { status: ['available', 'free', 'rented', 'disabled'] },
        callback: handleDelete,
      },
    ],
    [items],
  );

  return (
    <>
      <PageHeader
        title={t('inventory_management')} 
        actions={<Button onClick={newActions.open}>{t('add_item')} </Button>}
      />
      <Table
        headers={headers}
        data={data}
        loading={isLoading || loading}
        searchable
        selectable
        filters={['manufacturer', 'subCategory', 'status']}
        allActionsInMenu
        rowActions={rowActions}
        onSelect={handleItemSelect}
      />

      <EditItem
        open={editOpen}
        item={selectedItem}
        onClose={editActions.close}
        onDone={handleRefresh}
      />
      <NewItem
        open={newOpen}
        item={selectedItem}
        onClose={newActions.close}
        onDone={handleRefresh}
      />
    </>
  );
};
